const dashboard = {
    "dashboard.title": "Dashboard",
    "dashboard.banner.welcome": "Bienvenue!",
    "dashboard.banner.welcome.subtitle": " ",
    "dashboard.file.count": "Compteur de Fichiers",
    "dashboard.file.size": "Compteur Volumétrique des fichiers envoyés",
    "dashboard.public.storage": "Public Storage",
    "dashboard.private.storage": "Private Storage",
    "dashboard.file.public": "Stockage IPFS Public",
    "dashboard.file.private": "Stockage IPFS Privé",
    "dashboard.file.count.consumed":
        "{consumed} fichiers sur {storage} consommés",
    "dashboard.file.size.consumed":
        "{consumed} Mo sur {storage} Mo volume total",
    "dashboard.volume.public": "Volume de Stockage Public",
    "dashboard.volume.private": "Volume de Stockage Privé",
    "dashboard.volume.storage": "Stockage de Volume",
    "dashboard.pub.file.size": "Public File Size",
    "dashboard.priv.file.size": "Private File Size",
    "dashboard.pub.file.count": "Public File Count",
    "dashboard.priv.file.count": "Private File Count",
};

export default dashboard;

const success = {
    "success.login": "Logged in successfully",
    "success.signup": "User successfully registred",
    "success.logout": "Logged out successfully",
    "success.account.confirmation":
        "Account successfully confirmed. You can signin as the user `{email}`.",
    "success.confirmation.email.send": "Confirmation email sent successfully.",
    "success.partnership.invitation.sent":
        "Partnership invitation successfully sent to the user.",
    "success.partnership.invitation.sent.via.email":
        "An invitation has been sent by e-mail to register with TracerDoc and become your partner.",
    "success.partnership.invitation.canceled":
        "Partnership invitation successfully canceled.",
    "success.partnership.invitation.accepted":
        "Partnership invitation successfully accepted.",
    "success.partnership.invitation.rejected":
        "Partnership invitation successfully rejected.",
    "success.document.transfered":
        "Document successfully encrypted and transfered to your partner.",
    "success.document.store.personal": "Document successfully encrypted.",
    "success.ipfs.config.edited": "IPFS configuration successfully edited.",
    "success.ipfs.service.edited": "IPFS service successfully edited.",
    "success.category.created": "Subcaregory successfully created.",
    "success.subcategory.created": "Subcaregory successfully created.",
    "success.save.encryption.public.key":
        "Encryption public key saved successfully",
};

export default success;

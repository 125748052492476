import { gql } from "@apollo/client";

export const SAVE_ENCRYPTION_KEY = gql`
    mutation SaveMyEncryptionPublicKey(
        $input: SaveMyEncryptionPublicKeyInput!
    ) {
        saveMyEncryptionPublicKey(input: $input) {
            address
            encryptionPublicKey
        }
    }
`;

const home = {
    "home.app": " ",
    "home.title": "Welcome to TracerDoc",
    "home.title.1": "Your Web 3.0 Document Storage and Transfer Platform",
    "home.title.2": "To secure, store, and share files",
    "home.title.3": "on decentralized storage platform",
    "home.subtitle": " ",
};

export default home;

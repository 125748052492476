import React, { FC } from "react";
import logo from "./../../images/tck-logo.png";

type Props = {
    height?: string;
    width?: string;
    withTracerkeyText?: boolean;
    style?: React.CSSProperties;
};

const TracerkeyLogo: FC<Props> = ({
    height,
    width,
    withTracerkeyText,
    style,
}) => {
    if (withTracerkeyText) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...style,
                }}
            >
                <img
                    src={logo}
                    height={height || "30px"}
                    width={width || "auto"}
                    alt="tclogo"
                />
                <div
                    style={{
                        color: "#4d4d4d",
                        marginLeft: 10,
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    TracerKey
                </div>
            </div>
        );
    }

    return (
        <img
            src={logo}
            height={height || "120px"}
            width={width || "auto"}
            alt="tclogo"
            style={style}
        />
    );
};

export default TracerkeyLogo;

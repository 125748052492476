import { makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../../constants/colors";

import { DRAWER_WIDTH } from "../constants";
const useStyles = makeStyles((theme: Theme) => ({
    drawerOpen: {
        width: DRAWER_WIDTH,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        // background: COLORS.backgroundDark,
        background: "#fefefe",
        zIndex: 0,
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(8) + 1,
        },
        // background: "linear-gradient(to left, #343A40, #263137)",
        zIndex: 0,
        background: "#fefefe",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: "nowrap",
        background: COLORS.backgroundDark,
        zIndex: 0,
    },
    activeListItem: {
        fontSize: 13,
        // color: COLORS.primaryLight,
        color: "#01579B",
        fontWeight: "bold",
    },
    listItemText: {
        fontSize: 13,
        // color: "#e0e0e0",
        color: "#212121",
    },
    transparent: {
        color: "transparent",
        fontSize: "1em",
    },
    arrowIcon: {
        color: "#696E75",
        marginTop: "-1em",
    },
    nested: {
        paddingLeft: "5em !important",
    },
}));

export default useStyles;

const error = {
    "error.unknown": "An error has occured",
    "error.not.installed.tracerkey": "TracerKey not installed",
    "error.no.tracerkey.account.found": "No TracerKey account found",
    "error.mismatch.account":
        "Mismatched accounts : TracerDoc account and TracerKey account",
    "error.auth": "Error on user authentification. Logout.",
    "error.page.not.found.title": "PAGE NOT FOUND",
    "error.page.not.found.subtitle":
        "The page you are looking for cannot be found.",
    "error.page.not.found.button": "Go back to site",
    "error.document.retrieve.decrypt":
        "Error occured when retrieving and decrypting the document.",
    "error.document.encryption": "Error occured when encrypting the document.",
    "error.document.decryption":
        "Error occured when decrypting the document. Make sure the document is encrypted with your public key.",
    "error.only.partner.can.decrypt":
        "Only your partner can decrypt this document.",
    "error.partner.public.key.locked":
        "This partner's public key is locked. You can not transfer documents to this partner.",
    "error.recover.missing.file":
        "TracerDoc file (.tdFile) is required for recover.",
    "error.recover.missing.key":
        "TracerDoc key (.tdKey) is required for recover.",
    "error.recover.decryption":
        "Error occured when decrypting the document. Make sure you are using the appropriate key for that file.",
    "error.document.decrypt": "Unknown error occured while decrypting.",
    "error.no.tdfile.found": "No tracedoc file found",
    "error.no.tdkey.found": "No tracerdoc key found",
};

export default error;

import { gql } from "@apollo/client";

// export const SUBSCRIBE_TO_PLAN = gql`
//     mutation SubscribeToPlan($input: SubscribeToPlanInput!) {
//         subscribeToPlan(input: $input)
//     }
// `;

export const GET_PORTAL_SESSION = gql`
    mutation GetPortalSession($returnUrl: String!) {
        portalSession(returnUrl: $returnUrl)
    }
`;

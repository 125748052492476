import React, { FC } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Divider, Drawer, Tooltip, Collapse } from "@material-ui/core";
import { withWidth, WithWidthProps } from "@material-ui/core";
import { DRAWER_ROUTES } from "../constants";
import { useIntl } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Button } from "antd";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import COLORS from "../../../constants/colors";
import Image from "react-bootstrap/Image";
import logo from "../../../images/logo.png";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    hidden?: boolean;
}

const DrawerMobile: FC<Props & RouteComponentProps & WithWidthProps> = (
    props
) => {
    const { open, setOpen, location, width, hidden } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const isWeb = width === "lg" || width === "md" || width === "xl";
    const isRouteActive = (route: string) =>
        location.pathname.startsWith(route);

    const subItems = (subItem: any) => {
        const { route, menu } = subItem;
        const activeSubRoute = location.pathname.startsWith(route);

        return (
            <>
                <Tooltip
                    placement="right"
                    title={formatMessage({
                        id: menu,
                    })}
                    arrow
                >
                    <ListItem
                        button
                        className={open ? classes.nested : ""}
                        component={Link}
                        to={route}
                        selected={route === location.pathname}
                        style={{
                            color: "#e0e0e0",
                            borderRight: activeSubRoute
                                ? `3px solid ${COLORS.primaryDark}`
                                : "",
                            background: activeSubRoute ? "#D3E2F7" : "",
                        }}
                    >
                        <ListItemText
                            primary={
                                open
                                    ? formatMessage({
                                          id: menu,
                                      })
                                    : formatMessage({
                                          id: menu,
                                      }).slice(0, 1)
                            }
                            classes={{
                                primary: open
                                    ? activeSubRoute
                                        ? classes.activeListItem
                                        : classes.listItemText
                                    : classes.transparent,
                            }}
                        />
                    </ListItem>
                </Tooltip>
            </>
        );
    };
    return (
        <Drawer
            hidden={hidden}
            variant={isWeb ? "permanent" : undefined}
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div
                className={classes.toolbar}
                style={{
                    borderBottom: "1px solid #D7D7DC",
                }}
            >
                <Image
                    src={logo}
                    width="155px"
                    height="auto"
                    className={open ? "pl-2" : "pl-1"}
                />
            </div>
            <div className="d-flex justify-content-between flex-column h-100">
                <List>
                    {DRAWER_ROUTES.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Tooltip
                                    placement="right"
                                    title={formatMessage({
                                        id: item.menu,
                                    })}
                                    arrow
                                    className={`${item.menu.toLowerCase()}`}
                                >
                                    <ListItem
                                        button
                                        selected={isRouteActive(item.route)}
                                        component={Link}
                                        key={index}
                                        to={
                                            item.route
                                                ? item.route
                                                : location.pathname
                                        }
                                        style={{
                                            color: "#e0e0e0",
                                            borderLeft: `${
                                                isRouteActive(item.route)
                                                    ? `2px solid ${COLORS.primaryDark}`
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        <ListItemIcon>
                                            {item.icon(
                                                isRouteActive(item.route)
                                                    ? {
                                                          color: "#0D47A1",
                                                          fontSize: "1.2em",
                                                      }
                                                    : undefined
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={formatMessage({
                                                id: item.menu,
                                            })}
                                            style={{ color: "red" }}
                                            classes={{
                                                primary: open
                                                    ? isRouteActive(item.route)
                                                        ? classes.activeListItem
                                                        : classes.listItemText
                                                    : classes.transparent,
                                            }}
                                        />
                                    </ListItem>
                                </Tooltip>

                                {item.sub && (
                                    <Collapse
                                        in={true}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        {item?.sub?.length
                                            ? item.sub.map(
                                                  (subItem: any, idx) => {
                                                      const { menu: subMenu } =
                                                          subItem;

                                                      return (
                                                          <List
                                                              disablePadding
                                                              key={idx}
                                                          >
                                                              <Tooltip
                                                                  placement="right"
                                                                  title={
                                                                      subMenu
                                                                  }
                                                                  className={subMenu.toLowerCase()}
                                                                  arrow
                                                              >
                                                                  {subItems(
                                                                      subItem
                                                                  )}
                                                              </Tooltip>
                                                          </List>
                                                      );
                                                  }
                                              )
                                            : ""}
                                    </Collapse>
                                )}

                                {!open && item.menu === "Partners" && (
                                    <Divider />
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
                <Button onClick={toggleDrawer} type="text">
                    {open ? (
                        <AiOutlineDoubleLeft className={classes.arrowIcon} />
                    ) : (
                        <AiOutlineDoubleRight className={classes.arrowIcon} />
                    )}
                </Button>
            </div>
        </Drawer>
    );
};

export default withRouter(withWidth()(DrawerMobile));

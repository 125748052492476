import { ApolloProvider } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { FC, useContext } from "react";
import createApolloClient from "../../services/apolloClient";
import { AuthContext } from "../AuthProvider/AuthProvider";

const ApolloAppProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { token } = useContext(AuthContext);
    const { keycloak } = useKeycloak();
    const keycloakToken = keycloak.token;
    const client = createApolloClient({ token, keycloakToken });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAppProvider;

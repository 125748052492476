import { FC } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab";

const TableLoader: FC = () => {
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.up("xl"));
    return (
        <div style={{ marginTop: "2em" }}>
            <Skeleton
                variant="rect"
                width="100%"
                height={20}
                className="mt-4"
                style={{ borderRadius: 3 }}
                animation="wave"
            />
            <Skeleton
                variant="rect"
                width="100%"
                height={300}
                className="mt-4"
                style={{ borderRadius: 3 }}
                animation="wave"
            />
            <Skeleton
                variant="rect"
                width="100%"
                height={100}
                className="mt-4"
                style={{ borderRadius: 3 }}
                animation="wave"
            />
            <Skeleton
                variant="rect"
                width="100%"
                height={100}
                className="mt-4"
                style={{ borderRadius: 3 }}
                animation="wave"
            />
            {lg ? (
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={200}
                    className="mt-4"
                    style={{ borderRadius: 3 }}
                    animation="wave"
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default TableLoader;

import type { Moment } from "moment";

export type CustomFC = {
    children?: React.ReactNode;
};

export enum RequestStatus {
    ALL = "ALL",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    CANCELED = "CANCELED",
}

export type RangeValue = [Moment | null, Moment | null] | null;

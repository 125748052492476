const menu = {
    "menu.dashboard": "Dashboard",
    "menu.home": "Home",
    "menu.partners": "Partners",
    "menu.documents": "Documents",
    "menu.IPFS": "IPFS Service",
    "menu.plan": "Plan",
    "menu.backupRecover": "Backup / Recover",
    "menu.myAccount": "My Account",
    "menu.subscription": "Subscription",
    "menu.categories": "Categories",
    "menu.admin.dashboard": "Dashboard",
    "menu.admin.users": "Users",
    "menu.admin.users.tx": "Stripe Users",
    "menu.admin.settings": "Settings",
    "menu.admin.settings.admins": "Administrators",
    "menu.admin.settings.licenses": "Licenses",
    "menu.admin.settings.pages": "Pages",
};

export default menu;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DocumentOwner {
  BOTH = "BOTH",
  RECIPIENT = "RECIPIENT",
  SENDER = "SENDER",
}

export enum DocumentTransferFilter {
  PERSONAL = "PERSONAL",
  SENT_BY_ME = "SENT_BY_ME",
  SENT_TO_ME = "SENT_TO_ME",
}

export enum ExpiresIn {
  D001 = "D001",
  D003 = "D003",
  D007 = "D007",
  D030 = "D030",
}

export enum IPFSType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum RequestStatus {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum TransferSecurityLevel {
  CONFIDENTIAL = "CONFIDENTIAL",
  PRIVATE = "PRIVATE",
  SECRET = "SECRET",
}

export enum WalletAuthAction {
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
}

export enum currencyTypes {
  aed = "aed",
  aud = "aud",
  bgn = "bgn",
  brl = "brl",
  cad = "cad",
  chf = "chf",
  czk = "czk",
  dkk = "dkk",
  eur = "eur",
  gbp = "gbp",
  hkd = "hkd",
  huf = "huf",
  inr = "inr",
  jpy = "jpy",
  mxn = "mxn",
  myr = "myr",
  nok = "nok",
  nzd = "nzd",
  pln = "pln",
  ron = "ron",
  sek = "sek",
  sgd = "sgd",
  usd = "usd",
}

export enum recurringInterval {
  day = "day",
  month = "month",
  week = "week",
  year = "year",
}

export interface AcknowledgeReceiptInput {
  id: number;
}

export interface AssociateCategoryToDocumentTransferInput {
  id: number;
  categoryIdsToAdd: HasIntID[];
  subCategoryIdsToAdd: HasIntID[];
  categoryIdsToDelete: HasIntID[];
  subCategoryIdsToDelete: HasIntID[];
}

export interface CreateSubCategoryInput {
  name: string;
  categoryId: number;
}

export interface DeleteDocumentTransferInput {
  ids: number[];
}

export interface GetUsersInput {
  filter?: string | null;
  withoutMe?: boolean | null;
  withoutMyPartners?: boolean | null;
  partnersOnly?: boolean | null;
}

export interface HasIntID {
  id: number;
}

export interface MyDocumentTransfersInput {
  filter?: DocumentTransferFilter | null;
  take?: number | null;
  skip?: number | null;
  categoryIds?: number[] | null;
  subCategoryIds?: number[] | null;
}

export interface RespondToPartnershipRequestInput {
  partnershipRequestId: number;
  status: RequestStatus;
}

export interface SaveMyEncryptionPublicKeyInput {
  walletAddress: string;
  publicKey: string;
}

export interface SendPartnershipRequestInput {
  targetEmail: string;
}

export interface SigninInput {
  message: string;
  signature: string;
}

export interface SignupInput {
  email: string;
  message: string;
  signature: string;
}

export interface TransferDocumentInput {
  userId?: string | null;
  securityLevel?: TransferSecurityLevel | null;
  document: any;
  recipientPassphrase?: any | null;
  senderPassphrase: any;
  fileName?: string | null;
  fileSize?: number | null;
  categoryIds?: number[] | null;
  subCategoryIds?: number[] | null;
  ipfsType: IPFSType;
  expiresIn?: ExpiresIn | null;
}

export interface UpdateCategoryInput {
  id: number;
  name: string;
}

export interface UpdateSubCategoryInput {
  id: number;
  name: string;
}

export interface WalletAuthInput {
  keycloakUserId: string;
  email: string;
  message: string;
  signature: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

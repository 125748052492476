import { FC } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useIntl } from "react-intl";

interface ISelectItem {
    value: any;
    label: string;
}

interface Props {
    handlechangeValue: (value: any) => void;
    items: ISelectItem[];
    value: any;
    label: string;
    defaultValue?: any;
    style?: React.CSSProperties;
}

const CustomSelect: FC<Props> = (props) => {
    const { formatMessage } = useIntl();
    const { handlechangeValue, items, value, defaultValue, style, label } =
        props;

    return (
        <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
                {formatMessage({ id: label })}
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                defaultValue={defaultValue}
                value={value}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                    handlechangeValue(event.target.value)
                }
                label={formatMessage({ id: label })}
                style={{
                    minWidth: 200,
                    ...style,
                }}
            >
                {items.map((item) => (
                    <MenuItem key={item.label} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;

import { gql } from "@apollo/client";

export const GET_CURRENT_STATE = gql`
    query GetCurrentState {
        currentState {
            license {
                name
                licenseType
                pubFilesizeMonthly
                pubFileCountMonthly
                prvFilesizeMonthly
                prvFileCountMonthly
            }
            pubFilesize
            pubFileCount
            prvFilesize
            prvFileCount
            currentStartPeriod
        }
    }
`;

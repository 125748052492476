const ipfs = {
    "ipfs.gateway": "Gateway",
    "ipfs.pinningService": "Pinning service",
    "ipfs.token": "Token",
    "ipfs.default.node": "Default IPFS node",
    "ipfs.pinata": "Pinata",
    "ipfs.nft.storage": "NFT.Storage",
    "ipfs.filebase": "Filebase",
    "ipfs.service": "IPFS service",
};

export default ipfs;

import { FC } from "react";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";
import { IoMdUnlock, IoMdLock } from "react-icons/io";
import COLORS from "../../constants/colors";

const useStyles = makeStyles(() => ({
    container: { display: "flex", alignItems: "center" },
    text: { marginLeft: 7 },
    statusText: { fontWeight: "normal" },
    descText: { fontSize: 12 },
}));

interface Props {
    activated: boolean;
    onClick?: () => void;
    isMyProfile?: boolean;
}

const CustomPartnerStatus: FC<Props> = ({ activated, isMyProfile }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Box className={classes.container}>
            <Tooltip
                title={
                    isMyProfile
                        ? ""
                        : formatMessage({
                              id: activated
                                  ? "common.partner.status.activated.tooltip"
                                  : "common.partner.status.locked.tooltip",
                          })
                }
                arrow
            >
                <Box>
                    {activated ? (
                        <IoMdUnlock
                            style={{
                                color: COLORS.greenDark,
                                fontSize: 15,
                                marginTop: "-4px",
                            }}
                        />
                    ) : (
                        <IoMdLock
                            style={{
                                color: COLORS.error,
                                fontSize: 15,
                                marginTop: "-4px",
                            }}
                        />
                    )}
                </Box>
            </Tooltip>
            <Box className={classes.text}>
                <Box className={classes.statusText}>
                    {formatMessage({
                        id: activated
                            ? "common.partner.status.activated"
                            : "common.partner.status.locked",
                    })}
                </Box>
                {isMyProfile && (
                    <Box className={classes.descText}>
                        {formatMessage({
                            id: activated
                                ? "common.partner.status.activated.desc"
                                : "common.partner.status.locked.desc",
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default CustomPartnerStatus;

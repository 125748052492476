import React, { useState, FC, createContext, useContext } from "react";
import { useIntl } from "react-intl";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import { DEFAULT_IPFS, IIpfsService, IPFS_SERVICES } from "../../utils/ipfs";
import { SnackBarContext } from "../SnackBarProvider/SnackBarProvider";

type IIPFSConfigContext = {
    token: string;
    setToken(token: string): void;
    removeToken(): void;
    service: IIpfsService;
    setService(service: IIpfsService): void;
};

export const IPFSConfigContext = createContext<IIPFSConfigContext>({
    token: "",
    setToken: () => {},
    removeToken: () => {},
    service: DEFAULT_IPFS,
    setService: () => {},
});

const IPFSConfigProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { formatMessage } = useIntl();
    // IPFS service id saved in localStorage
    const savedServiceId = localStorage.getItem(
        LOCAL_STORAGE_KEYS.IPFS_SERVICE_ID
    );
    const savedIpfsToken = localStorage.getItem(LOCAL_STORAGE_KEYS.IPFS_TOKEN);
    const [token, setToken] = useState(savedIpfsToken || "");

    const defaultService = savedServiceId
        ? IPFS_SERVICES.find((s) => s.id === savedServiceId)
        : DEFAULT_IPFS;
    const [service, setService] = useState<IIpfsService>(
        defaultService || DEFAULT_IPFS
    );

    const { displaySnackBar } = useContext(SnackBarContext);

    const handleChangeToken = (t: string) => {
        setToken(t);
        localStorage.setItem(LOCAL_STORAGE_KEYS.IPFS_TOKEN, t);
    };

    const removeToken = () => {
        setToken("");
        localStorage.removeItem(LOCAL_STORAGE_KEYS.IPFS_TOKEN);
    };

    const handleChangeService = (s: IIpfsService) => {
        setService(s);
        localStorage.setItem(LOCAL_STORAGE_KEYS.IPFS_SERVICE_ID, s.id);
    };

    const setIPFSService = (s: IIpfsService) => {
        handleChangeService(s);
        if (s.id === DEFAULT_IPFS.id) {
            removeToken();
        }
        displaySnackBar({
            message: formatMessage({ id: "success.ipfs.service.edited" }),
            type: "success",
        });
    };

    return (
        <IPFSConfigContext.Provider
            value={{
                token,
                setToken: handleChangeToken,
                removeToken,
                service,
                setService: setIPFSService,
            }}
        >
            {children}
        </IPFSConfigContext.Provider>
    );
};

export default IPFSConfigProvider;

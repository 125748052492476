import { gql } from "@apollo/client";
import { PARTNERSHIP_REQUEST_FRAGMENT } from "./fragment";
import { USER_FRAGMENT } from "../user/fragment";

export const SEND_PARTNERSHIP_REQUEST = gql`
    mutation SendPartnershipRequest($input: SendPartnershipRequestInput!) {
        sendPartnershipRequest(input: $input) {
            ...PartnershipRequestFields
            sender {
                id
                email
            }
            recipient {
                id
                email
            }
        }
    }
    ${PARTNERSHIP_REQUEST_FRAGMENT}
`;

export const CANCEL_PARTNERSHIP_REQUEST = gql`
    mutation CancelPartnershipRequest($partnershipRequestId: Int!) {
        cancelPartnershipRequest(partnershipRequestId: $partnershipRequestId) {
            ...PartnershipRequestFields
            sender {
                id
                email
            }
            recipient {
                id
                email
            }
        }
    }
    ${PARTNERSHIP_REQUEST_FRAGMENT}
`;

export const RESPOND_TO_PARTNERSHIP_REQUEST = gql`
    mutation RespondToPartnershipRequest(
        $input: RespondToPartnershipRequestInput!
    ) {
        respondToPartnershipRequest(input: $input) {
            ...PartnershipRequestFields
            sender {
                id
                email
            }
            recipient {
                id
                email
            }
        }
    }
    ${PARTNERSHIP_REQUEST_FRAGMENT}
`;

export const DELETE_PARTNERSHIP = gql`
    mutation DeletePartnership($partnershipId: Int!) {
        deletePartnership(partnershipId: $partnershipId) {
            id
            partner {
                ...UserFields
            }
        }
    }
    ${USER_FRAGMENT}
`;

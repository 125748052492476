import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { FC } from "react";
import { CustomFC } from "../../constants/types";

const Web3ReactCustomProvider: FC<CustomFC> = ({ children }) => {
    const getLibrary = (provider: any) => {
        const web3Provider = new Web3Provider(provider);
        return web3Provider;
    };

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            {children}
        </Web3ReactProvider>
    );
};

export default Web3ReactCustomProvider;

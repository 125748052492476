import Keycloak from "keycloak-js";
import config from "../config/config";

const keycloak = new Keycloak({
    clientId: config.keycloak.clientId,
    realm: config.keycloak.realmName,
    url: config.keycloak.baseUrl,
});

export default keycloak;

import { populateMessage } from "common";
import config from "../config/config";
import C from "./browsers";

interface IConnectResult {
    address: string;
    encryptionPublicKey: string;
}

export const tracerkeyCheckExtension = async () => {
    const checkExtensionPromise = new Promise((resolve, reject) => {
        if (!C || !C.runtime || !config.tracerkey.extensionId) {
            resolve(false);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "check_extension" },
            (response) => {
                if (
                    response &&
                    response.result &&
                    response.result === "tracerkey_installed"
                ) {
                    resolve(true);
                    console.log("TracerKey installed.");
                } else {
                    resolve(false);
                    console.log("TracerKey not installed.");
                }
            }
        );
    });

    const isTracerkeyInstalled = (await checkExtensionPromise) as boolean;
    return isTracerkeyInstalled;
};

export const tracerkeyConnect = async () => {
    const connectPromise = new Promise((resolve, reject) => {
        if (!config.tracerkey.extensionId) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "connect" },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const connectResult = (await connectPromise) as IConnectResult;

    return connectResult;
};

export const tracerkeyDisconnect = async () => {
    const connectPromise = new Promise((resolve, reject) => {
        if (!config.tracerkey.extensionId) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "disconnect" },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const disconnectResult = (await connectPromise) as boolean;

    return disconnectResult;
};

export const tracerkeyEthSign = async (address: string) => {
    const message = populateMessage({
        date: new Date(),
        website: config.appName,
        address,
    });

    const ethSignPromise = new Promise((resolve, reject) => {
        if (!config.tracerkey.extensionId) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "eth_sign", params: [address, message] },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const signature = (await ethSignPromise) as string;

    return { message, signature };
};

export const tracerkeyEthDecrypt = async (encryptedData: string) => {
    const { address } = await tracerkeyConnect();

    const ethDecryptPromise = new Promise((resolve, reject) => {
        if (!config.tracerkey.extensionId) {
            resolve(undefined);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "eth_decrypt", params: [encryptedData, address] },
            (response) => {
                if (response && response.result) {
                    resolve(response.result);
                } else {
                    resolve(undefined);
                }
            }
        );
    });

    const decryptedString = (await ethDecryptPromise) as string | undefined;

    return decryptedString;
};

export const signAuthMessageTracerkey = async (
    displayError: (messageId: string) => void
) => {
    const isTracerkeyInstalled = await tracerkeyCheckExtension();

    if (!isTracerkeyInstalled) {
        displayError("error.not.installed.tracerkey");
        return;
    }

    const connectResult = await tracerkeyConnect();

    if (!connectResult || !connectResult.address) {
        console.log("TracerKey connect failed.");
    }

    const address = connectResult.address;

    const { message, signature } = await tracerkeyEthSign(address);

    return { message, signature };
};

export const isConnected = async (params: boolean) => {
    const checkConnectionPromise = new Promise((resolve, reject) => {
        if (!C || !C.runtime || !config.tracerkey.extensionId) {
            resolve(false);
            return;
        }

        C.runtime.sendMessage(
            config.tracerkey.extensionId,
            { method: "connected", params },
            (response) => {
                if (
                    response &&
                    response.result &&
                    response.result === "tracerkey_connected"
                ) {
                    resolve(true);
                    console.log("TracerKey connected.");
                } else {
                    resolve(false);
                    console.log("Tracerkey connection failed");
                }
            }
        );
    });

    const isConnectionSucess = (await checkConnectionPromise) as boolean;
    return isConnectionSucess;
};

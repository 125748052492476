import { useKeycloak } from "@react-keycloak/web";
import { FC, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomSelect from "../../components/CustomMuiSelect/CustomMuiSelect";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import { ROUTES } from "../../constants/routes";
import { IPFSConfigContext } from "../../providers/IPFSConfigProvider/IPFSConfigProvider";
import { DEFAULT_IPFS, IIpfsService, IPFS_SERVICES } from "../../utils/ipfs";

const IPFS: FC<RouteComponentProps> = () => {
    const {
        token: contextToken,
        setToken: setContextToken,
        removeToken,
        service: contextService,
        setService: setContextService,
    } = useContext(IPFSConfigContext);
    const [token, setToken] = useState(contextToken);
    const [service, setService] = useState(contextService);
    const { formatMessage } = useIntl();
    const { keycloak } = useKeycloak();
    const keycloakToken = keycloak.token;

    const handleSave = () => {
        if (service.id === DEFAULT_IPFS.id) {
            setToken("");
            removeToken();
        } else {
            setContextToken(token);
        }

        setContextService(service);
    };

    const handlechangeService = (value: IIpfsService) => {
        setService(value);
    };

    return (
        <PageContainer>
            <TitleWithBreadcrumb
                title="menu.IPFS"
                routes={[{ path: ROUTES.IPFS, title: "menu.IPFS" }]}
            />
            <CustomPaper>
                <CustomSelect
                    items={IPFS_SERVICES.map((s) => ({
                        label: formatMessage({ id: s.name }),
                        value: s,
                    }))}
                    defaultValue={DEFAULT_IPFS}
                    value={service}
                    handlechangeValue={handlechangeService}
                    label="ipfs.service"
                />

                <CustomTextField
                    label={formatMessage({ id: "ipfs.gateway" })}
                    type="text"
                    name="gateway"
                    value={service.gateway}
                    disabled
                />
                <CustomTextField
                    label={formatMessage({ id: "ipfs.pinningService" })}
                    type="text"
                    name="pinningService"
                    value={service.pinningService}
                    disabled
                />
                {service.id === DEFAULT_IPFS.id && (
                    <CustomTextField
                        label={formatMessage({ id: "ipfs.token" })}
                        type="text"
                        name="token"
                        value={keycloakToken}
                        disabled
                        multiline
                    />
                )}
                {service.id !== DEFAULT_IPFS.id && (
                    <CustomTextField
                        label={formatMessage({ id: "ipfs.token" })}
                        type="text"
                        name="token"
                        value={token}
                        handlechange={(value) => {
                            setToken(value);
                        }}
                        multiline
                    />
                )}
                <div
                    className="d-flex"
                    style={{ justifyContent: "flex-end", marginTop: 20 }}
                >
                    <CustomButton
                        onClick={handleSave}
                        text={formatMessage({
                            id: "common.save",
                        })}
                        disabled={
                            service.id === contextService.id &&
                            token === contextToken
                        }
                        style={{ width: "30%", margin: 0 }}
                    />
                </div>
            </CustomPaper>
        </PageContainer>
    );
};

export default withRouter(IPFS);

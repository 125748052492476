import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CustomPaper from "../../../components/CustomPaper/CustomPaper";
import PageContainer from "../../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import { ROUTES } from "../../../constants/routes";

const StripeUsers: FC<RouteComponentProps> = () => {
    return (
        <PageContainer>
            <TitleWithBreadcrumb
                title="menu.admin.users.tx"
                routes={[
                    {
                        path: ROUTES.ADMIN_USERS_TX,
                        title: "menu.admin.users.tx",
                    },
                ]}
            />
            <CustomPaper>
                <p>Content here</p>
            </CustomPaper>
        </PageContainer>
    );
};

export default withRouter(StripeUsers);

import { RequestStatus } from "../types/graphql-global-types";

export const getRequestStatus = (tab: string) => {
    switch (tab) {
        case "common.pending":
            return RequestStatus.PENDING;

        case "common.accepted":
            return RequestStatus.ACCEPTED;

        case "common.rejected":
            return RequestStatus.REJECTED;

        case "common.canceled":
            return RequestStatus.CANCELED;

        default:
            return undefined;
    }
};

export const getStatusText = (status: RequestStatus) => {
    switch (status) {
        case RequestStatus.PENDING:
            return "common.pending";

        case RequestStatus.ACCEPTED:
            return "common.accepted";

        case RequestStatus.REJECTED:
            return "common.rejected";

        case RequestStatus.CANCELED:
            return "common.canceled";

        default:
            return "";
    }
};

import { FC, useContext } from "react";
import { useQuery } from "@apollo/client";
import { UserContext } from "../../../providers/UserProvider/UserProvider";
import { GET_REFERENCE_ID } from "../../../graphql/user/query";
import config from "../../../config/config";

interface Props {
    pricingTableId: string | undefined;
    className?: string;
}

interface ReferenceId {
    referenceId: string;
}

const PricingTable: FC<Props> = ({ pricingTableId, className }) => {
    const { me } = useContext(UserContext);
    const { data } = useQuery<ReferenceId>(GET_REFERENCE_ID);
    const { referenceId } = data || {};

    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: `
                    <stripe-pricing-table
                        pricing-table-id="${pricingTableId}"
                        publishable-key="${config.stripe.publishableKey}"
                        client-reference-id="${referenceId}"
                        customer-email="${me?.email}"
                    >
                    </stripe-pricing-table>`,
            }}
        />
    );
};

export default PricingTable;

import React, { FC, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { AuthContext } from "../../../../providers/AuthProvider/AuthProvider";
import { UserContext } from "../../../../providers/UserProvider/UserProvider";

interface Props {
    exact?: boolean;
    path: ROUTES | string;
    children: React.ReactNode;
}

const PrivateRoute: FC<Props> = (props) => {
    const { exact, path, children } = props;
    const { token } = useContext(AuthContext);
    const { meKeycloak } = useContext(UserContext);

    return (
        <Route
            exact={exact}
            path={path}
            render={({ location }) =>
                meKeycloak && token ? (
                    children
                ) : meKeycloak && !token ? (
                    <Redirect
                        to={{
                            pathname: ROUTES.WALLET_AUTH,
                            state: JSON.stringify({ from: location }),
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.SIGNIN,
                            state: JSON.stringify({ from: location }),
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;

import { makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
    content: { padding: "2em 3em" },
    contentText: {
        color: COLORS.black,
        fontSize: 15,
        marginBottom: "3em",
        textAlign: "center",
        marginTop: "1em",
    },
    cancel: {
        width: "100%",
        borderRadius: 25,
        "&:hover": { transform: "scale(1.05)" },
    },
    submit: {
        width: "100%",
        borderRadius: 25,
        background: `${COLORS.blue} !important`,
        color: `${COLORS.white} !important`,
        "&:hover": { transform: "scale(1.02)" },
    },
    dialogTitle: { [theme.breakpoints.only("xs")]: { padding: "1em" } },
    disabled: {},
    title: { fontWeight: 600 },
    closeButton: {
        position: "absolute",
        right: theme.spacing(2.3),
        top: theme.spacing(2),
        color: "grey",
    },
    loader: {
        backgroundColor: COLORS.primaryTransparent,
    },
    loaderBar: {
        backgroundColor: COLORS.primaryDark,
    },
    acceptBtn: { borderRadius: 5 },
    deleteBtn: {
        background: `${COLORS.red} !important`,
        color: `${COLORS.white} !important`,
        borderRadius: 5,
    },
}));

export default useStyles;

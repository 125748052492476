import { BrowserRouter } from "react-router-dom";
import Layout from "../Layout/Layout";
import RouteStack from "./RouteStack/RouteStack";

const Routes = () => {
    return (
        <BrowserRouter>
            <Layout>
                <RouteStack />
            </Layout>
        </BrowserRouter>
    );
};

export default Routes;

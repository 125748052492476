import React, { FC, useState } from "react";
import clsx from "clsx";
import { Collapse, Divider, Drawer, Tooltip } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { DRAWER_ROUTES, ADMIN_DRAWER_ROUTES } from "../constants";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button } from "antd";
import COLORS from "../../../constants/colors";
import Image from "react-bootstrap/Image";
import logo from "../../../images/logo.png";
import useStyles from "./styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ROUTES } from "../../../constants/routes";

interface Props {
    open: boolean;
    toggleDrawer: (drawer: boolean) => void;
    hidden?: boolean;
}

const DrawerWeb: FC<Props & RouteComponentProps> = (props) => {
    const { open, location, toggleDrawer, hidden } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [isCollapsedMenu, setIsCollapsedMenu] = useState(false);

    const isRouteActive = (route: string) =>
        location.pathname.startsWith(route);

    // temp
    const _routes = location.pathname.startsWith("/bo")
        ? ADMIN_DRAWER_ROUTES
        : DRAWER_ROUTES;

    const subItems = (subItem: any) => {
        const { route, icon, menu } = subItem;
        const activeSubRoute = location.pathname.startsWith(route);

        return (
            <>
                <Tooltip
                    placement="right"
                    title={formatMessage({
                        id: menu,
                    })}
                    arrow
                >
                    <ListItem
                        button
                        className={open ? classes.nested : ""}
                        component={Link}
                        to={route}
                        selected={route === location.pathname}
                        onClick={() => {
                            if (route === ROUTES.SUBSCRIPTION) {
                                toggleDrawer(false);
                            }
                        }}
                        style={{
                            color: "#e0e0e0",
                            borderRight: activeSubRoute
                                ? `3px solid ${COLORS.primaryDark}`
                                : "",
                            background: activeSubRoute ? "#D3E2F7" : "",
                        }}
                    >
                        {!open && (
                            <ListItemIcon>
                                {icon(
                                    activeSubRoute
                                        ? {
                                              color: "#0D47A1",
                                              fontSize: "1.2em",
                                          }
                                        : undefined
                                )}
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={
                                open
                                    ? formatMessage({
                                          id: menu,
                                      })
                                    : formatMessage({
                                          id: menu,
                                      }).slice(0, 1)
                            }
                            classes={{
                                primary: open
                                    ? activeSubRoute
                                        ? classes.activeListItem
                                        : classes.listItemText
                                    : classes.transparent,
                            }}
                        />
                    </ListItem>
                </Tooltip>
            </>
        );
    };
    return (
        <Drawer
            hidden={hidden}
            variant="permanent"
            className={clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <Link to={ROUTES.DASHBOARD}>
                    <Image
                        src={logo}
                        width="155px"
                        height="auto"
                        className={open ? "pl-2" : "pl-1"}
                    />
                </Link>
            </div>
            <div className="d-flex justify-content-between flex-column h-100">
                <List>
                    {_routes.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Tooltip
                                    placement="right"
                                    title={formatMessage({
                                        id: item.menu,
                                    })}
                                    arrow
                                    className={`${item.menu.toLowerCase()}`}
                                >
                                    <ListItem
                                        button
                                        selected={isRouteActive(item.route)}
                                        component={Link}
                                        key={index}
                                        to={
                                            item.route &&
                                            item.route !== ROUTES.MY_ACCOUNT
                                                ? item.route
                                                : location.pathname
                                        }
                                        onClick={() =>
                                            item?.sub
                                                ? setIsCollapsedMenu(
                                                      !isCollapsedMenu
                                                  )
                                                : null
                                        }
                                        style={{
                                            color: "#e0e0e0",
                                            background: isRouteActive(
                                                item.route
                                            )
                                                ? "#CCDCF8"
                                                : "",
                                            borderRight: `${
                                                isRouteActive(item.route)
                                                    ? `3px solid ${COLORS.primaryDark}`
                                                    : ""
                                            }`,
                                        }}
                                    >
                                        <ListItemIcon>
                                            {item.icon(
                                                isRouteActive(item.route)
                                                    ? {
                                                          color: "#0D47A1",
                                                          fontSize: "1.2em",
                                                      }
                                                    : undefined
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                open
                                                    ? formatMessage({
                                                          id: item.menu,
                                                      })
                                                    : formatMessage({
                                                          id: item.menu,
                                                      }).slice(0, 1)
                                            }
                                            classes={{
                                                primary: open
                                                    ? isRouteActive(item.route)
                                                        ? classes.activeListItem
                                                        : classes.listItemText
                                                    : classes.transparent,
                                            }}
                                        />
                                        {item?.sub ? (
                                            isCollapsedMenu ? (
                                                <ExpandLess
                                                    className={
                                                        classes.expandIcon
                                                    }
                                                />
                                            ) : (
                                                <ExpandMore
                                                    className={
                                                        classes.expandIcon
                                                    }
                                                />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </ListItem>
                                </Tooltip>

                                {item.sub && (
                                    <Collapse
                                        in={isCollapsedMenu}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        {item?.sub?.length
                                            ? item.sub.map(
                                                  (subItem: any, idx) => {
                                                      const { menu: subMenu } =
                                                          subItem;

                                                      return (
                                                          <List
                                                              disablePadding
                                                              key={idx}
                                                          >
                                                              <Tooltip
                                                                  placement="right"
                                                                  title={
                                                                      subMenu
                                                                  }
                                                                  className={subMenu.toLowerCase()}
                                                                  arrow
                                                              >
                                                                  {subItems(
                                                                      subItem
                                                                  )}
                                                              </Tooltip>
                                                          </List>
                                                      );
                                                  }
                                              )
                                            : ""}
                                    </Collapse>
                                )}

                                {!open && item.menu === "Partners" && (
                                    <Divider />
                                )}
                            </React.Fragment>
                        );
                    })}
                </List>
                <Button onClick={() => toggleDrawer(!open)} type="text">
                    {open ? (
                        <AiOutlineDoubleLeft className={classes.arrowIcon} />
                    ) : (
                        <AiOutlineDoubleRight className={classes.arrowIcon} />
                    )}
                </Button>
            </div>
        </Drawer>
    );
};

export default withRouter(DrawerWeb);

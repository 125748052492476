import { makeStyles, Theme, createStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        banner: {
            height: "180px",
            marginTop: "0",
            padding: "1.6em",
            border: `1px solid ${COLORS.white100}`,
            borderRadius: 5,
            boxShadow: "3px 3px 10px -3px rgba(0, 0, 0, 0.15)",
            overflow: "visible",
            background: COLORS.blue400,
        },
        white: {
            color: COLORS.white,
        },
        imgContainer: {
            height: "150px",
            width: "100%",
            position: "relative",
            overflowY: "visible",
        },
        welcomeImg: {
            marginTop: "-2.5em",
            marginLeft: "3em",
            height: "190px",
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                display: "none",
            },
        },
        progressLabel: {
            fontSize: 16,
            fontWeight: 600,
        },
        publicContainer: {
            background: COLORS.blue500,
            width: "100%",
            padding: "0.7em 1em",
            borderTop: `2px solid ${COLORS.blue}`,
            boxShadow: "3px 0px 10px -3px rgba(0,0,0,0.15)",
        },
        privateContainer: {
            width: "100%",
            background: COLORS.orange,
            boxShadow: "3px 0px 10px -3px rgba(0,0,0,0.15)",
            padding: "0.7em 1em",
            borderTop: `2px solid ${COLORS.orange100}`,
        },
        loader: {
            borderRadius: 3,
            boxShadow: "0px 0px 21px -3px rgba(0,0,0,0.05)",
        },
        paperBoxShadow: {
            boxShadow: "0px 0px 21px -3px rgba(0,0,0,0.05)",
        },
        charts: {
            marginTop: "-0.7em",
            [theme.breakpoints.only("xl")]: {
                marginTop: "0",
            },
        },
    });
});

export default useStyles;

import { Toolbar, AppBar, withWidth, Tooltip } from "@material-ui/core";
import { IconButton, WithWidthProps } from "@material-ui/core";
import { Button as MaterialButton } from "@material-ui/core";
import { Button, Space } from "antd";
import { FC, useContext } from "react";
import { BiMenu } from "react-icons/bi";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { MdHelp } from "react-icons/md";
import clsx from "clsx";
import LanguageOptions from "../LanguageOptions/LanguageOptions";
import UserMenu from "../UserMenu/UserMenu";
import COLORS from "../../constants/colors";
import useStyles from "./useStyles";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    toggleDrawer: (drawer: boolean) => void;
}

const CustomAppBar: FC<Props & WithWidthProps & RouteComponentProps> = ({
    width,
    open,
    toggleDrawer,
    history,
    location,
}) => {
    const classes = useStyles();
    const { me } = useContext(UserContext);
    const { formatMessage } = useIntl();
    const isAdminPath = location.pathname.startsWith("/bo");

    const isMobile = width === "sm" || width === "xs";

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: isMobile ? true : open,
            })}
        >
            <Toolbar className="d-flex justify-content-between">
                <IconButton
                    onClick={() => toggleDrawer(!open)}
                    style={{ marginLeft: "-4px" }}
                >
                    <BiMenu color="#8B96A9" fontSize={24} />
                </IconButton>

                <Space className="d-flex justify-content-between">
                    {me?.isFullAdmin && (
                        <MaterialButton
                            variant="outlined"
                            style={{
                                border: `1px solid ${COLORS.primaryDark}`,
                                color: COLORS.primaryDark,
                                marginRight: 20,
                            }}
                            onClick={() => {
                                history.push(
                                    isAdminPath ? ROUTES.HOME : ROUTES.ADMIN
                                );
                            }}
                        >
                            {formatMessage({
                                id: isAdminPath
                                    ? "common.normal.user"
                                    : "common.admin",
                            })}
                        </MaterialButton>
                    )}
                    <LanguageOptions isOnAppBar />
                    <Tooltip title="Help Center" arrow>
                        <Button
                            type="link"
                            shape="circle"
                            size="large"
                            onClick={() => {}}
                            className={classes.iconBtn}
                            href="https://wiki.tracerdoc.com"
                            target="_blank"
                        >
                            <MdHelp className={classes.barIcon} fontSize={24} />
                        </Button>
                    </Tooltip>
                    <UserMenu />
                </Space>
            </Toolbar>
        </AppBar>
    );
};

export default withWidth()(withRouter(CustomAppBar));

const partnership = {
    "partnership.tab.partners": "Partenaires",
    "partnership.tab.invitations": "Invitations",
    "partnership.invitation.invite": "Nouveau partenaire",
    "partnership.invitation.title.common.all": "Toutes les invitations",
    "partnership.invitation.title.common.pending": "Invitations en attente",
    "partnership.invitation.title.common.accepted": "Invitations acceptées",
    "partnership.invitation.title.common.rejected": "Invitations rejetées",
    "partnership.invitation.title.common.canceled": "Invitations annulées",
    "partnership.invitation.new.partner.title": "Nouveau partenaire",
    "partnership.invitation.new.partner.subtitle":
        "Envoyez une invitation de partenariat à un utilisateur TracerDoc ou invitez un nouvel utilisateur à s'inscrire sur TracerDoc et à devenir votre partenaire.",
    "partnership.invitation.new.partner.not.registred":
        "Cet utilisateur n'est pas enregistré sur TracerDoc, inviter par email ?",
    "partnership.partners.title": "La liste de vos partenaires",
    "partnership.cancel.title": "Annuler l'invitation au partenariat",
    "partnership.cancel.text":
        "Êtes-vous sûr de vouloir annuler votre demande d'invitation de partenariat à {partner}",
    "partnership.accept.title": "Accepter l'invitation de partenariat",
    "partnership.accept.text":
        "Acceptez l'invitation pour devenir partenaire de {partner}",
    "partnership.reject.title": "Refuser l'invitation au partenariat",
    "partnership.reject.text":
        "Êtes-vous sûr de vouloir refuser l'invitation de partenariat de {partner}",
    "partnership.confirm.invite.cancel": "Oui, Confirmez",
    "partnership.confirm.invite.accept": "Accepter",
    "partnership.confirm.invite.reject": "Déclin",
    "partnership.confirm.delete":
        "Are you sure you want to delete this partnership {partner} ? You will not be able anymore to send or receive file from/to this partner.",
};

export default partnership;

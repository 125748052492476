import { Button, Space } from "antd";
import { Tooltip } from "@material-ui/core";
import { RiRecordCircleFill } from "react-icons/ri";
import { DATETIME_FORMAT } from "../../../constants/datetime";
import { FaUnlockAlt } from "react-icons/fa";
import { MyDocumentTransfers_myDocumentTransfers_documents } from "../../../graphql/document/types/MyDocumentTransfers";
import COLORS from "../../../constants/colors";
import moment from "moment";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const Columns = (
    type: string,
    viewDocInfo: (
        open: boolean,
        document: MyDocumentTransfers_myDocumentTransfers_documents | null
    ) => void,
    classes: ClassNameMap,
    decryptAndDownload: (
        doc: MyDocumentTransfers_myDocumentTransfers_documents
    ) => void,
    loading: boolean,
    selectedDoc: MyDocumentTransfers_myDocumentTransfers_documents | null,
    formatMessage: any,
    page: number,
    pageSize: number
) => {
    const defaultColumns = [
        {
            title: "#",
            dataIndex: "no",
            key: "no",
            render: (_: any, __: any, idx: number) => {
                return idx + 1 + (page - 1) * pageSize;
            },
        },
        {
            title: formatMessage({
                id: "document.fileName",
            }),
            dataIndex: "fileName",
            key: "fileName",
            sorter: (a: any, b: any) => a.fileName.localeCompare(b.fileName),
            render: (
                fileName: string,
                item: MyDocumentTransfers_myDocumentTransfers_documents
            ) => {
                return (
                    <Tooltip title={fileName}>
                        <span
                            onClick={() => viewDocInfo(true, item)}
                            className={classes.fileName}
                        >
                            {fileName
                                ? fileName?.length > 11
                                    ? fileName
                                          .slice(0, 10)
                                          .concat("..")
                                          .concat(
                                              fileName.slice(
                                                  fileName.length - 4
                                              )
                                          )
                                    : fileName
                                : ""}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            title: "IPFS Type",
            dataIndex: "ipfsType",
            key: "ipfsType",
            sorter: (a: any, b: any) => b.ipfsType.localeCompare(a.ipfsType),
        },
        {
            title: formatMessage({
                id: "common.date",
            }),
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: (a: any, b: any) => b.createdAt.localeCompare(a.createdAt),
            render: (dateCreated: string) =>
                moment(dateCreated).format(DATETIME_FORMAT),
        },
        {
            title: formatMessage({
                id: "common.actions",
            }),
            dataIndex: "actions",
            key: "actions",
            width: 100,
            render: (
                _: any,
                doc: MyDocumentTransfers_myDocumentTransfers_documents
            ) => {
                return (
                    <Space>
                        <Button
                            size="middle"
                            onClick={() => decryptAndDownload(doc)}
                            loading={loading && selectedDoc?.id === doc?.id}
                            type="ghost"
                            className={classes.actionBtn}
                        >
                            <FaUnlockAlt className={classes.unlockIcon} />
                            {formatMessage({
                                id: "document.download.decrypt",
                            })}
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const sender = {
        title: formatMessage({
            id: "document.sender",
        }),
        dataIndex: "sender",
        key: "sender",
        sorter: (a: any, b: any) =>
            a.sender.email.localeCompare(b.sender.email),
        render: (
            sender: any,
            item: MyDocumentTransfers_myDocumentTransfers_documents
        ) => {
            return (
                <>
                    <RiRecordCircleFill
                        style={{
                            fontSize: 15,
                            color: item?.sentByMe
                                ? COLORS.greenDark
                                : COLORS.primaryLight,
                            marginRight: 5,
                        }}
                    />
                    {sender?.email || ""}
                </>
            );
        },
    };
    const recipient = {
        title: formatMessage({
            id: "document.recipient",
        }),
        dataIndex: "recipient",
        key: "recipient",
        sorter: (a: any, b: any) =>
            a?.recipient?.email.localeCompare(b?.recipient?.email),
        render: (
            _: any,
            item: MyDocumentTransfers_myDocumentTransfers_documents
        ) => {
            return (
                <>
                    {item?.recipient?.email && (
                        <>
                            <RiRecordCircleFill
                                style={{
                                    fontSize: 15,
                                    color: item?.sentToMe
                                        ? COLORS.greenDark
                                        : COLORS.primaryLight,
                                    marginRight: 5,
                                }}
                            />
                            {`${item?.recipient?.email} ${
                                item?.sentToMe
                                    ? `(${formatMessage({
                                          id: "common.you",
                                      })})`
                                    : ""
                            }`}
                        </>
                    )}
                </>
            );
        },
    };
    if (type === "common.sent.by.me") defaultColumns.splice(3, 0, recipient);
    if (type === "common.sent.to.me") defaultColumns.splice(3, 0, sender);
    if (type === "documents.all")
        defaultColumns.splice(3, 0, sender, recipient);
    return defaultColumns;
};

export default Columns;

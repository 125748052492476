import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "antd/dist/antd.css";
// import "antd/dist/antd.min.css";
import Routes from "./components/Routes/Routes";
import SnackBarProvider from "./providers/SnackBarProvider/SnackBarProvider";
import GlobalSnackbar from "./components/GlobalSnackbar/GlobalSnackbar";
import AuthProvider from "./providers/AuthProvider/AuthProvider";
import ApolloAppProvider from "./providers/ApolloAppProvider/ApolloAppProvider";
import UserProvider from "./providers/UserProvider/UserProvider";
import TranslationProvider from "./providers/TranslationProvider/TranslationProvider";
import Web3ReactCustomProvider from "./providers/Web3ReactProvider/Web3ReactCustomProvider";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { HelmetProvider } from "react-helmet-async";
import COLORS from "./constants/colors";
import IPFSConfigProvider from "./providers/IPFSConfigProvider/IPFSConfigProvider";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./services/keyCloak";
import CustomLoader from "./components/CustomLoader/CustomLoader";
import EncryptionKeyProvider from "./providers/EncryptionKeyProvider/EncryptionKeyProvider";
import config from "./config/config";

console.log("Expecting tracerkey id", config.tracerkey.extensionId);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            background: COLORS.appBackground,
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            // temp
            overflowY: "hidden",
        },
    })
);

const App = () => {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <TranslationProvider>
                <ReactKeycloakProvider
                    authClient={keycloak}
                    initOptions={{ onLoad: "check-sso" }}
                    LoadingComponent={
                        <CustomLoader text="common.loading.keycloak" />
                    }
                >
                    <HelmetProvider>
                        <SnackBarProvider>
                            <IPFSConfigProvider>
                                <Web3ReactCustomProvider>
                                    <AuthProvider>
                                        <ApolloAppProvider>
                                            <UserProvider>
                                                <EncryptionKeyProvider>
                                                    <GlobalSnackbar />
                                                    <Routes />
                                                </EncryptionKeyProvider>
                                            </UserProvider>
                                        </ApolloAppProvider>
                                    </AuthProvider>
                                </Web3ReactCustomProvider>
                            </IPFSConfigProvider>
                        </SnackBarProvider>
                    </HelmetProvider>
                </ReactKeycloakProvider>
            </TranslationProvider>
        </div>
    );
};

export default App;

const dashboard = {
    "dashboard.title": "Dashboard",
    "dashboard.banner.welcome": "Welcome to Tracerdoc !",
    "dashboard.banner.welcome.subtitle": " ",
    "dashboard.file.count": "File Count",
    "dashboard.public.storage": "Public Storage",
    "dashboard.private.storage": "Private Storage",
    "dashboard.file.size": "File Size",
    "dashboard.file.public": "IPFS Public Storage",
    "dashboard.file.private": "IPFS Private Storage",
    "dashboard.file.count.consumed": "{consumed} of {storage} files consumed",
    "dashboard.file.size.consumed": "{consumed} MB of {storage} MB consumed",
    "dashboard.volume.public": "Volume Public Storage",
    "dashboard.volume.private": "Volume Private Storage",
    "dashboard.volume.storage": "Volume Storage",
    "dashboard.pub.file.size": "Public File Size",
    "dashboard.priv.file.size": "Private File Size",
    "dashboard.pub.file.count": "Public File Count",
    "dashboard.priv.file.count": "Private File Count",
};

export default dashboard;

import { Avatar, Badge, makeStyles, withStyles } from "@material-ui/core";
import React, { FC } from "react";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
        background: "#343A40",
        border: "3px solid white",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            marginTop: "15px",
        },
    },
}));

const Styledbadge = withStyles((theme) => ({
    badge: {
        backgroundColor: "#44b700",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "10px",
        },

        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(1.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(.9)",
            opacity: 0,
        },
    },
}))(Badge);

interface Props {
    src?: string | null | undefined;
}

const UserBadge: FC<Props> = ({ src }) => {
    const classes = useStyles();

    return (
        <div>
            <Styledbadge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
            >
                <Avatar aria-label="recipe" className={classes.avatar}>
                    <PersonIcon fontSize="large" />
                </Avatar>
            </Styledbadge>
        </div>
    );
};

export default UserBadge;

import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "../user/fragment";

export const SIGNUP = gql`
    mutation Signup($input: SignupInput!) {
        signup(input: $input) {
            createdUser
            confirmationEmailSent
            userAddress
        }
    }
`;

export const SIGNIN = gql`
    mutation Signin($input: SigninInput!) {
        signin(input: $input) {
            access
            lastLogin
        }
    }
`;

export const WALLET_AUTH = gql`
    mutation WalletAuth($input: WalletAuthInput!) {
        walletAuth(input: $input) {
            action
            token
            createdUserId
        }
    }
`;

export const SEND_CONFIRMATION_EMAIL = gql`
    mutation SendConfirmationEmail($email: String!) {
        sendConfirmationEmail(email: $email)
    }
`;

export const CONFIRM_ACCOUNT = gql`
    mutation ConfirmAccount($confirmationToken: String!) {
        confirmAccount(confirmationToken: $confirmationToken) {
            ...UserFields
        }
    }
    ${USER_FRAGMENT}
`;

import { gql } from "@apollo/client";

export const GET_MY_CATEGORIES = gql`
    query GetMyCategories {
        myCategories {
            id
            name
            subCategories {
                id
                name
            }
        }
    }
`;

export const GET_MY_CATEGORIES_DATA = gql`
    query GetMyCategoriesData {
        myCategoriesData {
            categories {
                id
                name
            }
            subCategories {
                id
                name
            }
        }
    }
`;

import { createContext, FC, useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import { useMutation } from "@apollo/client";
import {
    SaveMyEncryptionPublicKey,
    SaveMyEncryptionPublicKeyVariables,
} from "../../graphql/user/types/SaveMyEncryptionPublicKey";
import { SAVE_ENCRYPTION_KEY } from "../../graphql/user/mutation";
import { SnackBarContext } from "../SnackBarProvider/SnackBarProvider";
import { useIntl } from "react-intl";
import { GET_ME } from "../../graphql/user/query";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import { tracerkeyConnect } from "../../services/tracerkey";

type IEncryptionKeyContext = {
    handleClose: () => void;
    handleOpen: () => void;
};

export const EncryptionKeyContext = createContext<IEncryptionKeyContext>({
    handleClose: () => {},
    handleOpen: () => {},
});

const EncryptionKeyProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { me } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();

    const [savePubKey, { loading }] = useMutation<
        SaveMyEncryptionPublicKey,
        SaveMyEncryptionPublicKeyVariables
    >(SAVE_ENCRYPTION_KEY, {
        onCompleted: (data) => {
            if (data.saveMyEncryptionPublicKey.encryptionPublicKey) {
                displaySnackBar({
                    message: formatMessage({
                        id: "success.save.encryption.public.key",
                    }),
                    type: "success",
                });
                setOpen(false);
            }
        },
        onError: (error) => {
            displaySnackBar({
                message: formatMessage({
                    id: error.message || "error.unknown",
                }),
                type: "error",
            });
        },
        refetchQueries: [{ query: GET_ME }],
    });

    useEffect(() => {
        if (
            me &&
            !me.wallet.encryptionPublicKey &&
            !localStorage.getItem(
                LOCAL_STORAGE_KEYS.PUBLIC_KEY_GENERATION_CHOICE
            )
        ) {
            setOpen(true);
        }
    }, [me]);

    const save = async () => {
        const { encryptionPublicKey } = await tracerkeyConnect();
        if (encryptionPublicKey) {
            savePubKey({
                variables: {
                    input: {
                        walletAddress: me?.wallet.address || "",
                        publicKey: encryptionPublicKey,
                    },
                },
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.PUBLIC_KEY_GENERATION_CHOICE,
            "skipped"
        );
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <EncryptionKeyContext.Provider
            value={{
                handleClose,
                handleOpen,
            }}
        >
            <CustomDialog
                open={open}
                loading={loading}
                handleClose={handleClose}
                title={formatMessage({
                    id: "wallet.public.key.modal.title",
                })}
                text={formatMessage({
                    id: "wallet.public.key.modal.subtitle",
                })}
                submitText={formatMessage({
                    id: "wallet.public.key.modal.submit",
                })}
                cancelText={formatMessage({
                    id: "common.later",
                })}
                handleSubmit={save}
            ></CustomDialog>
            {children}
        </EncryptionKeyContext.Provider>
    );
};

export default EncryptionKeyProvider;

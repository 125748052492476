import { makeStyles, Theme, createStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        closeBtn: { borderRadius: 3 },
        closeIcon: { margin: "-2px 3px 0 0" },
        font14: { fontSize: 14 },
        drawer: { zIndex: 2000 },
        pointer: { cursor: "pointer" },
        copyIcon: { marginTop: "-5px", marginLeft: "7px", cursor: "pointer" },
        unlockIcon: {
            marginRight: "5px",
            marginTop: "-4px",
        },
        deleteBtn: {
            background: COLORS.red,
            color: COLORS.white,
            borderRadius: 25,
            fontWeight: 500,
            fontSize: 14,
            width: "100%",
            marginTop: "1em",
            "&:hover": {
                background: "transparent !important",
                color: `${COLORS.red} !important`,
                border: `1px solid ${COLORS.red} !important`,
                transform: "scale(1.01)",
            },
        },
        downloadBtn: {
            background: `${COLORS.greenDark} !important`,
            color: `${COLORS.white} !important`,
            borderRadius: 25,
            fontWeight: 500,
            fontSize: 14,
            width: "100%",
            marginTop: "1em",
            "&:hover": {
                transform: "scale(1.01)",
            },
        },
        downloadOnly: {
            background: "transparent",
            color: `${COLORS.blue} !important`,
            borderRadius: 25,
            fontWeight: 500,
            fontSize: 14,
            width: "100%",
            marginTop: "1em",
            "&:hover": {
                transform: "scale(1.01)",
            },
        },
        selectItem: { marginTop: "-1.5em" },
        fileName: {
            color: COLORS.blue,
            cursor: "pointer",
            fontWeight: 600,
        },
        actionBtn: {
            background: `${COLORS.greenDark} !important`,
            color: `${COLORS.white} !important`,
            borderRadius: 5,
            "&:hover": { transform: "scale(1.02)" },
        },

        root: { padding: "0 0.5em" },
        alertWarning: { width: "540px", marginBottom: "8px" },
        alertError: { width: "100%", marginBottom: "8px", marginTop: "-0.5em" },
        ipfsCont: { marginTop: "2em" },
        optional: { marginTop: "-1em", marginBottom: "2em" },
    });
});

export default useStyles;

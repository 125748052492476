import { FC, useContext } from "react";
import { Typography } from "@material-ui/core";
import { TranslationContext } from "../../providers/TranslationProvider/TranslationProvider";
import { AiOutlineDown } from "react-icons/ai";
import { Button, Menu, Dropdown, Space } from "antd";
import { useIntl } from "react-intl";
import clsx from "clsx";
import CountryFlag from "react-country-flag";
import LANGUAGES from "../../constants/language";
import useStyles from "./useStyles";

export const LANGUAGES_LIST = [
    { code: LANGUAGES.EN, label: "EN" },
    { code: LANGUAGES.FR, label: "FR" },
];

interface Props {
    isOnAppBar?: boolean;
    isInFooter?: boolean;
}

const LanguageOptions: FC<Props> = ({ isOnAppBar, isInFooter }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { language, changeLanguage } = useContext(TranslationContext);

    if (isInFooter)
        return (
            <footer className={classes.language}>
                <Space className="d-none">
                    <Typography
                        variant="caption"
                        color={
                            language === LANGUAGES.FR
                                ? "textSecondary"
                                : "primary"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => changeLanguage(LANGUAGES.EN)}
                    >
                        {formatMessage({ id: "common.english" })}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        |
                    </Typography>
                    <Typography
                        variant="caption"
                        style={{ cursor: "pointer" }}
                        onClick={() => changeLanguage(LANGUAGES.FR)}
                        color={
                            language !== LANGUAGES.FR
                                ? "textSecondary"
                                : "primary"
                        }
                    >
                        {formatMessage({ id: "common.french" })}
                    </Typography>
                </Space>
            </footer>
        );

    return (
        <Dropdown
            overlay={() => (
                <Menu>
                    {LANGUAGES_LIST.map((item, idx) => {
                        return (
                            <Menu.Item
                                key={idx}
                                onClick={() => changeLanguage(item.code)}
                            >
                                <CountryFlag
                                    countryCode={
                                        item.code === LANGUAGES.EN ? "US" : "FR"
                                    }
                                    svg
                                    className="mr-2"
                                />
                                {item.label}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            )}
            placement="bottom"
            arrow
            // className="mr-3"
        >
            <Button
                type="text"
                shape="circle"
                size="large"
                className={clsx(
                    classes.iconBtn,
                    isOnAppBar ? classes.blue : "",
                    "d-none"
                )}
            >
                <CountryFlag
                    countryCode={language === LANGUAGES.EN ? "US" : "FR"}
                    svg
                    className={!isOnAppBar ? classes.flag : classes.flagBar}
                />
                {!isOnAppBar && (
                    <AiOutlineDown
                        fontSize="small"
                        className={classes.outlineDown}
                    />
                )}
            </Button>
        </Dropdown>
    );
};

export default LanguageOptions;

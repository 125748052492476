import { FC } from "react";
import { Helmet } from "react-helmet-async";
import config from "../../config/config";

interface Props {
    title?: string;
    description?: string;
}
const SEO: FC<Props> = ({ title }) => {
    const { appName, appDescription } = config || {};
    return (
        <Helmet>
            <title>{`${title ? `${title} -` : ""}  ${appName}`}</title>
            <meta name="description" content={appDescription} />
            <meta
                name="keywords"
                content="wylog, tracerdoc, tracerkey, document storage, document, storage, web 3.0, transfer platform, decentralized storage platform"
            />
            <meta property="og:site_name" content={appName} />
            <meta property="og:title" content={appName} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window?.location?.origin || ""} />
            <meta property="og:description" content={appDescription} />
            <meta property="og:image:width" content="1280" />
            <meta property="og:image:height" content="640" />
        </Helmet>
    );
};

export default SEO;

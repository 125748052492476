import { FC } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Grid, Typography, Paper } from "@material-ui/core";
import { Space, Progress } from "antd";
import { GetMyPlan_myPlan } from "../../graphql/user/types/GetMyPlan";
import { GetCurrentState_currentState } from "../../graphql/license/types/GetCurrentState";
import moment from "moment";
import useStyles from "./styles";

interface Props {
    type: string;
    items: string[];
    myPlan: GetMyPlan_myPlan | null | undefined;
    currentState: GetCurrentState_currentState | undefined;
}

const Storage: FC<Props> = ({ type, items, myPlan, currentState }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const { license } = currentState || {};

    if (type === "private" && !myPlan) {
        return null;
    }

    return (
        <Grid container spacing={2} className="mt-2">
            {items.map((item, idx) => {
                const label = formatMessage({
                    id: `dashboard.${idx === 0 ? "pub" : "priv"}.file.count`,
                });

                const consumed =
                    item === "pubFileCount"
                        ? currentState?.pubFileCount
                        : item === "prvFileCount"
                        ? currentState?.prvFileCount
                        : 0;
                const storage =
                    item === "pubFileCount"
                        ? license?.pubFileCountMonthly
                        : item === "prvFileCount"
                        ? license?.prvFileCountMonthly
                        : 0;
                return (
                    <Grid item xs={12} sm={myPlan ? 6 : 8} key={idx}>
                        <Paper
                            variant="outlined"
                            className={
                                idx === 0
                                    ? classes.publicContainer
                                    : classes.privateContainer
                            }
                        >
                            <Space direction="vertical" className="w-100">
                                <Typography
                                    variant="subtitle1"
                                    className={classes.progressLabel}
                                >
                                    {label}
                                </Typography>
                                <div className="w-100">
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id={`dashboard.file.count.consumed`}
                                            values={{
                                                consumed: <b>{consumed}</b>,
                                                storage: <b>{storage}</b>,
                                            }}
                                        />
                                    </Typography>
                                    <Progress
                                        percent={
                                            Math.round(
                                                (Number(
                                                    ((consumed || 0) /
                                                        (storage || 0)) *
                                                        100
                                                ) +
                                                    Number.EPSILON) *
                                                    100
                                            ) / 100
                                        }
                                        strokeColor={
                                            idx === 0 ? "#316198" : "#F38F39"
                                        }
                                        strokeWidth={15}
                                        status="active"
                                        format={(percent) =>
                                            Number?.isInteger(percent)
                                                ? `${percent}%`
                                                : `${percent?.toFixed(2)}%`
                                        }
                                    />
                                    <Typography variant="caption">
                                        until{" "}
                                        {currentState?.currentStartPeriod &&
                                        myPlan?.interval
                                            ? moment(
                                                  new Date(
                                                      currentState?.currentStartPeriod
                                                  )
                                              )
                                                  .add(
                                                      1,
                                                      myPlan?.interval ===
                                                          "month"
                                                          ? "month"
                                                          : "year"
                                                  )
                                                  .format("DD MMM YYYY")
                                            : ""}
                                    </Typography>
                                </div>
                            </Space>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Storage;

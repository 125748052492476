import { makeStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles(() => ({
    btnLoader: { marginTop: "-0.8em" },
    subCategBtn: { borderRadius: 5 },
    deleteBtn: {
        background: `${COLORS.red} !important`,
        color: `${COLORS.white} !important`,
        borderRadius: 5,
    },
    bulkDelete: {},
}));

export default useStyles;

const common = {
    "common.app.name": "TracerDoc",
    "common.email": "Email",
    "common.email.invalid": "Invalid email format",
    "common.english": "English (US)",
    "common.french": "French",
    "common.confirm": "Confirm",
    "common.cancel": "Cancel",
    "common.resend": "Resend",
    "common.your.email": "your email",
    "common.all": "All",
    "common.pending": "Pending",
    "common.accepted": "Accepted",
    "common.rejected": "Rejected",
    "common.canceled": "Canceled",
    "common.invite": "Invite",
    "common.invite.via.email": "Invite via email",
    "common.requireField": "Field required",
    "common.no.data": "No data",
    "common.no.user": "No user found",
    "common.no.partner": "No partner found",
    "common.sender": "Sender",
    "common.recipient": "Recipient",
    "common.status": "Status",
    "common.date": "Date Created",
    "common.my.docs": "My documents",
    "common.partner.docs": "Partner documents",
    "common.actions": "Actions",
    "common.user.email": "User email",
    "common.you": "You",
    "common.invited.via.email": "Invited via email",
    "common.accept": "Accept",
    "common.reject": "Reject",
    "common.partner": "Partner",
    "common.partner.address": "Partner address",
    "common.partner.email": "Partner email",
    "common.partnership.date": "Partnership date",
    "common.document": "Document :",
    "common.dragtext.message": "Click or drag file to this area to upload",
    "common.loading": "Loading...",
    "common.encrypting": "Encrypting...",
    "common.uploading": "Uploading...",
    "common.downloading": "Downloading...",
    "common.saving": "Saving...",
    "common.sent.by.me": "Sent / Outbox",
    "common.sent.to.me": "Received / Inbox",
    "common.save": "Save",
    "common.save.changes": "Save Changes",
    "common.reset.default": "Reset to default",
    "common.default": "Default : ",
    "common.encryption": "Encryption",
    "common.decryption": "Decryption",
    "common.rows.per.page": "Rows per page :",
    "common.loading.keycloak": "Loading account...",
    "common.connected.keycloak.account": "CONNECTED ACCOUNT :",
    "common.keycloak.logout": "Logout account",
    "common.partner.status.activated": "Public Key Activated",
    "common.partner.status.activated.desc":
        "Your partners can transfer documents to you",
    "common.partner.status.locked": "Public Key Locked",
    "common.partner.status.locked.desc":
        "Activate your wallet public key to transfer documents or to allow your partners to transfer documents to you",
    "common.partner.status.activated.tooltip":
        "You can transfer documents to this partner",
    "common.partner.status.locked.tooltip":
        "You can not transfer documents to this partner",
    "common.later": "Later",
    "common.document.row": "Document",
    "common.create": "Create",
    "common.update": "Update",
    "common.name": "Name",
    "common.delete": "Delete",
    "common.category": "Categories",
    "common.subcategory": "Subcategories",
    "common.remove.filters": "Remove filters",
    "common.download": "Download",
    "common.recover": "Recover",
    "common.decrypting": "Decrypting...",
    "common.admin": "Admin",
    "common.normal.user": "Normal user",
    "common.copy": "Copy",
    "common.copied": "Copied",
    "common.search": "Search...",
    "common.done": "Done",
    "common.edit": "Edit",
    "common.success": "Success",
    "common.filter.all": "All time",
    "common.filter.today": "Today",
    "common.filter.week": "This week",
    "common.filter.month": "This month",
    "common.filter.custom": "Custom date",
    "common.delete.selected": "Delete selected items",
    "common.submit": "Submit",
    "common.send.to": "Send document to",
};

export default common;

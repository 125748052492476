import { makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        background: "transparent",
        color: COLORS.primaryDark,
        border: `1px solid ${COLORS.primaryDark}`,
        height: 100,
        width: 100,
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "2em",
    },
    icon: {
        fontSize: 40,
    },
    downloadBtn: {
        background: `${COLORS.blue} !important`,
        color: `${COLORS.white} !important`,
        paddingLeft: "4em",
        paddingRight: "4em",
        borderRadius: 5,
        marginTop: "2em",
        "&:hover": {
            transform: "scale(1.04)",
        },
    },
    loader: {
        backgroundColor: COLORS.primaryTransparent,
    },
    loaderBar: {
        backgroundColor: COLORS.primaryDark,
    },

    recoverBtn: {
        height: 110,
        width: 110,
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        background: `linear-gradient(to bottom, ${COLORS.primaryLight}, ${COLORS.primaryDark})`,
        color: COLORS.white,
        border: `1px solid ${COLORS.primaryDark}`,
        "&:hover": {
            transform: "scale(1.03)",
        },
    },
}));

export default useStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Space, Button as AntButton } from "antd";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        space: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #E1E1E1",
            marginTop: "-0.5em",
        },
        textPrimary: {
            fontSize: 14,
            paddingLeft: "2.5em",
            paddingRight: "2.5em",
        },
    })
);

interface IProps {
    tabs: string[];
    selectedTab?: string;
    onClickTab?(tab: string): void;
    style?: React.CSSProperties;
}

const CustomTab: FC<IProps> = (props) => {
    const { tabs, selectedTab, onClickTab, style } = props;

    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Space className={classes.space} style={style}>
            <div>
                {tabs.map((tab) => {
                    const activeTab = selectedTab === tab;
                    return (
                        <AntButton
                            size="large"
                            key={tab}
                            onClick={() => {
                                if (onClickTab) {
                                    onClickTab(tab);
                                }
                            }}
                            type="text"
                            className={classes.textPrimary}
                            style={
                                activeTab
                                    ? {
                                          borderRadius: "1px",
                                          borderBottom: "2.5px solid #2A5BDA",
                                          fontWeight: 500,
                                      }
                                    : {}
                            }
                        >
                            {formatMessage({ id: tab })}
                        </AntButton>
                    );
                })}
            </div>
        </Space>
    );
};

export default CustomTab;

import { FC, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { MdAdd } from "react-icons/md";
import { Grid } from "@material-ui/core";
import { GET_MY_DOCUMENT_TRANSFERS } from "../../graphql/document/query";
import { useQuery } from "@apollo/client";
import { MyDocumentTransfers } from "../../graphql/document/types/MyDocumentTransfers";
import { MyDocumentTransfersVariables } from "../../graphql/document/types/MyDocumentTransfers";
import { DocumentTransferFilter } from "../../types/graphql-global-types";
import { TABLE_PAGINATION_PAGE_SIZE } from "../../constants/pageSize";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import CustomTab from "../../components/CustomTab/CustomTab";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import DocumentEncryptionDecryption from "./DocumentEncryptionDecryption/DocumentEncryptionDecryption";
import DocumentList from "./DocumentList/DocumentList";
import CustomButton from "../../components/CustomButton/CustomButton";
import DocumentTransferModal from "./DocumentTransfer/DocumentTransferModal";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";
import Fade from "react-reveal/Fade";

export enum DOCUMENT_TAB_TITLES {
    LIST = "document.list.title",
    TRANSFER = "document.transfer.title",
    ENCRYPTION_DECRYPTION = "document.decryption.title",
}

export enum DOCUMENT_TAB_ROUTES {
    LIST = "list",
    TRANSFER = "transfer",
    ENCRYPTION_DECRYPTION = "encryption-decryption",
}

const mapDocumentTabRoutesToTitle = (
    documentTabRoute: string | undefined | null
) => {
    switch (documentTabRoute) {
        case DOCUMENT_TAB_ROUTES.LIST:
            return DOCUMENT_TAB_TITLES.LIST;

        case DOCUMENT_TAB_ROUTES.TRANSFER:
            return DOCUMENT_TAB_TITLES.TRANSFER;

        case DOCUMENT_TAB_ROUTES.ENCRYPTION_DECRYPTION:
            return DOCUMENT_TAB_TITLES.ENCRYPTION_DECRYPTION;

        default:
            return DOCUMENT_TAB_TITLES.LIST;
    }
};

interface IParams {
    tab?: string;
}

const Document: FC<RouteComponentProps> = (props) => {
    const breakpoint = useBreakpoint();
    const { match, history } = props;
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const params: IParams = match.params;
    const [filter, setFilter] = useState("documents.all");
    const tab = mapDocumentTabRoutesToTitle(params.tab);
    const [selectedTab, setSelectedTab] = useState<DOCUMENT_TAB_TITLES>(
        tab || DOCUMENT_TAB_TITLES.LIST
    );
    const [openDocTransfer, setOpenDocTransfer] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(TABLE_PAGINATION_PAGE_SIZE);

    const skip = pageSize * (page - 1);

    const {
        data,
        loading: queryLoading,
        refetch,
    } = useQuery<MyDocumentTransfers, MyDocumentTransfersVariables>(
        GET_MY_DOCUMENT_TRANSFERS,
        {
            fetchPolicy: "cache-first",
            variables: {
                input: {
                    filter:
                        filter === "common.sent.to.me"
                            ? DocumentTransferFilter.SENT_TO_ME
                            : filter === "common.sent.by.me"
                            ? DocumentTransferFilter.SENT_BY_ME
                            : filter === "documents.personal"
                            ? DocumentTransferFilter.PERSONAL
                            : undefined,
                    take: pageSize,
                    skip,
                },
            },
            onError: (error) => {
                displaySnackBar({
                    message: error.message
                        ? error.message
                        : formatMessage({ id: "error.unknown" }),
                    type: "error",
                });
            },
        }
    );

    const onClickTab = (tab: string) => {
        setSelectedTab(tab as DOCUMENT_TAB_TITLES);
        if (tab === DOCUMENT_TAB_TITLES.LIST) {
            history.push(`${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}`);
        }

        if (tab === DOCUMENT_TAB_TITLES.TRANSFER) {
            history.push(`${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.TRANSFER}`);
        }

        if (tab === DOCUMENT_TAB_TITLES.ENCRYPTION_DECRYPTION) {
            history.push(
                `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.ENCRYPTION_DECRYPTION}`
            );
        }
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    return (
        <PageContainer title="Documents">
            <Grid container justifyContent="space-between">
                <Grid item xs={10} sm={5}>
                    <TitleWithBreadcrumb
                        title="menu.documents"
                        routes={[
                            { title: "menu.documents" },
                            { title: selectedTab },
                        ]}
                        hideDivider
                    />
                </Grid>
                {selectedTab === DOCUMENT_TAB_TITLES.LIST && (
                    <Grid
                        item
                        xs={2}
                        sm={7}
                        className={`mt-2 d-flex justify-content-${
                            breakpoint === "xs" ? "start" : "end"
                        } align-items-${
                            breakpoint === "xs" ? "center" : "start"
                        }`}
                    >
                        <CustomButton
                            onClick={() => {
                                setOpenDocTransfer(true);
                            }}
                            text={formatMessage({
                                id: "document.transfer.new",
                            })}
                            hideTextOnMobile
                            style={{
                                marginTop: 0,
                                borderRadius: 6,
                                color: "#fff",
                                background: "#01579B",
                                paddingLeft: "2em",
                                paddingRight: "2em",
                            }}
                            icon={<MdAdd fontSize={18} />}
                        />
                    </Grid>
                )}
            </Grid>

            <hr />
            <CustomTab
                tabs={[
                    DOCUMENT_TAB_TITLES.LIST,
                    DOCUMENT_TAB_TITLES.ENCRYPTION_DECRYPTION,
                ]}
                selectedTab={selectedTab}
                onClickTab={onClickTab}
            />

            {selectedTab === DOCUMENT_TAB_TITLES.LIST && (
                <Fade bottom distance="20%">
                    <DocumentList
                        data={data?.myDocumentTransfers}
                        filter={filter}
                        setFilter={setFilter}
                        queryLoading={queryLoading}
                        refetch={refetch}
                        onPageChange={onPageChange}
                        page={page}
                        pageSize={pageSize}
                    />
                </Fade>
            )}
            {selectedTab === DOCUMENT_TAB_TITLES.ENCRYPTION_DECRYPTION && (
                <DocumentEncryptionDecryption />
            )}
            <DocumentTransferModal
                open={openDocTransfer}
                setOpen={setOpenDocTransfer}
                refetch={refetch}
            />
        </PageContainer>
    );
};

export default withRouter(Document);

import React, { useState, FC, createContext } from "react";

export type SnackBarType = "error" | "warning" | "info" | "success";

export type DisplaySnackBarInterface = ({
    message,
    type,
}: {
    message: string;
    type: SnackBarType;
}) => void;

export type SnackBarInterface = {
    isOpen: boolean;
    message: string;
    type: SnackBarType;
    displaySnackBar: DisplaySnackBarInterface;
    closeSnackBar: () => void;
};

export const SnackBarContext = createContext<SnackBarInterface>({
    isOpen: false,
    message: "",
    type: "error",
    displaySnackBar: () => {},
    closeSnackBar: () => {},
});

const SnackBarProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState<SnackBarType>("info");

    return (
        <SnackBarContext.Provider
            value={{
                isOpen,
                message,
                type,
                displaySnackBar: (args) => {
                    setIsOpen(true);
                    setMessage(args.message);
                    setType(args.type);
                },
                closeSnackBar: () => {
                    setIsOpen(false);
                    setMessage("");
                    setType(type);
                },
            }}
        >
            {children}
        </SnackBarContext.Provider>
    );
};

export default SnackBarProvider;

import { gql } from "@apollo/client";

export const DOCUMENT_TRANSFER_FRAGMENT = gql`
    fragment DocumentTransferFields on DocumentTransfer {
        id
        status
        securityLevel
        sentByMe
        sentToMe
        createdAt
        updatedAt
        fileName
        fileSize
        ipfsType
        expiresAt
    }
`;

export const DOCUMENT_FRAGMENT = gql`
    fragment DocumentFields on Document {
        cid
        encryptionVersion
        verified
        documentOwner
        sentByMe
        sentToMe
        isPassphrase
        ipfsType
    }
`;

import config from "../config/config";

export interface PinToIpfsArgs {
    file: File;
    fileName?: string;
    token?: string;
}

export interface PinToIpfsDefaultResult {
    cid: string;
    name: string;
    size: string;
}

export enum IPFS_SERVICES_IDS {
    DEFAULT = "default",
    PINATA = "pinata",
    NFT_STORAGE = "nft.storage",
    FILEBASE = "filebase",
}

export interface IIpfsService {
    id: IPFS_SERVICES_IDS;
    name: string;
    gateway: string;
    pinningService: string;
    pinToIPFS(args: PinToIpfsArgs): Promise<string>;
}

export const DEFAULT_IPFS: IIpfsService = {
    id: IPFS_SERVICES_IDS.DEFAULT,
    name: "ipfs.default.node",
    gateway: config.ipfsGateway,
    pinningService: config.ipfsPinningService,
    pinToIPFS: async (args: PinToIpfsArgs) => {
        const { file, fileName, token } = args;
        const formData = new FormData();
        formData.append("file", file, fileName);

        const result = (await fetch(config.ipfsPinningService, {
            method: "POST",
            body: formData,
            headers: token
                ? {
                      Authorization: `Bearer ${token}`,
                  }
                : undefined,
        }).then((r) => r.json())) as PinToIpfsDefaultResult;

        return result.cid;
    },
};

export interface PinToIpfsPinataResult {
    IpfsHash: string;
}

export const PINATA: IIpfsService = {
    id: IPFS_SERVICES_IDS.PINATA,
    name: "ipfs.pinata",
    gateway: "https://gateway.pinata.cloud",
    pinningService: "https://api.pinata.cloud/pinning/pinFileToIPFS",
    pinToIPFS: async (args: PinToIpfsArgs) => {
        const { file, fileName, token } = args;
        const formData = new FormData();
        formData.append("file", file, fileName);

        const result = (await fetch(
            "https://api.pinata.cloud/pinning/pinFileToIPFS",
            {
                method: "POST",
                body: formData,
                headers: token
                    ? {
                          Authorization: `Bearer ${token}`,
                      }
                    : undefined,
            }
        ).then((r) => r.json())) as PinToIpfsPinataResult;

        return result.IpfsHash;
    },
};

export interface PinToIpfsNFTStorageResult {
    cid: string;
}

export const NFT_STORAGE: IIpfsService = {
    id: IPFS_SERVICES_IDS.NFT_STORAGE,
    name: "ipfs.nft.storage",
    gateway: "https://nftstorage.link",
    pinningService: "https://api.nft.storage/pins",
    pinToIPFS: async (args: PinToIpfsArgs) => {
        const { file, fileName, token } = args;
        const formData = new FormData();
        formData.append("file", file, fileName);

        const result = (await fetch("https://api.nft.storage/pins", {
            method: "POST",
            body: formData,
            headers: token
                ? {
                      Authorization: `Bearer ${token}`,
                  }
                : undefined,
        }).then((r) => r.json())) as PinToIpfsNFTStorageResult;

        return result.cid;
    },
};

export interface PinToIpfsFilebaseResult {
    cid: string;
}

export const FILEBASE: IIpfsService = {
    id: IPFS_SERVICES_IDS.FILEBASE,
    name: "ipfs.filebase",
    gateway: "https://ipfs.filebase.io",
    pinningService: "https://api.filebase.io/v1/ipfs/pins",
    pinToIPFS: async (args: PinToIpfsArgs) => {
        const { file, fileName, token } = args;
        const formData = new FormData();
        formData.append("file", file, fileName);

        // pin via default gateway
        const result = (await fetch(config.ipfsPinningService, {
            method: "POST",
            body: formData,
        }).then((r) => r.json())) as PinToIpfsDefaultResult;

        const filebaseBody = {
            cid: result.cid,
            name: fileName,
        };

        // re-pin via filebase
        await fetch("https://api.filebase.io/v1/ipfs/pins", {
            method: "POST",
            body: JSON.stringify(filebaseBody),
            headers: token
                ? {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                  }
                : undefined,
        });

        return result.cid;
    },
};

export const IPFS_SERVICES: IIpfsService[] = [DEFAULT_IPFS];

export interface PinToIpfsResult {
    Name: string;
    Hash: string;
    Size: string;
}

export type RetrieveFromIPFSParams = {
    cid: string;
    fileName: string;
    token?: string | null;
};

export async function retrieveFromIPFS({
    cid,
    fileName,
    token,
}: RetrieveFromIPFSParams) {
    const blob = await fetch(`${config.backendUrl}/ipfs/${cid}`, {
        headers: token
            ? {
                  Authorization: token && `Bearer ${token}`,
              }
            : {},
    }).then((r) => r.blob());
    return new File([blob], fileName);
}

export const createIpfsFile = (
    contentText: string,
    fileName: string,
    type: string
) => {
    const blob = new Blob([contentText], { type });
    const file = new File([blob], fileName, { type });

    return file;
};

import { createStyles, makeStyles } from "@material-ui/core";
import { Grid, GridSize, Typography } from "@material-ui/core";
import { Upload, List, Tooltip } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { FC } from "react";
import { useIntl } from "react-intl";
import { RiUploadCloud2Fill, RiDeleteBin7Line } from "react-icons/ri";
import { MdAttachFile } from "react-icons/md";
import { getFileSize } from "../../utils/document";
import { MAX_FILE_SIZE } from "../../constants/fileSize";

const { Dragger } = Upload;

const useStyles = makeStyles(() =>
    createStyles({
        gridContainer: {
            marginTop: 20,
            marginBottom: 20,
        },
        title: {
            width: "20%",
        },
        dragger: {
            padding: "0.5em 2em",
            marginTop: "2px",
        },
        listItem: {
            padding: "2px 0 !important",
            fontSize: "13px",
            display: "flex",
            justifyContent: "space-between",
            "&:hover": { background: "#f5f5fa" },
        },
        deleteIcon: { cursor: "pointer" },
    })
);

type FileTypes =
    | "audio/*"
    | "video/*"
    | "image/*"
    | "text/*"
    | ".zip"
    | ".tdFile"
    | ".tdKey";

interface Props {
    title?: string;
    disabled?: boolean;
    multiple?: boolean;
    onChange?: ((info: UploadChangeParam<UploadFile<any>>) => void) | undefined;
    dragText?: string;
    accepts?: FileTypes[];
    hideTitle?: boolean;
    noMargin?: boolean;
    gridSize?: GridSize;
    showIcon?: boolean;
    background?: string;
    showUploadList?: boolean;
    file?: File | undefined;
    removeFile?(): void;
}

const FileUploadDragger: FC<Props> = ({
    title,
    disabled,
    multiple,
    onChange,
    dragText,
    accepts,
    hideTitle,
    noMargin,
    gridSize,
    showIcon,
    background,
    showUploadList,
    file,
    removeFile,
}) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <Grid
            container
            alignItems="flex-start"
            className={classes.gridContainer}
        >
            {!hideTitle && (
                <div className={classes.title}>
                    {title || formatMessage({ id: "common.document" })}
                </div>
            )}
            <Grid
                item
                xs={gridSize ? gridSize : 10}
                style={!noMargin ? { marginLeft: 40 } : {}}
                className="mt-1"
            >
                <Dragger
                    customRequest={dummyRequest}
                    accept={
                        accepts && accepts.length
                            ? accepts.join(",")
                            : undefined
                    }
                    multiple={multiple || false}
                    onChange={onChange}
                    showUploadList={showUploadList}
                    disabled={disabled}
                    maxCount={!multiple ? 1 : undefined}
                    className={classes.dragger}
                    style={{ background: background || "#fff" }}
                >
                    {showIcon && (
                        <>
                            <RiUploadCloud2Fill fontSize={50} color="#FF9436" />{" "}
                            <br />
                        </>
                    )}
                    <Typography variant="caption">
                        {formatMessage({
                            id: dragText || "common.dragtext.message",
                        })}
                        <br />
                        (Max: {MAX_FILE_SIZE} MB)
                    </Typography>
                </Dragger>
                {!showUploadList && file && (
                    <div>
                        <List
                            size="small"
                            bordered={false}
                            split={false}
                            dataSource={
                                file
                                    ? Array.isArray(file)
                                        ? file
                                        : [file]
                                    : undefined
                            }
                            renderItem={(item) => (
                                <List.Item className={classes.listItem}>
                                    <span style={{ fontWeight: 500 }}>
                                        <MdAttachFile />
                                        {item?.name || ""} (
                                        {getFileSize(item?.size)})
                                    </span>
                                    <Tooltip
                                        title={formatMessage({
                                            id: "document.remove",
                                        })}
                                        zIndex={1000000}
                                        placement="bottom"
                                    >
                                        <RiDeleteBin7Line
                                            color="#363636"
                                            className={classes.deleteIcon}
                                            onClick={removeFile}
                                        />
                                    </Tooltip>
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default FileUploadDragger;

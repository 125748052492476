import { FC } from "react";
import { FormLabel } from "@material-ui/core";
import { useIntl } from "react-intl";

interface IProps {
    required?: boolean;
    label: string;
}

const CustomFormLabel: FC<IProps> = ({ required, label }) => {
    const { formatMessage } = useIntl();

    return (
        <FormLabel
            required={required || false}
            className="formLabel"
            classes={{ asterisk: "asterisk" }}
        >
            {formatMessage({ id: label })}
        </FormLabel>
    );
};

export default CustomFormLabel;

import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import PageContainer from "../../../components/PageContainer/PageContainer";
import Recover from "../../BackupRecover/Recover/Recover";
import Fade from "react-reveal/Fade";
// import DocumentDecryption from "./Decryption/DocumentDecryption";

export enum ENCRYPTION_DECRYPTION_TAB_TITLES {
    ENCRYPTION = "common.encryption",
    DECRYPTION = "common.decryption",
}

const DocumentEncryptionDecryption: FC<RouteComponentProps> = () => {
    const { formatMessage } = useIntl();

    return (
        <PageContainer>
            <Fade bottom distance="25%">
                <div className="d-flex flex-column align-items-center mt-2">
                    <Typography variant="h5" className="font-weight-bold mt-3">
                        {formatMessage({
                            id: "document.decryption.section.title",
                        })}
                    </Typography>
                    <Typography variant="subtitle2" className="mt-1">
                        {formatMessage({ id: "document.decryption.subtitle" })}
                    </Typography>
                </div>
            </Fade>

            {/* <DocumentDecryption /> */}
            <Fade bottom distance="10%" delay={150}>
                <Recover />
            </Fade>
        </PageContainer>
    );
};

export default withRouter(DocumentEncryptionDecryption);

import { forwardRef, ReactElement } from "react";
import { TransitionProps } from "@material-ui/core/transitions";
import { Slide } from "@material-ui/core";

const SlideTransition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default SlideTransition;

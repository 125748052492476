const error = {
    "error.unknown": "Une erreur est survenue",
    "error.not.installed.tracerkey": "TracerKey non installé",
    "error.no.tracerkey.account.found": "Aucun compte TracerKey trouvé",
    "error.mismatch.account":
        "Comptes incompatibles : compte TracerDoc et compte TracerKey",
    "error.auth":
        "Erreur lors de l'authentification de l'utilisateur. Déconnexion",
    "error.page.not.found.title": "PAGE NON TROUVÉE",
    "error.page.not.found.subtitle":
        "La page que vous recherchez est introuvable.",
    "error.page.not.found.button": "Retourner sur le site",
    "error.document.retrieve.decrypt":
        "Une erreur s'est produite lors de la récupération et du déchiffrement du document.",
    "error.document.encryption":
        "Une erreur s'est produite lors du cryptage du document.",
    "error.document.decryption":
        "Une erreur s'est produite lors du décriptage du document. Assurez-vous que le document est crypté avec votre clé publique.",
    "error.only.partner.can.decrypt":
        "Seul votre partenaire peut décrypter ce document.",
    "error.partner.public.key.locked":
        "La clé publique de ce partenaire est verrouillée. Vous ne pouvez pas transférer de documents à ce partenaire.",
    "error.no.tdfile.found": "No tracedoc file found",
    "error.no.tdkey.found": "No tracerdoc key found",
};

export default error;

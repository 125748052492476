import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useContext, useState, FC } from "react";
import { useIntl } from "react-intl";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { tracerkeyEthDecrypt } from "../../../services/tracerkey";
import { UserContext } from "../../../providers/UserProvider/UserProvider";
import { Radio, Space } from "antd";
import { sweetalert } from "../../../utils/sweetalert";
import type { RadioChangeEvent } from "antd";
import * as openpgp from "openpgp";
import COLORS from "../../../constants/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import FileUploadDragger from "../../../components/FileUploadDragger/FileUploadDragger";
import JSZip from "jszip";
import Fade from "react-reveal/Fade";
import useStyles from "../styles";

interface IProps {
    title?: boolean;
    subtitle?: boolean;
    type?: string;
}
const Recover: FC<IProps> = ({ title, subtitle }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { me } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [uploadType, setUploadType] = useState<string>("zip");
    const [key, setKey] = useState<File | undefined>(undefined);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [zipFile, setZipFile] = useState<File | undefined>(undefined);
    const { displaySnackBar } = useContext(SnackBarContext);

    const displayError = (id: string) => {
        displaySnackBar({
            message: formatMessage({ id }),
            type: "error",
        });
    };

    const downloadFile = async (keyContent: string, fileContent: string) => {
        try {
            const myDecryptedPassphrase = await tracerkeyEthDecrypt(keyContent);
            if (!myDecryptedPassphrase) {
                displayError("error.document.decryption");
                return;
            }

            const myDecryptedPassphraseObject = JSON.parse(
                myDecryptedPassphrase
            );

            const passphrase: string = myDecryptedPassphraseObject?.armored;

            const encryptedMessage = await openpgp.readMessage({
                armoredMessage: fileContent, // parse encrypted bytes
            });

            const { data, filename } = await openpgp.decrypt({
                message: encryptedMessage,
                passwords: [passphrase],
                format: "binary",
            });

            const decryptedContent = data as any;

            const arrayBytes = Object.keys(decryptedContent).map(
                (val: any) => decryptedContent[val]
            );

            const docContentBytes = new Uint8Array(arrayBytes);

            const blob = new Blob([docContentBytes]);
            var fileURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", filename);
            tempLink.click();
            setLoading(false);
        } catch (error) {
            console.log("error : ", error);
            displayError("error.document.decrypt");
            setLoading(false);
        }
    };

    const handleRecover = async () => {
        setLoading(true);

        if (!file) {
            displayError("error.recover.missing.file");
            setLoading(false);
            return;
        }

        if (!key) {
            displayError("error.recover.missing.key");
            setLoading(false);
            return;
        }

        const keyContent = await key.text();
        const fileContent = await file.text();
        downloadFile(keyContent, fileContent);
    };

    const readBlobText = (blob: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const text = reader.result;
                resolve(text);
            };

            reader.onerror = () => {
                reject(new Error("Failed to read the Blob as text."));
            };
            reader.readAsText(blob);
        });
    };

    const readZipFile = async () => {
        if (zipFile) {
            const reader = new FileReader();

            reader.onload = (event: ProgressEvent<FileReader>) => {
                const zipFileData = event.target?.result;
                if (zipFileData instanceof ArrayBuffer) {
                    const jszip = new JSZip();
                    jszip.loadAsync(zipFileData).then(async (zip) => {
                        try {
                            const pgpFile = zip.filter((_, zipEntry) =>
                                zipEntry.name.includes("tdFile")
                            )?.[0];
                            const myTdKey = zip.filter(
                                (_, zipEntry) =>
                                    zipEntry.name.includes("tdKey") &&
                                    zipEntry.name.includes(
                                        me?.wallet?.address || ""
                                    )
                            )?.[0];

                            if (!pgpFile) {
                                setLoading(false);
                                sweetalert(
                                    "error",
                                    "Error",
                                    formatMessage({
                                        id: "error.no.tdfile.found",
                                    })
                                );
                                return;
                            }
                            if (!myTdKey) {
                                setLoading(false);
                                sweetalert(
                                    "error",
                                    "Error",
                                    formatMessage({
                                        id: "error.no.tdkey.found",
                                    })
                                );
                                return;
                            }

                            const extractedFileDataPgpFile = pgpFile
                                ? await pgpFile.async("blob")
                                : null;
                            const extractedFileKey = myTdKey
                                ? await myTdKey.async("blob")
                                : null;
                            const fileContent = (await readBlobText(
                                extractedFileDataPgpFile
                            )) as string;
                            const keyContent = (await readBlobText(
                                extractedFileKey
                            )) as string;

                            downloadFile(keyContent, fileContent);
                        } catch (err) {
                            console.log(err);
                        }
                    });
                }
            };

            reader.readAsArrayBuffer(zipFile);
        }
    };

    const handleZipRecover = async () => {
        setLoading(true);

        if (!zipFile) {
            displayError("error.recover.missing.file");
            setLoading(false);
            return;
        }
        readZipFile();
    };

    const onChangeKey = (info: UploadChangeParam<UploadFile<any>>) => {
        const f = info.file.originFileObj;
        setKey(f);
    };

    const onChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const f = info.file.originFileObj;
        setFile(f);
    };

    const onChangeZipFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const f = info.file.originFileObj;
        setZipFile(f);
    };

    const handleUploadType = (e: RadioChangeEvent) => {
        setUploadType(e.target.value);
        setFile(undefined);
        setKey(undefined);
        setZipFile(undefined);
    };

    return (
        <div className="pt-4 d-flex flex-column align-items-center">
            {title && (
                <Fade bottom distance="25%">
                    <Typography variant="h5" className="font-weight-bold mt-3">
                        {formatMessage({ id: "backup.recover.title" })}
                    </Typography>
                </Fade>
            )}
            {subtitle && (
                <Fade bottom distance="25%">
                    <Typography variant="subtitle1" className="mt-1">
                        {formatMessage({ id: "backup.recover.subtitle" })}
                    </Typography>
                </Fade>
            )}

            <Fade bottom distance="20%" delay={150}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="d-flex justify-content-center flex-column align-items-center"
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "3em",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "-3em",
                                }}
                            >
                                <div>
                                    <Typography className="font-weight-bold">
                                        {formatMessage({
                                            id: "document.select.action",
                                        })}
                                    </Typography>
                                    <Radio.Group
                                        onChange={handleUploadType}
                                        value={uploadType}
                                        className="mt-2"
                                    >
                                        <Space direction="horizontal">
                                            <Radio value={"manual"}>
                                                {formatMessage({
                                                    id: "document.upload.files",
                                                })}
                                            </Radio>
                                            <Radio value={"zip"}>
                                                {formatMessage({
                                                    id: "document.upload.zip",
                                                })}
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </div>
                            {uploadType === "zip" ? (
                                <div className="mt-4">
                                    <Typography className="font-weight-bold">
                                        {formatMessage({
                                            id: "document.upload.zip",
                                        })}
                                    </Typography>
                                    <div style={{ marginTop: "-1em" }}>
                                        <FileUploadDragger
                                            onChange={onChangeZipFile}
                                            hideTitle
                                            dragText="backup.recover.zip.dragtext"
                                            multiple={false}
                                            noMargin
                                            gridSize={12}
                                            accepts={[".zip"]}
                                            showUploadList
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="mt-4">
                                        <Typography className="font-weight-bold">
                                            {formatMessage({
                                                id: "backup.recover.upload.tracerdoc.file",
                                            })}
                                        </Typography>
                                        <div style={{ marginTop: "-1em" }}>
                                            <FileUploadDragger
                                                onChange={onChangeFile}
                                                hideTitle
                                                dragText="backup.recover.file.dragtext"
                                                multiple={false}
                                                noMargin
                                                gridSize={12}
                                                accepts={[".tdFile"]}
                                                showUploadList
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Typography className="font-weight-bold">
                                            {formatMessage({
                                                id: "backup.recover.upload.tracerdoc.key",
                                            })}
                                        </Typography>
                                        <div style={{ marginTop: "-1em" }}>
                                            <FileUploadDragger
                                                onChange={onChangeKey}
                                                hideTitle
                                                dragText="backup.recover.key.dragtext"
                                                noMargin
                                                gridSize={12}
                                                accepts={[".tdKey"]}
                                                showUploadList
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <Box
                            className={classes.recoverBtn}
                            style={
                                loading
                                    ? {
                                          cursor: "default",
                                          transform: "scale(1.02)",
                                          border: `1px solid grey`,
                                          color: COLORS.white,
                                          background: "grey",
                                      }
                                    : undefined
                            }
                            onClick={
                                uploadType === "zip"
                                    ? handleZipRecover
                                    : handleRecover
                            }
                        >
                            <GetAppIcon className={classes.icon} />
                            {formatMessage({
                                id: loading
                                    ? "common.decrypting"
                                    : "common.recover",
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Fade>
        </div>
    );
};

export default Recover;

const COLORS = {
    appBackground: "#F5F5FA",
    error: "#EB5757",
    white: "#FFFFFF",
    white100: "#f5f5f5",
    primary: "#eb5d27",
    primaryTransparent: "rgba(8, 64, 122, 0.4)",
    greyTitle: "#505458",
    greySubTitle: "#919aa3",
    primaryDark: "#08407a",
    primaryLight: "#6ab5ff",
    backgroundDark: "#343A40",
    greenDark: "#03a62c",
    greenLight: "#02e33a",
    blueGradient: "linear-gradient(to left, #94CAFE ,#ffffff)",
    primaryGradient:
        "linear-gradient(to left, #3D85FD, #1E5B99, #1E5B99, #225E9C)",
    primaryGradientLight: "linear-gradient(to left, #3D85FD, #225E9C)",
    lightblue: "#E6EDF8", //E6EDF8 CCDCF8
    lightblue100: "#90CAF9", 
    blue: "#1E5B99",
    blue100: "#225E9C",
    blue200: "#3D85FD",
    blue300: "#006BE6",
    blue400: "#0D6B9B",
    blue500: "#90CAF9",
    blue600: "#E6F7FF",
    black: "#424242",
    black100: "#616161",
    red: "#D64D4B",
    orange: "#FFE0B2",
    orange100: "#F38F39",
};

export default COLORS;

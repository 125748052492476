import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
    mutation CreateCategory($name: String!) {
        createCategory(name: $name) {
            id
            name
        }
    }
`;

export const DELETE_CATEGORY = gql`
    mutation DeleteCategory($id: Int!) {
        deleteCategory(id: $id) {
            id
            name
        }
    }
`;

export const CREATE_SUB_CATEGORY = gql`
    mutation CreateSubCategory($input: CreateSubCategoryInput!) {
        createSubCategory(input: $input) {
            id
            name
        }
    }
`;

export const DELETE_SUB_CATEGORY = gql`
    mutation DeleteSubCategory($id: Int!) {
        deleteSubCategory(id: $id) {
            id
            name
        }
    }
`;

export const UPDATE_CATEGORY = gql`
    mutation UpdateCategory($input: UpdateCategoryInput!) {
        updateCategory(input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_SUB_CATEGORY = gql`
    mutation UpdateSubCategory($input: UpdateSubCategoryInput!) {
        updateSubCategory(input: $input) {
            id
            name
        }
    }
`;

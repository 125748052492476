import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT } from "../partnership/fragment";
import { USER_FRAGMENT } from "../user/fragment";
import { DOCUMENT_FRAGMENT, DOCUMENT_TRANSFER_FRAGMENT } from "./fragment";

export const GET_MY_DOCUMENT_TRANSFERS = gql`
    query MyDocumentTransfers($input: MyDocumentTransfersInput) {
        myDocumentTransfers(input: $input) {
            totalCount
            documents {
                ...DocumentTransferFields
                sender {
                    ...UserFields
                }
                recipient {
                    ...UserFields
                }
                partnership {
                    ...PartnershipFields
                }
                document {
                    ...DocumentFields
                }
                senderPassphrase {
                    ...DocumentFields
                }
                recipientPassphrase {
                    ...DocumentFields
                }
                categories {
                    id
                    name
                }
                subCategories {
                    id
                    name
                }
            }
        }
    }
    ${DOCUMENT_TRANSFER_FRAGMENT}
    ${USER_FRAGMENT}
    ${DOCUMENT_FRAGMENT}
    ${PARTNERSHIP_FRAGMENT}
`;

export const GET_MY_DOCUMENT_TRANSFER = gql`
    query MyDocumentTransfer($id: Int!) {
        myDocumentTransfer(id: $id) {
            ...DocumentTransferFields
            sender {
                ...UserFields
            }
            recipient {
                ...UserFields
            }
            partnership {
                ...PartnershipFields
            }
            document {
                ...DocumentFields
            }
            senderPassphrase {
                ...DocumentFields
            }
            recipientPassphrase {
                ...DocumentFields
            }
            categories {
                id
                name
            }
            subCategories {
                id
                name
            }
        }
    }
    ${DOCUMENT_TRANSFER_FRAGMENT}
    ${USER_FRAGMENT}
    ${DOCUMENT_FRAGMENT}
    ${PARTNERSHIP_FRAGMENT}
`;

import React, { FC, useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { CustomFC } from "../../constants/types";
import CustomAppBar from "../CustomAppBar/CustomAppBar";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES_WITH_LAYOUT } from "../../constants/routes";
import clsx from "clsx";
import DrawerWeb from "./DrawerWeb/DrawerWeb";
import { withWidth, WithWidthProps } from "@material-ui/core";
import DrawerMobile from "./DrawerMobile/DrawerMobile";

const useStyles = makeStyles((theme) =>
    createStyles({
        app: {
            display: "flex",
        },
        account: {
            flexGrow: 1,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1.5, 2),
            [theme.breakpoints.down("sm")]: {
                padding: 0,
                margin: 0,
            },
            margin: "0 auto",
        },
        overFlow: {
            overflowX: "auto",
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
    })
);

const Layout: FC<CustomFC & RouteComponentProps & WithWidthProps> = (props) => {
    const classes = useStyles();
    const { children, location, width } = props;
    const [open, setOpen] = useState(true);

    const showLayout = ROUTES_WITH_LAYOUT.some((route) =>
        location.pathname.startsWith(route)
    );

    const toggleDrawer = (drawer: boolean) => {
        setOpen(drawer);
    };

    const isMobile = width === "sm" || width === "xs";
    const isWeb = width === "lg" || width === "md" || width === "xl";

    // ****************************************************************
    // THIS USEFFECT IS TO REVIEW in case there are too many re-renders
    useEffect(() => {
        if (isMobile) {
            setOpen(false);
        }
    }, [isMobile]);
    // ****************************************************************

    return (
        <div className={classes.app}>
            {showLayout && (
                <React.Fragment>
                    <CustomAppBar
                        open={open}
                        setOpen={setOpen}
                        toggleDrawer={toggleDrawer}
                    />
                    <DrawerWeb
                        open={open}
                        toggleDrawer={toggleDrawer}
                        hidden={isMobile}
                    />
                    <DrawerMobile
                        open={open}
                        setOpen={setOpen}
                        hidden={isWeb}
                    />
                </React.Fragment>
            )}
            <main
                className={
                    !showLayout
                        ? classes.account
                        : clsx(classes.content, classes.overFlow)
                }
            >
                {!showLayout ? "" : <div className={classes.toolbar} />}
                {children}
            </main>
        </div>
    );
};

export default withRouter(withWidth()(Layout));

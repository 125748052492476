import { FC, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Space } from "antd";
import { useIntl } from "react-intl";
import { ROUTES } from "../../constants/routes";
import { CONFIRM_ACCOUNT } from "../../graphql/authentification/mutation";
import { ConfirmAccount } from "../../graphql/authentification/types/ConfirmAccount";
import { ConfirmAccountVariables } from "../../graphql/authentification/types/ConfirmAccount";
import { useMutation } from "@apollo/client";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { AuthContext } from "../../providers/AuthProvider/AuthProvider";
import { ERROR_MESSAGES } from "../../constants/errors";
import LanguageOptions from "../../components/LanguageOptions/LanguageOptions";
import PageContainer from "../../components/PageContainer/PageContainer";
import useStyles from "./styles";
import tcLogo from "../../images/tc-logo.png";
import CustomButton from "../../components/CustomButton/CustomButton";

interface IParams {
    confirmationToken?: string;
}

const AccountConfirmation: FC<RouteComponentProps> = ({ history, match }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { logout } = useContext(AuthContext);
    const params: IParams = match.params;
    const confirmationToken = params.confirmationToken;
    const [confirmed, setConfirmed] = useState(false);

    const [confirmAccount, { loading }] = useMutation<
        ConfirmAccount,
        ConfirmAccountVariables
    >(CONFIRM_ACCOUNT, {
        onCompleted: async (data) => {
            if (data && data.confirmAccount && data.confirmAccount.confirmed) {
                displaySnackBar({
                    message: formatMessage(
                        { id: "success.account.confirmation" },
                        { email: data.confirmAccount.email }
                    ),
                    type: "success",
                });
                logout();
                setConfirmed(true);
            }
        },

        onError: (error) => {
            if (
                error &&
                error.message === ERROR_MESSAGES.USER_ACCOUNT_ALREADY_CONFIRMED
            ) {
                logout();
                setConfirmed(true);
                displaySnackBar({
                    message: formatMessage({
                        id: error.message || "error.unknown",
                    }),
                    type: "info",
                });
            } else {
                displaySnackBar({
                    message: formatMessage({
                        id: error.message || "error.unknown",
                    }),
                    type: "error",
                });
            }
        },
    });

    const handleConfirmAccount = () => {
        if (confirmationToken) {
            confirmAccount({
                variables: {
                    confirmationToken,
                },
            });
        }
    };

    const handleSignin = () => {
        history.replace(ROUTES.HOME);
    };

    return (
        <PageContainer isPublicPage>
            <div className={classes.languageOptions}>
                <LanguageOptions />
            </div>

            <img src={tcLogo} height="230px" width="auto" alt="no data" />
            <Space direction="vertical" align="center" className="mt-4">
                <Typography className={classes.notFound}>
                    {formatMessage({
                        id: confirmed
                            ? "auth.account.confirmed"
                            : "auth.account.confirmation.title",
                    })}
                </Typography>
                {!confirmed && (
                    <Typography className={classes.font14}>
                        {formatMessage({
                            id: "auth.account.confirmation.subtitle",
                        })}
                    </Typography>
                )}
                {confirmed ? (
                    <CustomButton
                        onClick={handleSignin}
                        text={formatMessage({ id: "auth.signin.link" })}
                    />
                ) : (
                    <CustomButton
                        onClick={handleConfirmAccount}
                        text={formatMessage({ id: "common.confirm" })}
                        loading={loading}
                    />
                )}
            </Space>
        </PageContainer>
    );
};

export default withRouter(AccountConfirmation);

import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import CustomTab from "../../components/CustomTab/CustomTab";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import Backup from "./Backup/Backup";
import Recover from "./Recover/Recover";

enum BACKUP_TAB_TITLES {
    BACKUP = "backup.tab.backup",
    RECOVER = "backup.tab.recover",
}

export enum BACKUP_TAB_ROUTES {
    BACKUP = "backup",
    RECOVER = "recover",
}

const mapBackupTabRoutesToTitle = (
    backupTabRoute: string | undefined | null
) => {
    switch (backupTabRoute) {
        case BACKUP_TAB_ROUTES.BACKUP:
            return BACKUP_TAB_TITLES.BACKUP;

        case BACKUP_TAB_ROUTES.RECOVER:
            return BACKUP_TAB_TITLES.RECOVER;

        default:
            return BACKUP_TAB_TITLES.BACKUP;
    }
};

interface IParams {
    tab?: string;
}

const BackupRecover: FC<RouteComponentProps> = (props) => {
    const { match, history } = props;
    const params: IParams = match.params;

    const tab = mapBackupTabRoutesToTitle(params.tab);
    const [selectedTab, setSelectedTab] = useState<BACKUP_TAB_TITLES>(
        tab || BACKUP_TAB_TITLES.BACKUP
    );

    const onClickTab = (tab: string) => {
        setSelectedTab(tab as BACKUP_TAB_TITLES);

        if (tab === BACKUP_TAB_TITLES.BACKUP) {
            history.push(
                `${ROUTES.BACKUP_RECOVER}/${BACKUP_TAB_ROUTES.BACKUP}`
            );
        }

        if (tab === BACKUP_TAB_TITLES.RECOVER) {
            history.push(
                `${ROUTES.BACKUP_RECOVER}/${BACKUP_TAB_ROUTES.RECOVER}`
            );
        }
    };

    return (
        <PageContainer title="Backup/Recover Documents">
            <TitleWithBreadcrumb
                title="menu.backupRecover"
                routes={[
                    {
                        path: ROUTES.BACKUP_RECOVER,
                        title: "menu.backupRecover",
                    },
                ]}
            />
            <CustomTab
                tabs={[BACKUP_TAB_TITLES.BACKUP, BACKUP_TAB_TITLES.RECOVER]}
                selectedTab={selectedTab}
                onClickTab={onClickTab}
            />

            {selectedTab === BACKUP_TAB_TITLES.BACKUP ? (
                <Backup />
            ) : (
                <Recover title subtitle />
            )}
        </PageContainer>
    );
};

export default withRouter(BackupRecover);

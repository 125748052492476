import { makeStyles, Theme, createStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flag: { marginTop: "-4px", fontSize: 23 },
        flagBar: { fontSize: 22 },
        outlineDown: { fontSize: 10, marginLeft: 5, marginTop: "-4px" },
        iconBtn: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        blue: {
            background: COLORS.lightblue,
            // [theme.breakpoints.down("sm")]: {
            //     background: COLORS.lightblue,
            // },
        },
        language: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            paddingLeft: "1em",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
    })
);

export default useStyles;

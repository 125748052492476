import { createStyles, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { Badge } from "react-bootstrap";
import { useIntl } from "react-intl";
import { RequestStatus } from "../../constants/types";

const useStyles = makeStyles(() =>
    createStyles({
        pending: {
            fontSize: "14px",
            padding: "0.6em 1.5em",
            background: "#F38E38",
            color: "white",
            fontWeight: 400,
        },
        accepted: {
            fontSize: "14px",
            padding: "0.6em 1.5em",
            fontWeight: 400,
            background: "#03A62C",
            color: "#fff",
        },
        cancelled: {
            fontSize: "14px",
            padding: "0.6em 1.5em",
            background: "#D64D4B",
            color: "#fff",
            fontWeight: 400,
        },
        rejected: {
            fontSize: "14px",
            padding: "0.6em 1.5em",
            background: "#D64D4B",
            fontWeight: 400,
            color: "#fff",
        },
        badge: {
            fontSize: "14px",
            background: "#F1F4F5",
            padding: "0.6em 1.5em",
            fontWeight: 400,
            cursor: "pointer",
        },
        all: {
            fontSize: "14px",
            background: "#236CA5",
            padding: "0.6em 1.5em",
            fontWeight: 400,
            color: "white",
        },
        selectedBadge: {
            fontSize: 14,
            background: "#236CA5",
            padding: "0.6em 1.5em",
            fontWeight: 400,
            color: "white",
            "&:hover": {
                background: "white",
                color: "#1D63BD",
                border: "1px solid #1D63BD",
            },
        },
    })
);

interface IProps {
    text: string;
    type?: RequestStatus;
    selected?: boolean;
    onClick?(item: string): void;
}

const FilterChips: FC<IProps> = ({ text, type, selected, onClick }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const getClassName = () => {
        switch (type) {
            case RequestStatus.ALL:
                return selected ? classes.all : classes.badge;

            case RequestStatus.PENDING:
                return selected ? classes.pending : classes.badge;

            case RequestStatus.ACCEPTED:
                return selected ? classes.accepted : classes.badge;

            case RequestStatus.REJECTED:
                return selected ? classes.rejected : classes.badge;

            case RequestStatus.CANCELED:
                return selected ? classes.cancelled : classes.badge;

            default:
                return selected ? classes.selectedBadge : classes.badge;
        }
    };

    return (
        <Badge
            pill
            className={getClassName()}
            onClick={() => {
                if (onClick) onClick(text);
            }}
        >
            {formatMessage({ id: text })}
        </Badge>
    );
};

export default FilterChips;

import { createStyles, makeStyles, Paper } from "@material-ui/core";
import { FC } from "react";
import { CustomFC } from "../../constants/types";

const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            display: "flex",
            flexDirection: "column",
            padding: "0.5em 1em 1em 1em",
            marginTop: "1.5em",
            boxShadow: "0px 0px 21px -3px rgba(0,0,0,0.05)",
        },
    })
);

const CustomPaper: FC<CustomFC> = ({ children }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={2}>
            {children}
        </Paper>
    );
};

export default CustomPaper;

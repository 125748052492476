import { useQuery } from "@apollo/client";
import { FormLabel } from "@material-ui/core";
import { FC, useContext } from "react";
import { useIntl } from "react-intl";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { Select, Space } from "antd";
import { GET_MY_PARTNERS } from "../../graphql/partnership/query";
import { MyPartners } from "../../graphql/partnership/types/MyPartners";
import { MyPartners_myPartners } from "../../graphql/partnership/types/MyPartners";
import CustomPartnerStatus from "../CustomPartnerStatus/CustomPartnerStatus";

const { Option } = Select;

export interface IAutocompleteUser {
    id?: string | null;
    email: string;
    confirmed?: boolean;
}

export const defaultUser: IAutocompleteUser = {
    email: "",
};

interface IProps {
    partner: MyPartners_myPartners | undefined;
    label?: string;
    onPartnerChange(partner: MyPartners_myPartners | undefined | string): void;
    noSelectedPartner?: boolean;
}

const CustomAutocompletePartners: FC<IProps> = ({
    partner,
    label,
    onPartnerChange,
    noSelectedPartner,
}) => {
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();

    const { data } = useQuery<MyPartners>(GET_MY_PARTNERS, {
        onError: (err) => {
            displaySnackBar({
                message: formatMessage({
                    id: err.message || "error.unknown",
                }),
                type: "error",
            });
        },
    });

    const partners = data?.myPartners || [];

    const handleChange = (id: Number | string | null) => {
        const _partner =
            id === "noSelectedPartner"
                ? "noSelectedPartner"
                : id && partners?.length
                ? partners.find((partner) => Number(partner.id) === id)
                : undefined;
        onPartnerChange(_partner);
    };

    return (
        <>
            <FormLabel
                required
                className="formLabel"
                classes={{ asterisk: "asterisk" }}
            >
                {formatMessage({ id: label })}
            </FormLabel>
            <Select
                value={
                    noSelectedPartner
                        ? "noSelectedPartner"
                        : partner
                        ? Number(partner?.id)
                        : null
                }
                onChange={(e) => handleChange(e)}
                dropdownStyle={{ zIndex: 2000 }}
                allowClear
                showSearch
                optionFilterProp="children"
                className="antdSelect"
            >
                <Option value={"noSelectedPartner"}>
                    {formatMessage({ id: "document.no.partner" })}
                </Option>
                {partners?.length
                    ? partners.map((item, idx) => (
                          <Option value={Number(item?.id)} key={idx + 1}>
                              <Space>
                                  {item?.partner?.email}
                                  (
                                  <CustomPartnerStatus
                                      activated={item?.partner?.activated}
                                  />
                                  )
                              </Space>
                          </Option>
                      ))
                    : ""}
            </Select>
        </>
    );
};

export default CustomAutocompletePartners;

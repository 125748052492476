import { getMainDefinition } from "@apollo/client/utilities";
import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "../config/config";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";

interface ICreateApolloClient {
    token: string | null;
    keycloakToken: string | undefined;
}

const createApolloClient = ({ token, keycloakToken }: ICreateApolloClient) => {
    const httpLink = createUploadLink({
        uri: config.serverUrl,
    });

    const wsLink = new GraphQLWsLink(
        createClient({
            url: token ? `${config.wsUrl}?token=${token}` : config.wsUrl,
            lazy: true,
        })
    );

    const authLink = setContext((_, { headers }) => {
        if (!token) {
            return headers;
        }

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
                KeycloakAuthorization: keycloakToken
                    ? `Bearer ${keycloakToken}`
                    : "",
            },
        };
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
            );
        },
        wsLink,
        authLink.concat(httpLink)
    );

    const client = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "cache-and-network",
            },
        },
    });

    return client;
};

export default createApolloClient;

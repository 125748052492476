import React, { FC } from "react";
import { Button, CircularProgress, Box, Tooltip } from "@material-ui/core";
import { Button as AntBtn } from "antd";
import useStyles from "./useStyles";
import COLORS from "../../constants/colors";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";
import clsx from "clsx";

interface Props {
    text: string;
    onClick: () => void;
    bordered?: boolean;
    loading?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    isCancel?: boolean;
    isMini?: boolean;
    background?: string;
    icon?: React.ReactElement;
    tooltip?: string;
    hideTextOnMobile?: boolean;
    fullWidth?: boolean;
}

const CustomButton: FC<Props> = (props) => {
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const {
        text,
        onClick,
        loading,
        disabled,
        style,
        bordered,
        isCancel,
        isMini,
        background,
        icon,
        tooltip,
        hideTextOnMobile,
        fullWidth,
    } = props;
    const opacity = disabled || loading ? 0.5 : 1;
    const color = bordered ? COLORS.primaryDark : COLORS.white;

    if (isMini)
        return (
            <Tooltip title={tooltip || ""} arrow className="mr-1">
                <span>
                    <AntBtn
                        disabled={disabled || loading}
                        className={classes.btn}
                        size="small"
                        onClick={onClick}
                        style={{
                            fontSize: 11,
                            borderRadius: 3,
                            background:
                                background ||
                                `linear-gradient(to bottom, ${COLORS.primaryLight}, ${COLORS.primaryDark})`,

                            color: "#fff",
                            borderWidth: 0,
                        }}
                    >
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {!loading && icon ? (
                                <Box
                                    style={{
                                        marginRight: 5,
                                        marginBottom: 2,
                                    }}
                                >
                                    {icon}
                                </Box>
                            ) : null}
                            {loading && (
                                <CircularProgress
                                    size={10}
                                    style={{ color, marginRight: 10 }}
                                />
                            )}
                            {text}
                        </Box>
                    </AntBtn>
                </span>
            </Tooltip>
        );

    if (isCancel)
        return (
            <Button
                color="inherit"
                onClick={onClick}
                size="small"
                className={classes.cancel}
                style={{ opacity, ...style }}
            >
                {text}
            </Button>
        );

    return (
        <Button
            size={breakpoint === "xs" ? "small" : "medium"}
            className={
                bordered
                    ? classes.bordered
                    : clsx(classes.primary, fullWidth ? "w-100" : "")
            }
            onClick={onClick}
            disabled={disabled || loading}
            style={{ opacity, ...style }}
            classes={{
                root: clsx(classes.primary, fullWidth ? "w-100" : ""),
                disabled: classes.disabled,
            }}
        >
            {loading ? (
                <Box
                    style={{
                        textTransform: "capitalize",
                    }}
                >
                    <CircularProgress
                        size={15}
                        style={{ color, marginRight: 10 }}
                    />
                    {text}
                </Box>
            ) : (
                <Box
                    style={{
                        textTransform: "capitalize",
                    }}
                >
                    <span className="mr-1"> {icon}</span>
                    <span
                        className={hideTextOnMobile ? classes.text : undefined}
                    >
                        {text}
                    </span>
                </Box>
            )}
        </Button>
    );
};

export default CustomButton;

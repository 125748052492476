import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import Subscription from "./Subscription/Subscription";

export enum ACCOUNT_TAB_ROUTES {
    SUBSCRIPTION = "subscription",
}

const MyAccount: FC<RouteComponentProps> = () => {
    return (
        <PageContainer title="My Account">
            <TitleWithBreadcrumb
                title="menu.myAccount"
                routes={[{ path: ROUTES.IPFS, title: "menu.myAccount" }]}
            />

            <Subscription />
        </PageContainer>
    );
};

export default withRouter(MyAccount);

import JSZip from "jszip";
import fileSize from "file-size";

export const generateArmoredFileName = (filename: string) =>
    `${filename}.tdFile`;

export const generatePassphraseFileName = (filename: string, wallet: string) =>
    `${filename}-${wallet}.tdKey`;

export const getFileSize = (file: number | null | undefined) => {
    const _fileSize = file ? fileSize(file).human("jedec") : 0;
    return _fileSize;
};

export const zipBlobs = (blobs: any) => {
    return new Promise((resolve, reject) => {
        var zip = new JSZip();
        blobs.forEach(function (item: any) {
            zip.file(item.fileName, item.blob);
        });
        zip.generateAsync({ type: "blob" }).then(
            (zippedBlob) => resolve(zippedBlob),
            (error) => {
                reject(error);
            }
        );
    });
};

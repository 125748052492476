import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { DRAWER_WIDTH } from "../Layout/constants";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: 1,
            width: `calc(100% - ${theme.spacing(8) + 1}px)`,
            background: "#FEFEFF",
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: " 2px 0px 3px -1px rgba(0,0,0,0.70)",
        },
        appBarShift: {
            marginLeft: DRAWER_WIDTH,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            [theme.breakpoints.down("sm")]: {
                marginLeft: 0,
                width: `calc(100% - ${0}px)`,
                background: "#FEFEFF",
                boxShadow: " 2px 0px 3px -1px rgba(0,0,0,0.70)",
                zIndex: 1,
            },
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        iconBtn: {
            // background: "#E6EDF8",
            // background: "#CCDCF8",
            background: `${COLORS.lightblue} !important`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // [theme.breakpoints.down("sm")]: {
            //     background: COLORS.lightblue,
            // },
        },
        barIcon: {
            color: "#192F5D",
            marginTop: "5px",
            // [theme.breakpoints.down("sm")]: {
            //     color: "#f5f5fa",
            // },
        },
    })
);

export default useStyles;

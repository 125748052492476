import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { DATETIME_FORMAT } from "../../../constants/datetime";
import { RequestStatus } from "../../../types/graphql-global-types";
import { Badge, Button, Space } from "antd";
import moment from "moment";

const Columns = (
    onActionClick: any,
    classes: ClassNameMap,
    formatMessage: any,
    page: number,
    pageSize: number
) => {
    const defaultColumns = [
        {
            title: "#",
            dataIndex: "no",
            key: "no",
            width: 10,
            render: (_: any, __: any, idx: number) => {
                return idx + 1 + (page - 1) * pageSize;
            },
        },
        {
            title: formatMessage({ id: "common.sender" }),
            dataIndex: "sender",
            key: "sender",
            sorter: (a: any, b: any) =>
                a?.sender?.email?.localeCompare(b.sender.email),
            render: (sender: any) => sender?.email || "",
        },
        {
            title: formatMessage({ id: "common.recipient" }),
            dataIndex: "recipient",
            key: "recipient",
            sorter: (a: any, b: any) =>
                a?.recipient?.email?.localeCompare(b?.recipient?.email),
            render: (_: any, partner: any) =>
                partner?.recipient
                    ? `${partner?.recipient?.email} ${
                          partner.sentToMe
                              ? `(${formatMessage({
                                    id: "common.you",
                                })})`
                              : ""
                      }`
                    : `${partner.targetEmail} (${formatMessage({
                          id: "common.invited.via.email",
                      })})`,
        },
        {
            title: formatMessage({ id: "common.status" }),
            dataIndex: "status",
            key: "status",
            sorter: (a: any, b: any) => a.status.localeCompare(b.status),
            render: (status: string) => {
                return (
                    <Badge
                        count={status}
                        style={{
                            fontSize: 13,
                            background:
                                status === RequestStatus.ACCEPTED
                                    ? "#03A62C"
                                    : status === RequestStatus.PENDING
                                    ? "#F38E38" // "linear-gradient(to bottom, #0B9F6F, #00A66B)" //86D168
                                    : "#D64D4B", //"#F38E38",
                        }}
                    />
                );
            },
        },
        {
            title: formatMessage({ id: "common.date" }),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: string) => {
                return moment(createdAt).format(DATETIME_FORMAT);
            },
        },
        {
            title: formatMessage({ id: "common.actions" }),
            dataIndex: "actions",
            key: "actions",
            render: (_: any, invite: any) => {
                return (
                    <Space>
                        {invite.status === RequestStatus.PENDING &&
                            invite.sentToMe && (
                                <Button
                                    size="middle"
                                    type="ghost"
                                    className={classes.acceptBtn}
                                    onClick={() =>
                                        onActionClick(
                                            true,
                                            invite,
                                            RequestStatus.ACCEPTED
                                        )
                                    }
                                >
                                    {formatMessage({
                                        id: "common.accept",
                                    })}
                                </Button>
                            )}
                        {invite.status === RequestStatus.PENDING &&
                            invite.sentToMe && (
                                <Button
                                    size="middle"
                                    type="ghost"
                                    className={classes.deleteBtn}
                                    onClick={() =>
                                        onActionClick(
                                            true,
                                            invite,
                                            RequestStatus.REJECTED
                                        )
                                    }
                                >
                                    {formatMessage({
                                        id: "common.reject",
                                    })}
                                </Button>
                            )}
                        {invite.status === RequestStatus.PENDING &&
                            invite.sentByMe && (
                                <Button
                                    size="middle"
                                    type="ghost"
                                    className={classes.acceptBtn}
                                    onClick={() =>
                                        onActionClick(
                                            true,
                                            invite,
                                            RequestStatus.CANCELED
                                        )
                                    }
                                >
                                    {formatMessage({
                                        id: "common.cancel",
                                    })}
                                </Button>
                            )}
                        {(invite.status === RequestStatus.ACCEPTED ||
                            invite.status === RequestStatus.REJECTED ||
                            invite.status === RequestStatus.CANCELED) &&
                            "-"}
                    </Space>
                );
            },
        },
    ];
    return defaultColumns;
};

export default Columns;

import { FaUsers, FaFileAlt, FaHome, FaUser, FaTag } from "react-icons/fa"; //  FaCloudUploadAlt
import {
    MdSettingsBackupRestore,
    MdDashboard,
    MdSettings,
    MdCardMembership,
} from "react-icons/md"; //

import { ROUTES } from "../../constants/routes";
import React from "react";

export const DRAWER_WIDTH = 240;

const iconStyle: React.CSSProperties | undefined = {
    // color: "#696E75",
    color: "#363636",
    fontSize: "1.1em",
};

export const DRAWER_ROUTES = [
    {
        menu: "menu.dashboard",
        route: ROUTES.DASHBOARD,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaHome style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    {
        menu: "menu.partners",
        route: ROUTES.PARTNERSHIP,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaUsers style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    {
        menu: "menu.documents",
        route: `${ROUTES.DOCUMENTS}`,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaFileAlt style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    // {
    //     menu: "menu.IPFS",
    //     route: ROUTES.IPFS,
    //     icon: <FaCloudUploadAlt style={iconStyle} />,
    // },
    // {
    //     menu: "menu.plan",
    //     route: ROUTES.PLAN,
    //     icon: <MdCardMembership style={iconStyle} />,
    // },
    // {
    //     menu: "menu.backupRecover",
    //     route: ROUTES.BACKUP_RECOVER,
    //     icon: (customStyle?: React.CSSProperties | undefined) => (
    //         <MdSettingsBackupRestore style={customStyle || iconStyle} />
    //     ),
    //     sub: null,
    // },
    {
        menu: "menu.myAccount",
        route: ROUTES.MY_ACCOUNT,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaUser style={customStyle || iconStyle} />
        ),
        sub: [
            {
                menu: "menu.subscription",
                route: ROUTES.SUBSCRIPTION,
                icon: (customStyle?: React.CSSProperties | undefined) => (
                    <MdCardMembership style={customStyle || iconStyle} />
                ),
            },
            {
                menu: "menu.backupRecover",
                route: ROUTES.BACKUP_RECOVER,
                icon: (customStyle?: React.CSSProperties | undefined) => (
                    <MdSettingsBackupRestore style={customStyle || iconStyle} />
                ),
            },
            {
                menu: "menu.categories",
                route: ROUTES.CATEGORIES,
                icon: (customStyle?: React.CSSProperties | undefined) => (
                    <FaTag style={customStyle || iconStyle} />
                ),
                sub: null,
            },
        ],
    },
];

export const ADMIN_DRAWER_ROUTES = [
    {
        menu: "menu.admin.dashboard",
        route: ROUTES.ADMIN_DASHBOARD,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <MdDashboard style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    {
        menu: "menu.admin.users",
        route: ROUTES.ADMIN_USERS,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaUsers style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    {
        menu: "menu.admin.users.tx",
        route: ROUTES.ADMIN_USERS_TX,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <FaUsers style={customStyle || iconStyle} />
        ),
        sub: null,
    },
    {
        menu: "menu.admin.settings",
        route: ROUTES.ADMIN_SETTINGS,
        icon: (customStyle?: React.CSSProperties | undefined) => (
            <MdSettings style={customStyle || iconStyle} />
        ),
        sub: null,
    },
];

import { FC } from "react";
import { Button, Select } from "antd";
import { Typography, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";

const { Option } = Select;

interface Props {
    list?: number;
    selected?: number;
    handleDelete?: any;
    data?: any;
    divClass?: any;
}

const BulkDelete: FC<Props> = (props) => {
    const { selected, handleDelete, divClass } = props;
    const { formatMessage } = useIntl();

    if (!selected) return null;
    return (
        <div className={`d-flex align-items-center ${divClass}`}>
            <Select aria-label="delete" style={{ width: 250 }} value="delete">
                <Option value="delete">Delete selected items</Option>
            </Select>

            <Tooltip
                title={formatMessage({ id: "common.delete.selected" })}
                arrow
                placement="bottom"
            >
                <Button
                    onClick={handleDelete}
                    className="ml-2"
                    style={{
                        background: "#D64D4B", //f5f5fa D64D4B
                        borderRadius: 4,
                        fontSize: 14,
                        color: "#fff",
                    }}
                >
                    {formatMessage({ id: "common.delete" })}
                </Button>
            </Tooltip>

            <Typography style={{ fontSize: 13 }} className="ml-2">
                {selected} selected
            </Typography>
        </div>
    );
};

export default BulkDelete;

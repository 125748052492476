const ipfs = {
    "ipfs.gateway": "Passerelle",
    "ipfs.pinningService": "Service d'épinglage",
    "ipfs.token": "Token",
    "ipfs.default.node": "Nœud IPFS par défaut",
    "ipfs.pinata": "Pinata",
    "ipfs.nft.storage": "NFT.Storage",
    "ipfs.filebase": "Filebase",
    "ipfs.service": "Service IPFS",
};

export default ipfs;

import Swal from "sweetalert2";

export const sweetalert = (icon: any, title: any, text?: any, html?: any) => {
    return Swal.fire({
        title,
        text,
        html,
        icon,
        allowOutsideClick: false,
        confirmButtonColor: "#2365B9",
        focusConfirm: false,
    });
};

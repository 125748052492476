import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Container, Grid } from "@material-ui/core";
import { FC, CSSProperties } from "react";
import { CustomFC } from "../../constants/types";
import SEO from "../SEO/SEO";

interface IProps {
    isAuthPage?: boolean;
    isPublicPage?: boolean;
    title?: string;
    description?: string;
    style?: CSSProperties;
}

const PageContainer: FC<CustomFC & IProps> = (props) => {
    const classes = useStyles();
    const { children, isAuthPage, isPublicPage, title, style } = props;

    const content = isAuthPage ? (
        <Grid container className={classes.auth}>
            {children}
        </Grid>
    ) : isPublicPage && children ? (
        <Container maxWidth="lg" className={classes.public} style={style}>
            {children}
        </Container>
    ) : (
        <div className={classes.private}>{children}</div>
    );

    return (
        <>
            <SEO title={title} />
            {content}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        auth: { height: "100vh", width: "100vw" },
        public: {
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
        },
        private: {
            padding: "0 5em",
            marginBottom: "2em",
            [theme.breakpoints.down("lg")]: { padding: "0 1em" },
        },
    })
);
export default PageContainer;

import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { Button } from "antd";
import { useKeycloak } from "@react-keycloak/web";
import { Image } from "react-bootstrap";
import TracerkeyLogo from "../../components/TracerkeyLogo/TracerkeyLogo";
import config from "../../config/config";
import keyCloakLogo from "../../images/keycloak_logo.png";
import useStyles from "./styles";
import AuthLayout from "./AuthLayout";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";

const SigninPage: FC<RouteComponentProps> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { keycloak } = useKeycloak();
    const breakpoint = useBreakpoint();

    const locationState =
        location?.state && typeof location?.state === "string"
            ? JSON.parse(`${location?.state}`)
            : "";
    const locationStateFrom = locationState?.from || "";
    const redirectUri = `${config.uiUrl}${ROUTES.WALLET_AUTH}${
        locationStateFrom &&
        locationStateFrom?.pathname?.includes(ROUTES.DOCUMENTS) &&
        locationStateFrom?.search
            ? locationStateFrom?.search
            : ""
    }`;

    const login = async () => {
        await keycloak.login({ redirectUri });
    };

    return (
        <AuthLayout type="signin" title="auth.title" pageTitle="Sign In">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",
                    marginTop: 32,
                    fontSize: 20,
                    fontWeight: "bold",
                }}
            >
                <Image
                    src={keyCloakLogo}
                    width="60px"
                    height="auto"
                    alt="keycloak logo"
                    className="mr-2"
                />
                +
                <TracerkeyLogo
                    width="60px"
                    height="auto"
                    withTracerkeyText
                    style={{ marginLeft: 15 }}
                />
            </div>

            <form
                className={classes.form}
                noValidate
                style={{ marginTop: "2em" }}
            >
                <Button
                    size="large"
                    type="primary"
                    className={classes.submitBtn}
                    onClick={login}
                >
                    {formatMessage({
                        id: `auth.signin.keycloak.two.factor.auth${
                            breakpoint === "xs" || breakpoint === "sm"
                                ? ".mobile"
                                : ""
                        }`,
                    })}
                </Button>
            </form>
        </AuthLayout>
    );
};

export default withRouter(SigninPage);

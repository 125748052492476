import authentification from "./authentification";
import common from "./common";
import document from "./document";
import error from "./error";
import home from "./home";
import ipfs from "./ipfs";
import menu from "./menu";
import partnership from "./partnership";
import success from "./success";
import wallet from "./wallet";
import plan from "./plan";
import account from "./account";
import categories from "./categories";
import backup from "./backup";
import dashboard from "./dashboard";

const en = {
    ...authentification,
    ...home,
    ...dashboard,
    ...common,
    ...menu,
    ...error,
    ...success,
    ...partnership,
    ...document,
    ...ipfs,
    ...wallet,
    ...plan,
    ...account,
    ...categories,
    ...backup,
};

export default en;

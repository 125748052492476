import { FC, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Space } from "antd";
import { useIntl } from "react-intl";
import { ROUTES } from "../../constants/routes";
import { GET_USER } from "../../graphql/user/query";
import { GetUser, GetUserVariables } from "../../graphql/user/types/GetUser";
import { useQuery } from "@apollo/client";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import PageContainer from "../../components/PageContainer/PageContainer";
import useStyles from "./styles";
import tcLogo from "../../images/email_sent.png";
import CustomButton from "../../components/CustomButton/CustomButton";
import LanguageOptions from "../../components/LanguageOptions/LanguageOptions";

interface IParams {
    userAddress?: string;
}

const ConfirmationEmailSent: FC<RouteComponentProps> = ({ history, match }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const params: IParams = match.params;

    const { data } = useQuery<GetUser, GetUserVariables>(GET_USER, {
        variables: { id: params.userAddress || "" },
        skip: !params.userAddress,
        onError: (err) => {
            displaySnackBar({
                message: formatMessage({ id: err.message || "error.unknown" }),
                type: "error",
            });
        },
    });

    const user = data?.getUser;
    const email = user?.email || formatMessage({ id: "common.your.email" });

    const goToSignin = () => {
        history.push(ROUTES.SIGNIN);
    };

    return (
        <PageContainer isPublicPage>
            <div className={classes.languageOptions}>
                <LanguageOptions />
            </div>

            <img src={tcLogo} height="230px" width="auto" alt="no data" />
            <Space direction="vertical" align="center" className="mt-4">
                <Typography className={classes.notFound}>
                    {formatMessage({
                        id: "auth.confirmation.email.sent.title",
                    })}
                </Typography>

                <Typography className={classes.font14}>
                    {formatMessage(
                        { id: "auth.confirmation.email.sent.subtitle" },
                        { email }
                    )}
                </Typography>

                <CustomButton
                    onClick={goToSignin}
                    text={formatMessage({ id: "auth.signin.link" })}
                />
            </Space>
        </PageContainer>
    );
};

export default withRouter(ConfirmationEmailSent);

import React, { useState, createContext, FC } from "react";
import { IntlProvider } from "react-intl";
import LANGUAGES from "../../constants/language";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import { CustomFC } from "../../constants/types";
import locales from "../../locales";

type ITranslation = {
    language: LANGUAGES;
    changeLanguage: (language: LANGUAGES) => void;
};

export const TranslationContext = createContext<ITranslation>({
    language: LANGUAGES.EN,
    changeLanguage: () => {},
});

const TranslationProvider: FC<CustomFC> = ({ children }) => {
    const localLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
    const [language, setLanguage] = useState<LANGUAGES>(
        (localLanguage as LANGUAGES) || LANGUAGES.EN
    );

    const changeLanguage = (lang: LANGUAGES) => {
        setLanguage(lang);
        localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, lang);
    };

    return (
        <TranslationContext.Provider
            value={{
                language,
                changeLanguage,
            }}
        >
            <IntlProvider
                locale={language}
                defaultLocale={LANGUAGES.EN}
                messages={locales[language]}
            >
                {children}
            </IntlProvider>
        </TranslationContext.Provider>
    );
};

export default TranslationProvider;

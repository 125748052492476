const common = {
    "common.app.name": "TracerDoc",
    "common.email": "Email",
    "common.email.invalid": "Format de l'email invalid",
    "common.english": "Anglais (US)",
    "common.french": "Français",
    "common.confirm": "Confirmer",
    "common.cancel": "Annuler",
    "common.resend": "Renvoyer",
    "common.your.email": "ton email",
    "common.all": "Tous",
    "common.pending": "En Attente",
    "common.accepted": "Accepté(é)",
    "common.rejected": "Rejeté(é)",
    "common.canceled": "Annulé(é)",
    "common.invite": "Inviter",
    "common.invite.via.email": "Inviter par email",
    "common.requireField": "Champ obligatoire",
    "common.no.data": "Aucune donnée",
    "common.no.user": "Aucun utilisateur trouvé",
    "common.no.partner": "Aucun partenaire trouvé",
    "common.sender": "Expéditeur",
    "common.recipient": "Destinataire",
    "common.status": "Statut",
    "common.date": "Date",
    "common.my.docs": "Mes documents",
    "common.partner.docs": "Documents du partenaire",
    "common.actions": "Actions",
    "common.user.email": "Email de l'utilisateur",
    "common.you": "Vous",
    "common.invited.via.email": "Invité via email",
    "common.accept": "Accepter",
    "common.reject": "Rejeter",
    "common.partner": "Partenaire",
    "common.partner.address": "Addresse du partenaire",
    "common.partner.email": "Email du partenaire",
    "common.partnership.date": "Date du partenariat",
    "common.document": "Document :",
    "common.dragtext.message":
        "Cliquez ou faites glisser le fichier dans cette zone pour le télécharger",
    "common.loading": "En cours...",
    "common.encrypting": "Encryption...",
    "common.uploading": "Upload...",
    "common.downloading": "Téléchargement...",
    "common.saving": "Sauvegarde...",
    "common.sent.by.me": "Envoyé / Boîte d'envoi",
    "common.sent.to.me": "Reçu / Boîte de réception",
    "common.save": "Sauvegarder",
    "common.save.changes": "Sauvegarder",
    "common.reset.default": "Réinitialiser par défaut",
    "common.default": "Par défaut : ",
    "common.encryption": "Cryptage",
    "common.decryption": "Décryptage",
    "common.rows.per.page": "Lignes par page :",
    "common.loading.keycloak": "Chargement du compte...",
    "common.connected.keycloak.account": "COMPTE CONNECTÉ :",
    "common.keycloak.logout": "Déconnecter le compte",
    "common.partner.status.activated": "Clé Publique Activé",
    "common.partner.status.activated.desc":
        "Vos partenaires peuvent vous transférer des documents",
    "common.partner.status.locked": "Clé Public Verrouillé",
    "common.partner.status.locked.desc":
        "Activer la clé publique de votre portefeuille pour transférer des documents ou pour permettre à vos partenaires de vous transférer des documents",
    "common.partner.status.activated.tooltip":
        "Vous pouvez transferer des documents à ce partenaire",
    "common.partner.status.locked.tooltip":
        "Vous ne pouvez pas transferer des documents à ce partenaire",
    "common.later": "Plus tard",
    "common.document.row": "Document",
    "common.create": "Créer",
    "common.update": "Mise à jour",
    "common.name": "Nom",
    "common.delete": "Supprimer",
    "common.category": "Catégorie",
    "common.subcategory": "Sous-catégorie",
    "common.remove.filters": "Supprimer les filtres",
    "common.download": "Télécharger",
    "common.recover": "Récupérer",
    "common.decrypting": "Décryption...",
    "common.admin": "Admin",
    "common.normal.user": "Utilisateur normal",
    "common.copy": "Copie",
    "common.copied": "Copié",
    "common.search": "Recherche...",
    "common.done": "Fait",
    "common.edit": "Modifier",
    "common.success": "Succès",
    "common.filter.all": "Tout le temps",
    "common.filter.today": "Aujourd'hui",
    "common.filter.week": "Cette semaine",
    "common.filter.month": "Ce mois-ci",
    "common.filter.custom": "Date personnalisée",
    "common.delete.selected": "Supprimer les éléments sélectionnés",
    "common.submit": "Submit",
};

export default common;

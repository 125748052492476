import { ChangeEvent, FC, useContext, useState, useEffect } from "react";
import { getFileSize } from "../../../utils/document";
import { useIntl } from "react-intl";
import { Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Badge, Button, Drawer, Space, Tooltip } from "antd";
import { Select, Form } from "antd";
import { MyDocumentTransfers_myDocumentTransfers_documents } from "../../../graphql/document/types/MyDocumentTransfers";
import { AiOutlineClose } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import { FaUnlockAlt, FaLock } from "react-icons/fa";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { GET_MY_CATEGORIES } from "../../../graphql/category/query";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { GetMyCategories } from "../../../graphql/category/types/GetMyCategories";
import { GetMyCategories_myCategories } from "../../../graphql/category/types/GetMyCategories";
import { DELETE_DOCUMENT_TRANSFER } from "../../../graphql/document/mutation";
import { ASSOCIATE_CATEGORY_TO_DOCUMENT_TRANSFER } from "../../../graphql/document/mutation";
import { DeleteDocumentTransfer } from "../../../graphql/document/types/DeleteDocumentTransfer";
import { DeleteDocumentTransferVariables } from "../../../graphql/document/types/DeleteDocumentTransfer";
import { GET_MY_DOCUMENT_TRANSFERS } from "../../../graphql/document/query";
import { sweetalert } from "../../../utils/sweetalert";
import { AssociateCategoryToDocumentTransferVariables } from "../../../graphql/document/types/AssociateCategoryToDocumentTransfer";
import { AssociateCategoryToDocumentTransfer } from "../../../graphql/document/types/AssociateCategoryToDocumentTransfer";
import { ROUTES } from "../../../constants/routes";
import { DOCUMENT_TAB_ROUTES } from "../Document";
import { DATETIME_FORMAT } from "../../../constants/datetime";
import config from "../../../config/config";
import moment from "moment";
import useStyles from "../styles";
import ConfirmDelete from "../../../components/ConfirmDelete/ConfirmDelete";

interface Props {
    open: boolean;
    setOpen(
        open: boolean,
        item: MyDocumentTransfers_myDocumentTransfers_documents | null
    ): void;
    document?: MyDocumentTransfers_myDocumentTransfers_documents | null;
    refetch?: any;
    decryptAndDownload: (
        doc: MyDocumentTransfers_myDocumentTransfers_documents
    ) => void;
    downloadLoader?: boolean;
    isRedirectParams?: string | null;
    download: (doc: MyDocumentTransfers_myDocumentTransfers_documents) => void;
}
const { Option } = Select;

const Item = ({ label, value }: { label: string; value: any }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    return (
        <Grid item xs={12}>
            <Typography variant="body2" className={classes.font14}>
                {formatMessage({ id: `document.${label}` })} : {value}
            </Typography>
        </Grid>
    );
};

const DocumentInfo: FC<Props> = ({
    open,
    setOpen,
    document,
    refetch,
    decryptAndDownload,
    downloadLoader,
    isRedirectParams,
    download,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState("");
    const [selectedCid, setSelectedCid] = useState<string | null>(null);
    const [showCategForm, setShowCategForm] = useState(false);
    const [defaultCategory, setDefaultCategory] = useState<any>();
    const [defaultSubCategory, setDefaultSubCategory] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedSubcategory, setSelectedSubcategory] = useState<any>();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        const defCategory = document?.categories?.length
            ? document?.categories?.[0]
            : null;
        const defSubCategory = document?.subCategories?.length
            ? document?.subCategories?.[0]
            : null;

        setDefaultCategory(defCategory);
        setDefaultSubCategory(defSubCategory);
    }, [document]);

    const { data: { myCategories } = {} } = useQuery<GetMyCategories>(
        GET_MY_CATEGORIES,
        { onError: (err) => onError(err) }
    );

    const [deleteDocumentTransfer, { loading }] = useMutation<
        DeleteDocumentTransfer,
        DeleteDocumentTransferVariables
    >(DELETE_DOCUMENT_TRANSFER, {
        onCompleted: async (data) => {
            if (data?.deleteDocumentTransfer) {
                setOpenDeleteModal(false);
                sweetalert(
                    "success",
                    "Deleted",
                    `A document transfer has been successfully deleted!`
                );
                onDrawerClose();
                await refetch();
            }
        },
        onError: (error) => onError(error),
    });

    const [associateCategoryToDocTransfer, { loading: updateCategLoader }] =
        useMutation<
            AssociateCategoryToDocumentTransfer,
            AssociateCategoryToDocumentTransferVariables
        >(ASSOCIATE_CATEGORY_TO_DOCUMENT_TRANSFER, {
            onCompleted: async (data) => {
                const { associateCategoryToDocumentTransfer: doc } = data || {};
                if (data?.associateCategoryToDocumentTransfer) {
                    const newCategory = doc?.categories?.[0];
                    const newSubCategory = doc?.subCategories?.[0];

                    sweetalert("success", "Success", `Update success!`);
                    setShowCategForm(false);
                    setDefaultCategory(newCategory);
                    setDefaultSubCategory(newSubCategory);
                }
            },
            onError: (error) => onError(error),
            refetchQueries: [{ query: GET_MY_DOCUMENT_TRANSFERS }],
        });

    const confirmDeleteDocument = () => {
        deleteDocumentTransfer({
            variables: {
                input: { ids: [Number(document?.id)] },
            },
        });
    };

    const onMouseEnter = (cid: string) => {
        setTooltipTitle(formatMessage({ id: "common.copy" }));
        setTooltipOpen(true);
        setSelectedCid(cid);
    };

    const onMouseLeave = () => setTooltipOpen(false);

    const onBtnClick = (cid: string) => {
        navigator.clipboard.writeText(cid);
        setTooltipTitle(formatMessage({ id: "common.copied" }));
        setTooltipOpen(true);
    };

    const minifyCID = (cid: string | undefined) => {
        const minifiedCid = cid
            ? cid
                  .slice(0, 18)
                  .concat("...")
                  .concat(cid.slice(cid.length - 3))
            : "";
        return cid ? (
            <Tooltip
                title={tooltipTitle}
                zIndex={1000000}
                visible={isTooltipOpen && cid === selectedCid}
            >
                <span
                    className={classes.pointer}
                    onMouseEnter={() => onMouseEnter(cid)}
                    onMouseLeave={onMouseLeave}
                    onClick={() => onBtnClick(cid)}
                >
                    {minifiedCid}
                    <MdContentCopy className={classes.copyIcon} />
                </span>
            </Tooltip>
        ) : (
            ""
        );
    };

    const onCategoryChange = (e: ChangeEvent<string | null | undefined>) => {
        if (e) {
            const cat = myCategories?.find(
                (item) => Number(item.id) === Number(e)
            );
            const subCategs = cat?.subCategories?.length
                ? cat?.subCategories
                : null;
            setSelectedCategory(cat);
            setSelectedSubcategory(subCategs?.length ? subCategs?.[0] : null);
        } else {
            setSelectedCategory(null);
            setSelectedSubcategory(null);
        }
    };

    const onSubCategoryChange = (e: ChangeEvent<string | null | undefined>) => {
        const cat = selectedCategory?.subCategories?.find(
            (item: any) => item.id === e
        );
        setSelectedSubcategory(e ? cat : null);
    };

    const saveCategoryChanges = () => {
        const { id: newCateg } = selectedCategory || {};
        const { id: prevCateg } = defaultCategory || {};
        const { id: newSubCateg } = selectedSubcategory || {};
        const { id: prevSubCateg } = defaultSubCategory || {};

        if (newCateg !== prevCateg || newSubCateg !== prevSubCateg) {
            const categoryIdsToAdd = [];
            const categoryIdsToDelete = [];
            const subCategoryIdsToAdd = [];
            const subCategoryIdsToDelete = [];

            if (newSubCateg) {
                subCategoryIdsToAdd.push({ id: newSubCateg });
                if (prevSubCateg) {
                    subCategoryIdsToDelete.push({ id: prevSubCateg });
                }
                if (prevCateg && prevCateg !== newCateg) {
                    categoryIdsToDelete.push({ id: prevCateg });
                }
            } else if (!newSubCateg && newCateg) {
                categoryIdsToAdd.push({ id: newCateg });
                if (prevCateg && prevCateg !== newCateg) {
                    categoryIdsToDelete.push({ id: prevCateg });
                }
                if (prevSubCateg) {
                    subCategoryIdsToDelete.push({ id: prevSubCateg });
                }
            } else if (!newSubCateg && !newCateg) {
                if (prevCateg) {
                    categoryIdsToDelete.push({ id: prevCateg });
                }
                if (prevSubCateg) {
                    subCategoryIdsToDelete.push({ id: prevSubCateg });
                }
            }

            associateCategoryToDocTransfer({
                variables: {
                    input: {
                        id: Number(document?.id),
                        categoryIdsToAdd,
                        categoryIdsToDelete,
                        subCategoryIdsToAdd,
                        subCategoryIdsToDelete,
                    },
                },
            });
        } else {
            toggleCategForm(false);
            // todo: display alert(no changes found)
        }
    };

    const toggleCategForm = (form: boolean) => {
        setShowCategForm(form);

        if (form) {
            if (myCategories?.length) {
                const selectedCateg = defaultCategory?.id
                    ? myCategories.find(
                          (item) => item.id === defaultCategory.id
                      )
                    : null;
                const selectedSubCateg = defaultSubCategory?.id
                    ? selectedCateg?.subCategories?.find(
                          (item) => item.id === defaultSubCategory?.id
                      )
                    : null;
                setSelectedCategory(selectedCateg);
                setSelectedSubcategory(selectedSubCateg);
            }
        } else {
            setSelectedCategory(null);
            setSelectedSubcategory(null);
        }
    };

    const onDrawerClose = () => {
        setOpen(false, null);
        toggleCategForm(false);
        if (!isRedirectParams) {
            history.push(`${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}`);
        }
    };

    const onError = (error: ApolloError) => {
        displaySnackBar({
            message: formatMessage({ id: error.message || "error.unknown" }),
            type: "error",
        });
    };

    return (
        <>
            <Drawer
                title={formatMessage({ id: "document.properties" })}
                placement="right"
                onClose={onDrawerClose}
                visible={open || false}
                width={420}
                closable={false}
                extra={
                    <Button
                        type="text"
                        className={classes.closeBtn}
                        size="small"
                        onClick={onDrawerClose}
                    >
                        <AiOutlineClose
                            fontSize={14}
                            className={classes.closeIcon}
                        />{" "}
                    </Button>
                }
            >
                <Grid container spacing={1}>
                    <Item label="fileName" value={document?.fileName} />
                    <Item
                        label="fileSize"
                        value={getFileSize(document?.fileSize)}
                    />
                    {/* <Item
                        label="securityLevel"
                        value={document?.securityLevel}
                    /> */}
                    <Item label="ipfsType" value={document?.ipfsType} />
                    <Item
                        label="cid"
                        value={minifyCID(document?.document?.cid)}
                    />
                    <Item
                        label="sender.cid"
                        value={minifyCID(document?.senderPassphrase?.cid)}
                    />
                    {document?.recipientPassphrase?.cid && (
                        <Item
                            label="recipient.cid"
                            value={minifyCID(
                                document?.recipientPassphrase?.cid
                            )}
                        />
                    )}
                    <Item
                        label="download.url"
                        value={minifyCID(
                            `${config.uiUrl}${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}?id=${document?.id}&redirect=true`
                        )}
                    />
                    {/* <Item
                        label="created"
                        value={
                            document?.createdAt
                                ? moment(document?.createdAt).format(
                                      DATETIME_FORMAT
                                  )
                                : ""
                        }
                    /> */}
                </Grid>
                <hr />
                <Grid container spacing={1}>
                    <Item
                        label="created"
                        value={
                            document?.createdAt
                                ? moment(document?.createdAt).format(
                                      DATETIME_FORMAT
                                  )
                                : ""
                        }
                    />
                    <Item
                        label="expiresAt"
                        value={
                            document?.expiresAt
                                ? moment(document?.expiresAt).format(
                                      DATETIME_FORMAT
                                  )
                                : "Permanent Storage"
                        }
                    />
                </Grid>
                <hr />
                <Grid container spacing={1}>
                    <Grid item xs={12} className="mb-1">
                        <Space className="d-flex justify-content-between w-100">
                            <Typography
                                variant="body2"
                                className={classes.font14}
                            >
                                <b>
                                    {formatMessage({
                                        id: "document.categories",
                                    })}
                                </b>
                            </Typography>
                            <Space>
                                {showCategForm && (
                                    <Button
                                        type="default"
                                        className={classes.closeBtn}
                                        size="small"
                                        onClick={() => setShowCategForm(false)}
                                        disabled={updateCategLoader}
                                    >
                                        {formatMessage({
                                            id: `common.cancel`,
                                        })}
                                    </Button>
                                )}
                                <Button
                                    type="default"
                                    className={classes.closeBtn}
                                    size="small"
                                    loading={updateCategLoader}
                                    onClick={() =>
                                        showCategForm
                                            ? saveCategoryChanges()
                                            : toggleCategForm(true)
                                    }
                                >
                                    {formatMessage({
                                        id: `common.${
                                            showCategForm
                                                ? "save.changes"
                                                : "edit"
                                        }`,
                                    })}
                                </Button>
                            </Space>
                        </Space>
                    </Grid>

                    {!showCategForm && (
                        <Item
                            label="category"
                            value={defaultCategory?.name || "---"}
                        />
                    )}
                    {!showCategForm && (
                        <Item
                            label="subcategory"
                            value={defaultSubCategory?.name || "---"}
                        />
                    )}
                    {showCategForm && (
                        <Grid item xs={12}>
                            <Form
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                autoComplete="off"
                                className="mt-1"
                            >
                                <Form.Item label="Category">
                                    <Select
                                        dropdownStyle={{ zIndex: 2000 }}
                                        value={selectedCategory?.id}
                                        onChange={onCategoryChange}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        autoFocus
                                    >
                                        {myCategories?.length
                                            ? myCategories.map((item, idx) => (
                                                  <Option
                                                      value={item?.id}
                                                      key={idx}
                                                  >
                                                      {item?.name}
                                                  </Option>
                                              ))
                                            : ""}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Subcategory"
                                    className={classes.selectItem}
                                >
                                    <Select
                                        dropdownStyle={{ zIndex: 2000 }}
                                        onChange={onSubCategoryChange}
                                        value={selectedSubcategory?.id}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {selectedCategory?.subCategories?.length
                                            ? selectedCategory?.subCategories.map(
                                                  (
                                                      item: GetMyCategories_myCategories,
                                                      idx: number
                                                  ) => (
                                                      <Option
                                                          value={item?.id}
                                                          key={idx}
                                                      >
                                                          {item?.name}
                                                      </Option>
                                                  )
                                              )
                                            : ""}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Grid>
                    )}
                </Grid>
                <hr className={showCategForm ? "mt-0" : ""} />
                <Grid container spacing={1}>
                    <Item
                        label="sender"
                        value={document?.sender?.email || ""}
                    />

                    <Item
                        label="recipient"
                        value={document?.recipient?.email || ""}
                    />
                    <Item
                        label="status"
                        value={
                            <>
                                <Badge
                                    count={document?.status || ""}
                                    className="ml-2"
                                    style={{
                                        marginTop: "-5px",
                                        background:
                                            document?.status?.toLowerCase() ===
                                            "pending"
                                                ? "#F79407"
                                                : "#0B9F6F", //DF3B3A
                                    }}
                                />{" "}
                                {document?.status?.toLowerCase() ===
                                    "accepted" &&
                                    `on ${
                                        document?.updatedAt
                                            ? moment(
                                                  document?.updatedAt
                                              ).format(DATETIME_FORMAT)
                                            : ""
                                    }`}
                            </>
                        }
                    />
                </Grid>
                <div className="w-100 mt-4 d-flex justify-content-center">
                    <Button
                        className={classes.downloadOnly}
                        onClick={
                            document?.id ? () => download(document) : undefined
                        }
                    >
                        <FaLock className={classes.unlockIcon} />
                        {formatMessage({
                            id: "document.download.only",
                        })}
                    </Button>
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <Button
                        className={classes.downloadBtn}
                        loading={downloadLoader}
                        onClick={
                            document?.id
                                ? () => decryptAndDownload(document)
                                : undefined
                        }
                    >
                        <FaUnlockAlt className={classes.unlockIcon} />
                        {formatMessage({
                            id: "document.download.decrypt",
                        })}
                    </Button>
                </div>
                {document?.sentByMe && (
                    <div className="w-100 d-flex justify-content-center">
                        <Button
                            className={classes.deleteBtn}
                            onClick={() => setOpenDeleteModal(true)}
                        >
                            {formatMessage({ id: "common.delete" })}
                        </Button>
                    </div>
                )}
            </Drawer>
            <ConfirmDelete
                open={openDeleteModal}
                toggleModal={setOpenDeleteModal}
                message={`Are you sure you want to delete  <b>${
                    document?.fileName || ""
                }</b> ? This will be removed permanently for you and your partners.`}
                submit={confirmDeleteDocument}
                loading={loading}
            />
        </>
    );
};
export default DocumentInfo;

import { makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => ({
    content: { margin: "0 0 1em 0" },
    text: {
        color: COLORS.black,
        fontSize: 14,
        marginBottom: "3em",
        textAlign: "center",
    },
    cancel: {
        [theme.breakpoints.only("xs")]: { width: "30%" },
        fontSize: 14,
        marginRight: "-3px",
        borderRadius: 4,
        "&:hover": { transform: "scale(1.05)" },
    },
    actions: { padding: 0, marginTop: "3em" },
    submit: {
        background: `${COLORS.red} !important`,
        borderRadius: 4,
        color: COLORS.white,
        fontSize: 14,
        // width: "25%",
        paddingLeft: "3em",
        paddingRight: "3em",
        "&:hover": {
            transform: "scale(1.02)",
            background: "linear-gradient(to left, #034B91, #2c3e50)",
            color: COLORS.white,
        },
        "&:active": { border: "none" },
        [theme.breakpoints.only("xs")]: { width: "70%" },
        "&:disabled": {
            color: COLORS.white,
            transform: "scale(1)",
        },
    },
    dialogTitle: { [theme.breakpoints.only("xs")]: { padding: "1em" } },
    disabled: {},
    title: {
        // fontSize: 16,
        fontWeight: 500,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(2.3),
        top: theme.spacing(2),
        color: "grey",
    },
    loader: {
        backgroundColor: COLORS.primaryTransparent,
    },
    loaderBar: {
        backgroundColor: COLORS.primaryDark,
    },
}));

export default useStyles;

import { FC } from "react";
import { createStyles, makeStyles, TableContainer } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Table } from "antd";
import { TABLE_PAGINATION_PAGE_SIZE } from "../../constants/pageSize";
import TableExpandIcon from "../CustomAntdTableExpandIcon/CustomAntdTableExpandIcon";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";
import COLORS from "../../constants/colors";

const useStyles = makeStyles(() =>
    createStyles({
        container: { overFlowX: "scroll", zIndex: 0 },
        header: {
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        col: {
            background: "#f8fafc", //ecf0fa f8fafc
            padding: "3em !important",
        },
        selectedCol: {
            background: COLORS.lightblue100, //FFF7EE E6F7FF
        },
    })
);

interface IProps {
    loading?: boolean;
    loadingSkeletonCount?: number;
    rowKey?: string;
    columns?: any;
    data: any;
    expandable?: boolean;
    childrenColumnName?: string;
    pagination?: boolean;
    onPageChange?: (page: number, pageSize: number) => void;
    checkbox?: boolean;
    rowSelection?: any;
    selectedRow?: any;
    selectedKey?: string;
    totalCount?: number;
}

const CustomAntdTable: FC<IProps> = (props) => {
    const {
        loading,
        loadingSkeletonCount,
        columns,
        data,
        rowKey,
        childrenColumnName,
        expandable,
        onPageChange,
        pagination,
        checkbox,
        rowSelection,
        selectedRow,
        selectedKey,
        totalCount,
    } = props;
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const skeletonCount = loadingSkeletonCount || 5;
    if (loading)
        return (
            <div>
                <Skeleton
                    variant="rect"
                    height={50}
                    style={{ marginBottom: 20 }}
                />
                {[...Array(skeletonCount)].map((e, i) => (
                    <Skeleton
                        variant="rect"
                        height={100}
                        style={{ marginBottom: 20 }}
                    />
                ))}
            </div>
        );

    return (
        <TableContainer className={classes.container}>
            <Table
                size={
                    breakpoint === "xs" || breakpoint === "sm"
                        ? "small"
                        : "large"
                }
                rowKey={rowKey}
                columns={columns}
                pagination={
                    pagination
                        ? {
                              position: ["bottomRight"],
                              showSizeChanger: true,
                              defaultPageSize: TABLE_PAGINATION_PAGE_SIZE,
                              onChange: onPageChange,
                              total: totalCount,
                          }
                        : false
                }
                expandIcon={(props) => <TableExpandIcon props={props} />}
                expandable={expandable ? { childrenColumnName } : {}}
                dataSource={data}
                defaultExpandAllRows={true}
                rowClassName={(item, idx) =>
                    selectedKey &&
                    item?.[selectedKey] === selectedRow?.[selectedKey]
                        ? classes.selectedCol
                        : idx % 2 !== 0
                        ? classes.col
                        : ""
                }
                rowSelection={checkbox ? rowSelection : undefined}
            />
        </TableContainer>
    );
};

export default CustomAntdTable;

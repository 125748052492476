import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { useKeycloak } from "@react-keycloak/web";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import { isConnected } from "../../services/tracerkey";
import { signAuthMessageTracerkey } from "../../services/tracerkey";
import { tracerkeyCheckExtension } from "../../services/tracerkey";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { useMutation } from "@apollo/client";
import { WALLET_AUTH } from "../../graphql/authentification/mutation";
import { WalletAuthVariables } from "../../graphql/authentification/types/WalletAuth";
import { WalletAuth } from "../../graphql/authentification/types/WalletAuth";
import { DOCUMENT_TAB_ROUTES } from "../Document/Document";
import { WalletAuthAction } from "../../types/graphql-global-types";
import { AuthContext } from "../../providers/AuthProvider/AuthProvider";
import config from "../../config/config";
import useStyles from "./styles";
import KeycloakAccount from "../../components/KeycloakAccount/KeycloakAccount";
import CustomButton from "../../components/CustomButton/CustomButton";
import AuthLayout from "./AuthLayout";

const WalletAuthPage: FC<RouteComponentProps> = ({ history, location }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { login } = useContext(AuthContext);
    const { keycloak } = useKeycloak();
    const { meKeycloak } = useContext(UserContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [isTracerkeyInstalled, setIsTracerkeyInstalled] = useState(false);

    const locationState =
        location?.state && typeof location?.state === "string"
            ? JSON.parse(`${location?.state}`)
            : "";
    const locationStateFrom = locationState?.from || "";

    const displayError = (messageId: string) => {
        displaySnackBar({
            message: formatMessage({ id: messageId }),
            type: "error",
        });
    };

    useEffect(() => {
        (async () => {
            const installed = await tracerkeyCheckExtension();
            setIsTracerkeyInstalled(installed);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [walletAuth, { loading }] = useMutation<
        WalletAuth,
        WalletAuthVariables
    >(WALLET_AUTH, {
        onCompleted: async (data) => {
            if (data.walletAuth.token) {
                isConnected(true);
                login(data.walletAuth.token);

                if (
                    locationStateFrom &&
                    locationStateFrom?.pathname?.includes(ROUTES.DOCUMENTS) &&
                    locationStateFrom?.search
                ) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${locationStateFrom?.search}`
                    );
                } else if (location?.search && !location?.state) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${location?.search}`
                    );
                } else {
                    history.replace(ROUTES.DASHBOARD);
                }
                // SIGNIN
                if (data.walletAuth.action === WalletAuthAction.SIGNIN) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.login" }),
                        type: "success",
                    });
                }
                // SIGNUP
                if (
                    data.walletAuth.action === WalletAuthAction.SIGNUP &&
                    data.walletAuth.createdUserId
                ) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.signup" }),
                        type: "success",
                    });
                }
            }
        },

        onError: async (error) => {
            displaySnackBar({
                message: error?.message
                    ? error?.message
                    : formatMessage({
                          id: error.message || "error.unknown",
                      }),
                type: "error",
            });
        },
    });

    const onClickWalletAuth = async () => {
        if (meKeycloak && meKeycloak.email && meKeycloak.sub) {
            const result = await signAuthMessageTracerkey(displayError);
            if (result) {
                const { message, signature } = result;
                walletAuth({
                    variables: {
                        input: {
                            message,
                            signature,
                            email: meKeycloak.email,
                            keycloakUserId: meKeycloak.sub,
                        },
                    },
                });
            }
        }
    };

    const onClickLogoutKeycloak = async () => {
        await keycloak.logout({
            redirectUri: `${config.uiUrl}${ROUTES.SIGNIN}`,
        });
    };

    return (
        <AuthLayout
            type="wallet-auth"
            title="auth.signin.title"
            noTracerkey={!isTracerkeyInstalled}
            pageTitle="Connect Wallet"
        >
            <KeycloakAccount />

            <form className={classes.form} noValidate>
                <CustomButton
                    onClick={onClickLogoutKeycloak}
                    text={formatMessage({
                        id: "common.keycloak.logout",
                    })}
                    disabled={loading}
                    bordered
                    fullWidth
                />
                <CustomButton
                    onClick={onClickWalletAuth}
                    text={formatMessage({
                        id: "auth.signin.tracerkey",
                    })}
                    fullWidth
                    loading={loading}
                    disabled={!isTracerkeyInstalled}
                />
                <div className="d-flex justify-content-end mt-4">
                    <a
                        className={classes.installLink}
                        href={config.tracerkeyInstallationUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {formatMessage({ id: "auth.install.tracerkey" })}
                    </a>
                </div>
            </form>
        </AuthLayout>
    );
};

export default withRouter(WalletAuthPage);

import React, { useState, FC, useContext, createContext } from "react";
import { useIntl } from "react-intl";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import { IPFSConfigContext } from "../IPFSConfigProvider/IPFSConfigProvider";
import { SnackBarContext } from "../SnackBarProvider/SnackBarProvider";
import { isConnected } from "../../services/tracerkey";

type IAuthContext = {
    token: string | null;
    login: (token: string | null) => void;
    logout: (args?: ILogout) => void;
};

type ILogout = {
    error?: string;
    displaySuccessMessage?: boolean;
};

export const AuthContext = createContext<IAuthContext>({
    token: null,
    login: () => {},
    logout: () => {},
});

const AuthProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const localToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const { formatMessage } = useIntl();

    const [token, setToken] = useState<string | null>(localToken);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { removeToken: removeIPFSToken } = useContext(IPFSConfigContext);

    const login = (token: string | null) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token ? token : "");
        setToken(token);
    };

    const logout = async (args?: ILogout) => {
        const error = args?.error;
        const displaySuccessMessage = args?.displaySuccessMessage;

        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        localStorage.removeItem(
            LOCAL_STORAGE_KEYS.PUBLIC_KEY_GENERATION_CHOICE
        );
        setToken(null);
        await isConnected(false);

        if (error) {
            displaySnackBar({
                message: formatMessage({ id: error }),
                type: "error",
            });
        }

        if (displaySuccessMessage) {
            displaySnackBar({
                message: formatMessage({ id: "success.logout" }),
                type: "success",
            });
        }
        removeIPFSToken();
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import COLORS from "../../constants/colors";
import logo from "../../images/logo-only-1.png";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: COLORS.white,
        // background: "#343A40",
        background: "#0D6B9B",
    },
}));

interface Props {
    text?: string;
}

const CustomLoader: FC<Props> = ({ text }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <div
                style={{
                    position: "fixed",
                    marginTop: 150,
                    // color: COLORS.primaryLight,
                    color: "#fff",
                    fontSize: 18,
                }}
            >
                {formatMessage({ id: text || "common.loading" })}
            </div>
            <CircularProgress
                style={{
                    color: "#fafafa",
                    // color: COLORS.primaryLight,
                }}
                size="64px"
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >
                    <Image
                        src={logo}
                        width="40px"
                        height="auto"
                        className="pl-2"
                    />
                </Typography>
            </Box>
        </Backdrop>
    );
};

export default CustomLoader;

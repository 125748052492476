import { makeStyles, Theme, createStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primary: {
            margin: theme.spacing(5, 0, -2),
            paddingRight: 15,
            paddingLeft: 15,
            borderRadius: "25px",
            border: 1,
            borderStyle: "solid",
            background: `linear-gradient(to left, #3D85FD, #1E5B99, #1E5B99, #225E9C)`,
            // background: `linear-gradient(to bottom, ${COLORS.primaryLight}, ${COLORS.primaryDark})`,
            "&:hover": {
                transform: "scale(1.02)",
                // background: "transparent",
                // background: "#1E5B99",
                background: `linear-gradient(to left, #3D85FD, #225E9C)`,
                border: `1px solid ${COLORS.primaryDark}`,
                color: COLORS.white,
            },
            height: "auto",
            // width: "100%",
            color: COLORS.white,
            [theme.breakpoints.only("xs")]: {
                borderRadius: "25px",
                width: 0,
            },
            "&$disabled": {
                background: `linear-gradient(to bottom, ${COLORS.primaryLight}, ${COLORS.primaryDark})`,
                color: COLORS.white,
            },
        },
        bordered: {
            background: "transparent",
            border: `1px solid ${COLORS.primaryDark}`,
            color: COLORS.primaryDark,
        },
        disabled: {},
        cancel: {
            marginRight: 10,
            marginLeft: 10,
            height: "auto",
        },
        btn: {
            minWidth: "1em",
        },
        text: {
            [theme.breakpoints.only("xs")]: { display: "none" },
        },
    })
);

export default useStyles;

import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { MdAdd } from "react-icons/md";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import NewPartnerModal from "./PartnershipInvitations/NewPartnerModal";
import CustomTab from "../../components/CustomTab/CustomTab";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import PartnersList from "./Partners/PartnersList";
import PartnershipInvitations from "./PartnershipInvitations/PartnershipInvitations";
import CustomButton from "../../components/CustomButton/CustomButton";
import Fade from "react-reveal/Fade";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";

export enum PARTNERSHIP_TAB_TITLES {
    PARTNERS = "partnership.tab.partners",
    INVITATIONS = "partnership.tab.invitations",
}

export enum PARTNERSHIP_TAB_ROUTES {
    PARTNERS = "partners",
    INVITATIONS = "invitations",
}

const mapPartnershipTabRoutesToTitle = (
    partnershipTabRoute: string | undefined | null
) => {
    switch (partnershipTabRoute) {
        case PARTNERSHIP_TAB_ROUTES.PARTNERS:
            return PARTNERSHIP_TAB_TITLES.PARTNERS;

        case PARTNERSHIP_TAB_ROUTES.INVITATIONS:
            return PARTNERSHIP_TAB_TITLES.INVITATIONS;

        default:
            return PARTNERSHIP_TAB_TITLES.PARTNERS;
    }
};

interface IParams {
    tab?: string;
}

const Partnership: FC<RouteComponentProps> = (props) => {
    const breakpoint = useBreakpoint();
    const { match, history } = props;
    const { formatMessage } = useIntl();
    const params: IParams = match.params;
    const tab = mapPartnershipTabRoutesToTitle(params.tab);
    const [openPartnerModal, setOpenPartnerModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState<PARTNERSHIP_TAB_TITLES>(
        tab || PARTNERSHIP_TAB_TITLES.PARTNERS
    );

    const onClickTab = (tab: string) => {
        setSelectedTab(tab as PARTNERSHIP_TAB_TITLES);

        if (tab === PARTNERSHIP_TAB_TITLES.INVITATIONS) {
            history.push(
                `${ROUTES.PARTNERSHIP}/${PARTNERSHIP_TAB_ROUTES.INVITATIONS}`
            );
        }

        if (tab === PARTNERSHIP_TAB_TITLES.PARTNERS) {
            history.push(
                `${ROUTES.PARTNERSHIP}/${PARTNERSHIP_TAB_ROUTES.PARTNERS}`
            );
        }
    };

    return (
        <PageContainer title="Partners">
            <Grid container justifyContent="space-between">
                <Grid item xs={8} sm={8} md={9}>
                    <TitleWithBreadcrumb
                        title="menu.partners"
                        routes={[
                            {
                                path: ROUTES.PARTNERSHIP,
                                title: "menu.partners",
                            },
                        ]}
                        hideDivider
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    className={`mt-2 d-flex flex-column align-items-end justify-content-${
                        breakpoint === "xs" ? "center" : "start"
                    }`}
                >
                    <CustomButton
                        onClick={() => setOpenPartnerModal(true)}
                        text={formatMessage({
                            id: "partnership.invitation.invite",
                        })}
                        hideTextOnMobile
                        style={{
                            marginTop: 0,
                            borderRadius: 6,
                            color: "#fff",
                            background: "#01579B",
                            paddingLeft: "2em",
                            paddingRight: "2em",
                        }}
                        icon={
                            <MdAdd
                                fontSize={18}
                                style={{ marginTop: "-4px" }}
                            />
                        }
                    />
                </Grid>
            </Grid>
            <hr />

            <CustomTab
                tabs={[
                    PARTNERSHIP_TAB_TITLES.PARTNERS,
                    PARTNERSHIP_TAB_TITLES.INVITATIONS,
                ]}
                selectedTab={selectedTab}
                onClickTab={onClickTab}
            />

            {selectedTab === PARTNERSHIP_TAB_TITLES.PARTNERS ? (
                <Fade bottom distance="50%">
                    <PartnersList />
                </Fade>
            ) : (
                <Fade bottom distance="25%">
                    <PartnershipInvitations />
                </Fade>
            )}
            <NewPartnerModal
                open={openPartnerModal}
                setOpen={setOpenPartnerModal}
                tab={selectedTab}
                setSelectedTab={setSelectedTab}
            />
        </PageContainer>
    );
};

export default withRouter(Partnership);

import { gql } from "@apollo/client";

export const PARTNERSHIP_REQUEST_FRAGMENT = gql`
    fragment PartnershipRequestFields on PartnershipRequest {
        id
        status
        targetEmail
        createdAt
        updatedAt
        sentByMe
        sentToMe
    }
`;

export const PARTNERSHIP_FRAGMENT = gql`
    fragment PartnershipFields on Partnership {
        id
        createdAt
    }
`;

import React, { FC, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { UserContext } from "../../../../providers/UserProvider/UserProvider";

interface Props {
    exact?: boolean;
    path: ROUTES | string;
    children: React.ReactNode;
}

const AdminRoute: FC<Props> = (props) => {
    const { exact, path, children } = props;
    const { me } = useContext(UserContext);

    return (
        <Route
            exact={exact}
            path={path}
            render={({ location }) =>
                me && !me.isFullAdmin ? (
                    <Redirect
                        to={{
                            pathname: ROUTES.WALLET_AUTH,
                            state: { from: location },
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
};

export default AdminRoute;

import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { Badge, Space, Select } from "antd";
import { FC, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RequestStatus } from "../../../types/graphql-global-types";
import { RequestStatus as FilterRequestStatus } from "../../../constants/types";
import { RangeValue } from "../../../constants/types";
import { GET_MY_PARTNERSHIP_REQUESTS } from "../../../graphql/partnership/query";
import { MyPartnershipRequests } from "../../../graphql/partnership/types/MyPartnershipRequests";
import { MyPartnershipRequestsVariables } from "../../../graphql/partnership/types/MyPartnershipRequests";
import { MyPartnershipRequests_myPartnershipRequests } from "../../../graphql/partnership/types/MyPartnershipRequests";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { getRequestStatus } from "../../../utils/mapStatus";
import { TABLE_PAGINATION_PAGE_SIZE } from "../../../constants/pageSize";
import { filterListByDate } from "../../../components/DateFilter/DateFilter";
import CustomPaper from "../../../components/CustomPaper/CustomPaper";
import CustomTitle from "../../../components/CustomTitle/CustomTitle";
import FilterChips from "../../../components/FilterChips/FilterChips";
import CustomAntdTable from "../../../components/CustomAntdTable/CustomAntdTable";
import ConfirmInvitation from "./ConfirmInvitation";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import tableColumns from "./InviteTableColumns";
import TableLoader from "../../../components/TableLoader/TableLoader";
import useStyles from "../styles";
import DateFilter from "../../../components/DateFilter/DateFilter";
import useBreakpoint from "../../../customHooks/useBreakpoint/useBreakpoint";

const { Option } = Select;

const PartnershipInvitations: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const [filter, setFilter] = useState("common.pending");
    const [search, setSearch] = useState<string>("");
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(TABLE_PAGINATION_PAGE_SIZE);
    const [dateFilter, setDateFilter] = useState<string>("all");
    const [openDateFilter, setOpenDateFilter] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<RangeValue>(null);
    const [responseType, setResponseType] = useState<string | null | undefined>(
        ""
    );

    const filterStatus = Object.values(FilterRequestStatus);
    const [selectedInvitation, setSelectedInvitation] = useState<
        MyPartnershipRequests_myPartnershipRequests | null | undefined
    >(null);

    const { data, loading } = useQuery<
        MyPartnershipRequests,
        MyPartnershipRequestsVariables
    >(GET_MY_PARTNERSHIP_REQUESTS, {
        variables: { status: getRequestStatus(filter) },
        onError: (err) => {
            displaySnackBar({
                message: formatMessage({ id: err.message || "error.unknown" }),
                type: "error",
            });
        },
    });

    const onClickFilter = (tab: string) => {
        setFilter(tab);
        setSearch("");
    };

    const toggleDateFilter = (open: boolean) => {
        setOpenDateFilter(open);

        if (!open && dateFilter === "custom" && !dateRange) {
            setDateFilter("all");
        }
    };

    const handleDateFilter = (type: string) => {
        setDateFilter(type);
        if (type !== "custom") setDateRange(null);
    };
    const requests = data?.myPartnershipRequests || [];

    const onActionClick = (
        open: boolean,
        invitation?: MyPartnershipRequests_myPartnershipRequests | undefined,
        status?: RequestStatus | undefined
    ) => {
        setOpenConfirmModal(open);
        setSelectedInvitation(invitation);
        setResponseType(status);
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    let filteredRequests = requests?.length
        ? requests.filter((request) => {
              const _search = search?.toLowerCase();
              return (
                  request?.sender?.email?.toLowerCase()?.startsWith(_search) ||
                  request?.recipient?.email
                      ?.toLowerCase()
                      ?.startsWith(_search) ||
                  request?.targetEmail?.toLowerCase()?.startsWith(_search)
              );
          })
        : null;

    filteredRequests = filteredRequests?.length
        ? filterListByDate(filteredRequests, dateFilter, dateRange, "createdAt")
        : [];

    return (
        <>
            <CustomPaper>
                <Space>
                    <CustomTitle
                        title={`partnership.invitation.title.${filter}`}
                    />
                    <Badge
                        count={`${requests?.length || 0}`}
                        overflowCount={10000}
                        style={{
                            marginTop: "-1em",
                            backgroundColor: "#0B9F6F",
                        }}
                    />
                </Space>
                <Grid
                    container
                    justifyContent="space-between"
                    alignContent="flex-end"
                    alignItems="flex-end"
                    className="mb-3"
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        {breakpoint === "xs" ? (
                            <Select
                                value={filter}
                                onChange={onClickFilter}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                style={{ width: "100%" }}
                            >
                                {filterStatus?.length
                                    ? filterStatus.map((item, idx) => {
                                          const _item = item.toLowerCase();
                                          return (
                                              <Option
                                                  value={`common.${_item}`}
                                                  key={idx}
                                              >
                                                  {formatMessage({
                                                      id: `common.${_item}`,
                                                  })}
                                              </Option>
                                          );
                                      })
                                    : ""}
                            </Select>
                        ) : (
                            <Space>
                                {filterStatus?.length
                                    ? filterStatus.map((item, idx) => {
                                          const _filter = `common.${item.toLowerCase()}`;
                                          const _type =
                                              item as keyof typeof FilterRequestStatus;
                                          return (
                                              <FilterChips
                                                  key={idx}
                                                  text={_filter}
                                                  type={
                                                      FilterRequestStatus[_type]
                                                  }
                                                  onClick={onClickFilter}
                                                  selected={filter === _filter}
                                              />
                                          );
                                      })
                                    : ""}
                            </Space>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className={`d-flex justify-content-${
                            breakpoint === "xs" || breakpoint === "sm"
                                ? "start"
                                : "end"
                        }`}
                    >
                        <Space>
                            <SearchFilter
                                value={search}
                                setValue={setSearch}
                                results={filteredRequests?.length}
                            />
                            <DateFilter
                                openDateFilter={openDateFilter}
                                dateFilter={dateFilter}
                                toggleDateFilter={toggleDateFilter}
                                handleDateFilter={handleDateFilter}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                            />
                        </Space>
                    </Grid>
                </Grid>

                {loading ? (
                    <div style={{ marginTop: "-2em" }}>
                        <TableLoader />
                    </div>
                ) : (
                    <CustomAntdTable
                        data={filteredRequests}
                        rowKey="id"
                        childrenColumnName="subCategories"
                        expandable
                        pagination
                        onPageChange={onPageChange}
                        columns={tableColumns(
                            onActionClick,
                            classes,
                            formatMessage,
                            page,
                            pageSize
                        )}
                    />
                )}
            </CustomPaper>

            <ConfirmInvitation
                open={openConfirmModal}
                toggleModal={onActionClick}
                responseType={responseType}
                invitation={selectedInvitation}
            />
        </>
    );
};

export default withRouter(PartnershipInvitations);

const success = {
    "success.login": "Connecté avec succès",
    "success.signup": "Utilisateur enregistré avec succès",
    "success.logout": "Déconnecté avec succès",
    "success.account.confirmation":
        "Compte confirmé avec succès. Vous pouvez vous connecter en tant que l'utilisateur `{email}`.",
    "success.confirmation.email.send":
        "Email de confirmation envoyé avec succès.",
    "success.partnership.invitation.sent":
        "Invitation de partenariat envoyée avec succès à l'utilisateur.",
    "success.partnership.invitation.sent.via.email":
        "Une invitation a été envoyée par e-mail pour s' inscrire à Tracerdoc et devenir votre partenaire.",
    "success.partnership.invitation.canceled":
        "Invitation au partenariat annulée avec succès.",
    "success.partnership.invitation.accepted":
        "Invitation de partenariat acceptée avec succès.",
    "success.partnership.invitation.rejected":
        "L'invitation au partenariat a été rejetée avec succès.",
    "success.document.transfered":
        "Document chiffré et transféré avec succès à votre partenaire.",
    "success.document.store.personal": "Document chiffré avec succès.",
    "success.ipfs.config.edited":
        "La configuration IPFS a été modifiée avec succès.",
    "success.ipfs.service.edited":
        "Le service IPFS a été modifiée avec succès.",
    "success.category.created": "Categorie créée avec succès.",
    "success.subcategory.created": "Sous-categorie créée avec succès.",
    "success.save.encryption.public.key":
        "Clé d'encryption publique enregistré avec succès.",
};

export default success;

import { FC, ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Grid, Typography } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            width: "100%",
            margin: "0.5em 0 1em 0",
            padding: "0.5em 0",
        },
        left: { padding: "1px" },
        right: { padding: "0" },
        title: {
            color: COLORS.greyTitle,
            fontWeight: "bold",
        },
        subtitle: {
            color: COLORS.greySubTitle,
            fontSize: 12,
        },
    })
);

interface Props {
    title?: string;
    subtitle?: string;
    rightComponent?: ReactNode;
}

const CustomTitle: FC<Props & RouteComponentProps> = (props) => {
    const { title, subtitle, rightComponent } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();

    if (!title && !subtitle) return null;

    return (
        <Grid
            container
            spacing={0}
            justifyContent="space-between"
            className={classes.gridContainer}
        >
            <Grid item className={classes.left}>
                {title && (
                    <Typography variant="body1" className={classes.title}>
                        {formatMessage({ id: title })}
                    </Typography>
                )}

                {subtitle && (
                    <Typography variant="body2" className={classes.subtitle}>
                        {formatMessage({ id: subtitle })}
                    </Typography>
                )}
            </Grid>
            {rightComponent && (
                <Grid item className="d-flex justify-content-between">
                    {rightComponent}
                </Grid>
            )}
        </Grid>
    );
};

export default withRouter(CustomTitle);

import { Button, DatePicker, Dropdown, Menu, Radio } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { useIntl } from "react-intl";
import { FC } from "react";
import { BiCalendar } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { RangeValue } from "../../constants/types";
import moment from "moment";

const { RangePicker } = DatePicker;

interface Props {
    openDateFilter?: boolean;
    toggleDateFilter: (isOpen: boolean) => void;
    dateRange?: RangeValue;
    setDateRange: (dateRange: RangeValue) => void;
    dateFilter?: string;
    handleDateFilter: (_filter: string) => void;
}

const disableFuture: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
};

export const filterListByDate = (
    data: any,
    filterType: string,
    dateRange: RangeValue,
    key: string
) => {
    const startMonth = moment(new Date()).startOf("month").valueOf();
    const endMonth = moment(new Date()).endOf("month").valueOf();
    const startWeek = moment(new Date()).startOf("week").valueOf();
    const endWeek = moment(new Date()).endOf("week").valueOf();

    const list = data?.length
        ? filterType === "all"
            ? data
            : filterType === "today"
            ? data.filter(
                  (item: any) =>
                      moment(new Date(item?.[key])).format("MM-dd-yyyy") ===
                      moment(new Date()).format("MM-dd-yyyy")
              )
            : filterType === "week"
            ? data.filter(
                  (item: any) =>
                      moment(item?.[key]).valueOf() >= startWeek &&
                      moment(item?.[key]).valueOf() <= endWeek
              )
            : filterType === "month"
            ? data.filter(
                  (item: any) =>
                      moment(item?.[key]).valueOf() >= startMonth &&
                      moment(item?.[key]).valueOf() <= endMonth
              )
            : filterType === "custom" &&
              dateRange?.length &&
              dateRange?.[0] &&
              dateRange?.[1]
            ? data.filter(
                  (item: any) =>
                      moment(item?.[key]).valueOf() >=
                          moment(dateRange?.[0]).valueOf() &&
                      moment(item?.[key]).valueOf() <=
                          moment(dateRange?.[1]).endOf("day").valueOf()
              )
            : data
        : [];
    return list;
};

const DateFilter: FC<Props> = ({
    openDateFilter,
    toggleDateFilter,
    dateFilter,
    dateRange,
    setDateRange,
    handleDateFilter,
}) => {
    const { formatMessage } = useIntl();

    return (
        <Dropdown
            overlay={
                <Menu>
                    {["all", "today", "week", "month", "custom"].map((item) => (
                        <Menu.Item key={item}>
                            <Radio
                                checked={item === dateFilter}
                                value={item}
                                onChange={(e) => {
                                    handleDateFilter(e.target.value);
                                }}
                            >
                                {formatMessage({ id: `common.filter.${item}` })}
                            </Radio>
                        </Menu.Item>
                    ))}
                    {dateFilter === "custom" && (
                        <div className="p-2">
                            <RangePicker
                                className="mt-2"
                                value={dateRange}
                                onChange={(val) => setDateRange(val)}
                                disabledDate={disableFuture}
                            />
                        </div>
                    )}
                </Menu>
            }
            trigger={["click"]}
            visible={openDateFilter || false}
            placement="bottomRight"
            onVisibleChange={() => toggleDateFilter(!openDateFilter)}
            overlayStyle={{ width: "250px" }}
        >
            <Button
                size="large"
                style={{ background: "#f5f5fa", fontSize: 14 }}
                onClick={() => toggleDateFilter(!openDateFilter)}
            >
                <BiCalendar
                    style={{
                        fontSize: 16,
                        marginTop: "-2px",
                        marginRight: "4px",
                    }}
                />

                {dateRange?.length === 2
                    ? `${dateRange?.[0]?.format(
                          "DD-MMM-YYYY"
                      )} to ${dateRange?.[1]?.format("DD-MMM-YYYY")}`
                    : formatMessage({ id: `common.filter.${dateFilter}` })}

                <FiChevronDown className="ml-4" />
            </Button>
        </Dropdown>
    );
};

export default DateFilter;

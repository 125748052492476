import { useIntl, FormattedMessage } from "react-intl";
import { FC, useContext } from "react";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { DialogTitle, LinearProgress } from "@material-ui/core";
import { IconButton, Typography, Grid } from "@material-ui/core";
import { Button } from "antd";
import { MyPartnershipRequests_myPartnershipRequests } from "../../../graphql/partnership/types/MyPartnershipRequests";
import { RequestStatus } from "../../../types/graphql-global-types";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { SnackBarType } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { useMutation } from "@apollo/client";
import { CancelPartnershipRequest } from "../../../graphql/partnership/types/CancelPartnershipRequest";
import { CancelPartnershipRequestVariables } from "../../../graphql/partnership/types/CancelPartnershipRequest";
import { CANCEL_PARTNERSHIP_REQUEST } from "../../../graphql/partnership/mutation";
import { RESPOND_TO_PARTNERSHIP_REQUEST } from "../../../graphql/partnership/mutation";
import { RespondToPartnershipRequestVariables } from "../../../graphql/partnership/types/RespondToPartnershipRequest";
import { RespondToPartnershipRequest } from "../../../graphql/partnership/types/RespondToPartnershipRequest";
import useStyles from "../styles";
import CloseIcon from "@material-ui/icons/Close";
import SlideTransition from "../../../components/SlideTransition/SlideTransition";
import { sweetalert } from "../../../utils/sweetalert";

interface Props {
    open: boolean;
    toggleModal(open: boolean): void;
    responseType?: string | null;
    invitation?: MyPartnershipRequests_myPartnershipRequests | null;
}

const ConfirmInvation: FC<Props> = (props) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { open, toggleModal, responseType, invitation } = props;
    const [cancelPartnershipRequest, { loading: loadingCancel }] = useMutation<
        CancelPartnershipRequest,
        CancelPartnershipRequestVariables
    >(CANCEL_PARTNERSHIP_REQUEST, {
        onCompleted: async ({ cancelPartnershipRequest }) => {
            if (cancelPartnershipRequest) {
                showSnackbar(
                    "success",
                    "success.partnership.invitation.canceled"
                );
                toggleModal(false);
            }
        },
        onError: (error) => {
            showSnackbar("error", error.message || "error.unknown");
        },
    });

    const [respondToPartnershipRequest, { loading: loadingRespond }] =
        useMutation<
            RespondToPartnershipRequest,
            RespondToPartnershipRequestVariables
        >(RESPOND_TO_PARTNERSHIP_REQUEST, {
            onCompleted: async (data) => {
                if (data.respondToPartnershipRequest) {
                    sweetalert(
                        "success",
                        "Success",
                        formatMessage({
                            id:
                                data.respondToPartnershipRequest.status ===
                                RequestStatus.ACCEPTED
                                    ? "success.partnership.invitation.accepted"
                                    : "success.partnership.invitation.rejected",
                        })
                    );
                    toggleModal(false);
                }
            },

            onError: (error) => {
                showSnackbar("error", error.message || "error.unknown");
            },
        });

    const respondToRequest = () => {
        const status =
            responseType === RequestStatus.ACCEPTED
                ? RequestStatus.ACCEPTED
                : RequestStatus.REJECTED;
        respondToPartnershipRequest({
            variables: {
                input: {
                    partnershipRequestId: Number(invitation?.id),
                    status,
                },
            },
        });
    };

    const handleCancel = () => {
        const partnershipRequestId = Number(invitation?.id);
        cancelPartnershipRequest({ variables: { partnershipRequestId } });
    };

    const showSnackbar = (type: SnackBarType, id: string) => {
        displaySnackBar({ message: formatMessage({ id }), type });
    };

    const formatMsgId =
        responseType === RequestStatus.CANCELED
            ? "cancel"
            : responseType === RequestStatus.ACCEPTED && invitation?.sentToMe
            ? "accept"
            : "reject";
    return (
        <Dialog
            open={open || false}
            maxWidth="xs"
            fullWidth
            keepMounted
            TransitionComponent={SlideTransition}
        >
            {(loadingRespond || loadingCancel) && (
                <LinearProgress
                    classes={{
                        colorPrimary: classes.loader,
                        barColorPrimary: classes.loaderBar,
                    }}
                />
            )}
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <IconButton
                    className={classes.closeButton}
                    size="small"
                    onClick={() => toggleModal(false)}
                    aria-label="close-icon"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <Typography className={classes.title} variant="h6">
                        <FormattedMessage
                            id={`partnership.${formatMsgId}.title`}
                            values={{
                                partner:
                                    responseType === RequestStatus.CANCELED
                                        ? invitation?.recipient?.email ||
                                          invitation?.targetEmail
                                        : invitation?.sender?.email,
                            }}
                        />
                    </Typography>
                    <DialogContentText className={classes.contentText}>
                        <FormattedMessage
                            id={`partnership.${formatMsgId}.text`}
                            values={{
                                partner:
                                    responseType === RequestStatus.CANCELED
                                        ? invitation?.recipient?.email ||
                                          invitation?.targetEmail
                                        : invitation?.sender?.email,
                            }}
                        />
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button
                                className={classes.cancel}
                                onClick={() => toggleModal(false)}
                                disabled={loadingRespond || loadingCancel}
                            >
                                {formatMessage({ id: "common.cancel" })}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                className={classes.submit}
                                onClick={
                                    responseType === RequestStatus.CANCELED
                                        ? handleCancel
                                        : respondToRequest
                                }
                                loading={loadingRespond || loadingCancel}
                            >
                                {formatMessage({
                                    id: `partnership.confirm.invite.${formatMsgId}`,
                                })}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmInvation;

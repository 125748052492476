import { useIntl } from "react-intl";
import { DATETIME_FORMAT } from "../../../constants/datetime";
import CustomPartnerStatus from "../../../components/CustomPartnerStatus/CustomPartnerStatus";
import moment from "moment";
import { Button } from "antd";
import { MyPartners_myPartners } from "../../../graphql/partnership/types/MyPartners";

const Columns = (
    page: number,
    pageSize: number,
    handleClickDelete: (partner: MyPartners_myPartners) => void
) => {
    const { formatMessage } = useIntl();
    const defaultColumns = [
        {
            title: "#",
            dataIndex: "no",
            key: "no",
            width: 10,
            render: (_: any, __: any, idx: number) => {
                return idx + 1 + (page - 1) * pageSize;
            },
        },
        {
            title: formatMessage({
                id: "common.partner.email",
            }),
            dataIndex: "partner",
            key: "partner",
            sorter: (a: any, b: any) =>
                a.partner.email.localeCompare(b.partner.email),
            render: (partner: any) => partner?.email || "",
        },
        {
            title: formatMessage({
                id: "common.partnership.date",
            }),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt: string) => {
                return moment(createdAt).format(DATETIME_FORMAT);
            },
        },
        {
            title: formatMessage({ id: "common.status" }),
            dataIndex: "partner",
            key: "partner",
            render: (partner: any) => {
                return <CustomPartnerStatus activated={partner?.activated} />;
            },
        },

        {
            title: formatMessage({
                id: "categories.actions",
            }),
            dataIndex: "actions",
            key: "actions",
            width: 150,
            render: (_: any, partner: MyPartners_myPartners) => {
                return (
                    <Button
                        size="middle"
                        type="ghost"
                        style={{
                            background: "#D64D4B",
                            color: "#fff",
                            borderRadius: 5,
                        }}
                        onClick={() => handleClickDelete(partner)}
                    >
                        {formatMessage({
                            id: "common.delete",
                        })}
                    </Button>
                );
            },
        },
    ];
    return defaultColumns;
};

export default Columns;

export const ERROR_CODES = {
    SIGNER_DOES_NOT_MATCH: "SIGNER_DOES_NOT_MATCH",
    SIGNATURE_EXPIRED: "SIGNATURE_EXPIRED",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
    EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
    MISSING_EMAIL: "MISSING_EMAIL",
    INVALID_EMAIL_FORMAT: "INVALID_EMAIL_FORMAT",
    USER_ACCOUNT_NOT_CONFIRMED: "USER_ACCOUNT_NOT_CONFIRMED",
    USER_ACCOUNT_ALREADY_CONFIRMED: "USER_ACCOUNT_ALREADY_CONFIRMED",
    INVALID_TOKEN: "INVALID_TOKEN",
    EXPIRED_TOKEN: "EXPIRED_TOKEN",
};

export const ERROR_MESSAGES = {
    SIGNER_DOES_NOT_MATCH: "Signer address doesn't match.",
    SIGNATURE_EXPIRED:
        "Signature expired : Date of signature too early or too late.",
    USER_NOT_FOUND: "User not found.",
    USER_ALREADY_EXISTS: "User already exists.",
    EMAIL_ALREADY_EXISTS: "User with the same email already exists.",
    MISSING_EMAIL: "Missing email.",
    INVALID_EMAIL_FORMAT: "Invalid email format.",
    USER_ACCOUNT_NOT_CONFIRMED:
        "Account not confirmed. Please verify your mailbox or resend a confirmation email.",
    USER_ACCOUNT_ALREADY_CONFIRMED:
        "Account already confirmed. Please just signin.",
    INVALID_TOKEN: "Invalid token.",
    EXPIRED_TOKEN:
        "Expired token. Please use the latest generated token or generate a new token.",
};

import { FC, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import config from "../../config/config";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import PricingTable from "./PricingTable/PricingTable";

interface IPricingTable {
    type: string;
    desc?: string;
    tableId: string | undefined;
}

const Plan: FC<RouteComponentProps> = () => {
    const { formatMessage } = useIntl();

    const pricingTables: IPricingTable[] = [
        {
            type: "Personal",
            desc: "",
            tableId: config.stripe.personalPricingTableId,
        },
        {
            type: "Business",
            desc: "",
            tableId: config.stripe.businessPricingTableId,
        },
    ];

    return (
        <PageContainer>
            <TitleWithBreadcrumb
                title="menu.plan"
                routes={[{ path: ROUTES.IPFS, title: "menu.plan" }]}
            />
            <div className="pt-3 d-flex flex-column align-items-center">
                <Typography variant="h4" className="font-weight-bold">
                    {formatMessage({ id: "plan.title" })}
                </Typography>
                <Typography variant="h6" className="mt-1">
                    {formatMessage({ id: "plan.subtitle" })}
                </Typography>
            </div>

            {pricingTables.map((item, idx) => (
                <Fragment key={idx}>
                    <CustomPaper>
                        <div className="py-4">
                            <Typography
                                variant="h5"
                                className="font-weight-bold"
                                align="center"
                            >
                                {item?.type}
                            </Typography>
                            {item?.desc && (
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    align="center"
                                >
                                    {item?.desc}
                                </Typography>
                            )}
                            <PricingTable
                                className="mt-3"
                                pricingTableId={item?.tableId}
                            />
                        </div>
                    </CustomPaper>
                </Fragment>
            ))}
        </PageContainer>
    );
};

export default withRouter(Plan);

import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { Button, Input, Space } from "antd";
import { ChangeEvent, FC } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() =>
    createStyles({
        result: { fontSize: 14, color: "#19A2E5" },
    })
);
interface Props {
    value: string;
    setValue: (value: string) => void;
    placeholder?: string;
    results?: number;
}

const SearchFilter: FC<Props> = ({ value, setValue, placeholder, results }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <Space>
            {value && (
                <Typography className={classes.result}>
                    {results} result
                    {Number(results) > 1 ? "s" : ""}
                </Typography>
            )}
            <Input.Search
                autoComplete="off"
                size="large"
                aria-label="input search"
                onChange={handleSearchChange}
                value={value}
                placeholder={
                    placeholder || formatMessage({ id: "common.search" })
                }
                enterButton={
                    <Button
                        style={{ background: "#f5f5fa" }}
                        aria-label="search"
                    >
                        <AiOutlineSearch fontSize={18} color="#252525" />
                    </Button>
                }
            />
        </Space>
    );
};

export default SearchFilter;

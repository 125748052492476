import { FC } from "react";
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri";

interface Props {
    props?: any;
}

const CustomTableExpandIcon: FC<Props> = ({ props }) => {
    const { expandable, expanded, onExpand, record } = props;
    return (
        <>
            {expandable?.length ? (
                expanded ? (
                    <span
                        style={{
                            color: "black",
                            marginLeft: "-3px",
                            marginRight: "3px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            onExpand(record, e);
                        }}
                    >
                        <RiArrowDownSFill />
                    </span>
                ) : (
                    <span
                        style={{
                            color: "black",
                            marginLeft: "-5px",
                            marginRight: "1em",
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            onExpand(record, e);
                        }}
                    >
                        <RiArrowRightSFill />
                    </span>
                )
            ) : (
                ""
            )}
        </>
    );
};

export default CustomTableExpandIcon;

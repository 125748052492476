import { useContext, FC, useState, useEffect } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { CREATE_CATEGORY } from "../../../graphql/category/mutation";
import { CREATE_SUB_CATEGORY } from "../../../graphql/category/mutation";
import { UPDATE_CATEGORY } from "../../../graphql/category/mutation";
import { UPDATE_SUB_CATEGORY } from "../../../graphql/category/mutation";
import { CreateCategory } from "../../../graphql/category/types/CreateCategory";
import { CreateCategoryVariables } from "../../../graphql/category/types/CreateCategory";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { GET_MY_CATEGORIES } from "../../../graphql/category/query";
import { sweetalert } from "../../../utils/sweetalert";
import { GetMyCategories_myCategories } from "../../../graphql/category/types/GetMyCategories";
import { UpdateCategory } from "../../../graphql/category/types/UpdateCategory";
import { UpdateCategoryVariables } from "../../../graphql/category/types/UpdateCategory";
import { UpdateSubCategory } from "../../../graphql/category/types/UpdateSubCategory";
import { UpdateSubCategoryVariables } from "../../../graphql/category/types/UpdateSubCategory";
import { CreateSubCategory } from "../../../graphql/category/types/CreateSubCategory";
import { CreateSubCategoryVariables } from "../../../graphql/category/types/CreateSubCategory";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";

interface IProps {
    open: boolean;
    handleCategory(open: boolean, type: string): void;
    action?: string;
    category?: GetMyCategories_myCategories | null;
}

const NewCategoryModal: FC<IProps> = (props) => {
    const { open, action, handleCategory, category } = props;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const [name, setName] = useState<string>("");

    const requiredNameError = !name;

    useEffect(() => {
        if (category?.name && action === "update") setName(category?.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category?.name]);

    const [createCategory, { loading }] = useMutation<
        CreateCategory,
        CreateCategoryVariables
    >(CREATE_CATEGORY, {
        onCompleted: async (data) => {
            if (data.createCategory) {
                handleCloseDialog();
                sweetalert(
                    "success",
                    formatMessage({ id: "common.success" }),
                    formatMessage({ id: "categories.add.success" })
                );
            }
        },

        onError: (error) => onError(error),
        refetchQueries: [{ query: GET_MY_CATEGORIES }],
    });

    const [createSubCategory, { loading: addSubCategLoading }] = useMutation<
        CreateSubCategory,
        CreateSubCategoryVariables
    >(CREATE_SUB_CATEGORY, {
        onCompleted: async (data) => {
            if (data.createSubCategory) {
                handleCloseDialog();
                sweetalert(
                    "success",
                    formatMessage({ id: "common.success" }),
                    formatMessage({ id: "subcategories.add.success" })
                );
            }
        },
        onError: (error) => onError(error),
        refetchQueries: [{ query: GET_MY_CATEGORIES }],
    });

    const [updateCategory, { loading: updateCategLoading }] = useMutation<
        UpdateCategory,
        UpdateCategoryVariables
    >(UPDATE_CATEGORY, {
        onCompleted: async (data) => {
            if (data.updateCategory) {
                handleCloseDialog();
                sweetalert(
                    "success",
                    formatMessage({ id: "common.success" }),
                    formatMessage({ id: "categories.rename.success" })
                );
            }
        },
        onError: (error) => onError(error),
        refetchQueries: [{ query: GET_MY_CATEGORIES }],
    });

    const [updateSubCategory, { loading: updateSubCategLoading }] = useMutation<
        UpdateSubCategory,
        UpdateSubCategoryVariables
    >(UPDATE_SUB_CATEGORY, {
        onCompleted: async (data) => {
            if (data.updateSubCategory) {
                handleCloseDialog();
                sweetalert(
                    "success",
                    formatMessage({ id: "common.success" }),
                    formatMessage({ id: "categories.rename.success" })
                );
            }
        },
        onError: (error) => onError(error),
        refetchQueries: [{ query: GET_MY_CATEGORIES }],
    });

    const handleCreateCategory = () => {
        if (category && category?.__typename === "Category") {
            createSubCategory({
                variables: {
                    input: {
                        categoryId: category?.id,
                        name,
                    },
                },
            });
        } else {
            createCategory({ variables: { name } });
        }
    };

    const handleUpdateCategory = () => {
        const updateCateg =
            category?.__typename === "Category"
                ? updateCategory
                : updateSubCategory;
        updateCateg({
            variables: {
                input: {
                    id: Number(category?.id),
                    name,
                },
            },
        });
    };

    const handleCloseDialog = () => {
        setName("");
        handleCategory(false, "");
    };

    const onError = (error: ApolloError) => {
        displaySnackBar({
            message: formatMessage({ id: error.message || "error.unknown" }),
            type: "error",
        });
    };

    return (
        <CustomDialog
            loading={
                loading ||
                updateCategLoading ||
                updateSubCategLoading ||
                addSubCategLoading
            }
            open={open}
            handleClose={handleCloseDialog}
            title={
                action === "update"
                    ? formatMessage({ id: "categories.edit" })
                    : category
                    ? `${formatMessage({
                          id: "categories.add.new.subcategory",
                      })} ${category?.name}`
                    : formatMessage({ id: "categories.add.new" })
            }
            text={formatMessage({
                id: `${
                    category ? "subcategories" : "categories"
                }.create.subtitle`,
            })}
            submitText={formatMessage({
                id: `common.${action === "add" ? "create" : "update"}`,
            })}
            handleSubmit={
                action === "add" ? handleCreateCategory : handleUpdateCategory
            }
            disableSubmit={
                requiredNameError ||
                loading ||
                updateCategLoading ||
                addSubCategLoading
            }
            fullWidth
            maxWidth="sm"
        >
            <CustomTextField
                label={formatMessage({ id: "common.name" })}
                type="text"
                name="name"
                value={name}
                handlechange={(value) => {
                    setName(value);
                }}
                inputRef={(input) => input && input.focus()}
            />
        </CustomDialog>
    );
};

export default NewCategoryModal;

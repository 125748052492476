import { FC } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, TextFieldProps } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles(() => ({
    root: { background: "#fafafa" },
}));

type Props = {
    handlechange?: (val: string) => void;
};

const CustomTextField: FC<TextFieldProps & Props> = (props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { handlechange } = props;

    const defaultHelperText = props.helperText
        ? props.helperText
        : formatMessage({ id: "common.requireField" });

    return (
        <TextField
            className={classes.root}
            variant="outlined"
            margin="normal"
            fullWidth
            label={props.placeholder ? "" : props.label}
            size={props.size ? props.size : "small"}
            helperText={props.error ? defaultHelperText : ""}
            inputProps={{
                style: {
                    fontSize: 13,
                    minHeight: props.multiline ? 100 : undefined,
                },
            }}
            onChange={(event) => {
                const val = event.target.value;
                if (handlechange) {
                    handlechange(val);
                }
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {props.name ? (
                            <div>
                                {
                                    {
                                        email: (
                                            <MailOutlineIcon
                                                color="action"
                                                fontSize="small"
                                                style={{ fontSize: 18 }}
                                            />
                                        ),
                                        password: (
                                            <LockOutlinedIcon
                                                color="action"
                                                style={{ fontSize: 18 }}
                                            />
                                        ),
                                        password1: (
                                            <LockOutlinedIcon
                                                color="action"
                                                style={{ fontSize: 18 }}
                                            />
                                        ),
                                        password2: (
                                            <LockOutlinedIcon
                                                color="action"
                                                style={{ fontSize: 18 }}
                                            />
                                        ),
                                        "": "",
                                    }[props.name]
                                }
                            </div>
                        ) : null}
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

export default CustomTextField;

import { gql } from "@apollo/client";
import { DOCUMENT_TRANSFER_FRAGMENT } from "./fragment";

export const TRANSFER_DOCUMENT = gql`
    mutation TransferDocument($input: TransferDocumentInput!) {
        transferDocument(input: $input) {
            ...DocumentTransferFields
        }
    }
    ${DOCUMENT_TRANSFER_FRAGMENT}
`;

export const DELETE_DOCUMENT_TRANSFER = gql`
    mutation DeleteDocumentTransfer($input: DeleteDocumentTransferInput!) {
        deleteDocumentTransfer(input: $input)
    }
`;

export const ASSOCIATE_CATEGORY_TO_DOCUMENT_TRANSFER = gql`
    mutation AssociateCategoryToDocumentTransfer(
        $input: AssociateCategoryToDocumentTransferInput!
    ) {
        associateCategoryToDocumentTransfer(input: $input) {
            id
            categories {
                id
                name
            }
            subCategories {
                id
                name
            }
        }
    }
`;

export const ACKNOWLEDGE_RECEIPT = gql`
    mutation AcknowledgeReceipt($input: AcknowledgeReceiptInput!) {
        acknowledgeReceipt(input: $input) {
            id
            status
        }
    }
`;

const backup = {
    "backup.download.title": "Documents backup",
    "backup.download.subtitle":
        "Backup and download all your encrypted documents (sent and received) to keep your precious data safe.",
    "backup.recover.file.dragtext":
        "Click or drag file to this area to upload (.tdFile)",
    "backup.recover.zip.dragtext":
        "Click or drag file to this area to upload (.zip)",
    "backup.recover.key.dragtext":
        "Click or drag file to this area to upload (.tdKey)",
    "backup.in.progress": "Backup In Progress",
    "backup.email.on.completion":
        "An email will be sent to you upon backup completion.",
    "backup.download.button.title": "Download",
    "backup.package.available":
        "Your backup package is available and is ready for download. <br /> This package will be available for {duration} and will be automatically deleted.",
    "backup.confirm.package.delete":
        "Are you sure you want to delete your backup package ? This process cannot be undone.",
    "backup.start": "Start Backup",
    "backup.tab.backup": "Backup",
    "backup.tab.recover": "Recover",
    "backup.recover.title": "Recover documents",
    "backup.recover.upload.tracerdoc.file": "Upload Tracerdoc File",
    "backup.recover.upload.tracerdoc.key": "Upload Tracerdoc Key",
    "backup.recover.subtitle":
        "Recover your backuped documents by importing your file and the appropriate key.",
};

export default backup;

import { FC } from "react";
import { useIntl } from "react-intl";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import HomeIcon from "@material-ui/icons/Home";
import GrainIcon from "@material-ui/icons/Grain";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            width: "100%",
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        header: {
            fontWeight: "bold",
            fontSize: 18,
        },
        link: {
            display: "flex",
            fontSize: 12,
            color: "#19A2E5",
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 13,
            height: 16,
            color: "#616161",
        },
    })
);

interface IRoutes {
    path?: string;
    title: string;
}

interface IProps {
    title: string;
    routes?: IRoutes[];
    hideBreadcrumb?: boolean;
    hideDivider?: boolean;
}

const TitleWithBreadcrumb: FC<IProps> = (props) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { title, routes, hideBreadcrumb, hideDivider } = props;

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                className={classes.grid}
            >
                <Grid item className="pl-0">
                    <Typography variant="h6" className={classes.header}>
                        {formatMessage({ id: title })}
                    </Typography>

                    {!hideBreadcrumb && (
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                to={ROUTES.DASHBOARD}
                                color="textPrimary"
                                className={classes.link}
                            >
                                <HomeIcon
                                    className={classes.icon}
                                    fontSize="small"
                                    style={{ color: "#19A2E5" }}
                                />
                                {formatMessage({ id: "common.app.name" })}
                            </Link>

                            {routes &&
                                routes.length !== 0 &&
                                routes.map((route, i) => {
                                    if (i + 1 !== routes.length && route.path) {
                                        // Not last one.
                                        return (
                                            <Link
                                                key={route.title}
                                                to={route.path}
                                                color="textPrimary"
                                                className={classes.link}
                                            >
                                                <GrainIcon
                                                    className={classes.icon}
                                                    fontSize="small"
                                                />
                                                {formatMessage({
                                                    id: route.title,
                                                })}
                                            </Link>
                                        );
                                    } else {
                                        // Last one.
                                        return (
                                            <Typography
                                                key={route.title}
                                                color="textPrimary"
                                                className={classes.link}
                                                style={{ color: "#616161" }}
                                            >
                                                <>
                                                    <GrainIcon
                                                        className={classes.icon}
                                                        fontSize="small"
                                                    />
                                                    {formatMessage({
                                                        id: route.title,
                                                    })}
                                                </>
                                            </Typography>
                                        );
                                    }
                                })}
                        </Breadcrumbs>
                    )}
                </Grid>
            </Grid>
            {!hideDivider && <hr />}
        </>
    );
};

export default TitleWithBreadcrumb;
